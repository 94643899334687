import React from "react";
import at from "lodash/at";
import { useField } from "formik";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import { booleaner } from "helpers/utils";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import HelpIcon from "components/HelpIcon";
import { Trans } from "react-i18next";
import dataStorage from "dataStorage";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    position: "relative",
    marginTop: theme.spacing(1),
    "&.radio-horizontal": {
      width: "fit-content",
      "& $group": {
        display: "flex",
        flexWrap: "nowrap",
        flexDirection: "row",
        alignItems: "center",
      },
    },
  },
  group: {
    margin: theme.spacing(0),
  },
}));

function BooleanRadio(props) {
  const {
    label,
    isHorizontal,
    tooltip,
    yesTooltip,
    data,
    style,
    translate = true,
    ...rest
  } = props;
  const classes = useStyles();
  const [field, meta, helper] = useField(props);
  const { setValue: setValueForm } = helper || {};
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, "touched", "error");
  const isError = touched && error && true;

  /**
   * Render the helper text or error text
   * @returns text translated
   */
  function _renderHelperText() {
    if (isError) {
      return (
        <FormHelperText>
          <Trans values={{ label: dataStorage.translate(label) }}>
            {error}
          </Trans>
        </FormHelperText>
      );
    }
  }

  /**
   * Update the new value of radio group
   * @param {Event} event Material ui radio change event
   */
  const _onChange = (event) => {
    setValueForm && setValueForm(booleaner(event.target.value), true);
  };

  return (
    <FormControl
      {...rest}
      error={isError}
      className={classnames(classes.formControl, {
        "radio-horizontal": isHorizontal,
        "radio-vertical": !isHorizontal,
      })}
    >
      {label ? (
        <FormLabel component="legend">
          {translate ? <Trans>{label}</Trans> : label}
          {tooltip ? (
            <HelpIcon
              tooltip={tooltip}
              placement="right"
              iconName="InfoOutlined"
            />
          ) : (
            <></>
          )}
        </FormLabel>
      ) : (
        <React.Fragment />
      )}
      <RadioGroup
        aria-label={label}
        name={label}
        className={classes.group}
        style={style}
        value={
          typeof selectedValue === "boolean"
            ? selectedValue
            : (selectedValue && selectedValue.value) ?? selectedValue
        }
        onBlur={field.onBlur}
        onChange={_onChange}
      >
        <FormControlLabel
          key={"yes"}
          value={true}
          control={<Radio />}
          label={translate ? <Trans>{data[0].label}</Trans> : data[0].label}
        />
        <FormControlLabel
          key={"no"}
          value={false}
          control={<Radio />}
          label={translate ? <Trans>{data[1].label}</Trans> : data[1].label}
        />
      </RadioGroup>
      <input
        readOnly
        style={{
          opacity: 0,
          position: "absolute",
          pointerEvents: "none",
          left: 136,
        }}
        type="checkbox"
        id="gender"
        required={rest.required}
        checked={
          !!(selectedValue && data.length
            ? selectedValue.value ?? selectedValue
            : "")
        }
      />
      {_renderHelperText()}
      {yesTooltip && selectedValue ? (
        <Box>{yesTooltip}</Box>
      ) : (
        <React.Fragment />
      )}
    </FormControl>
  );
}

export default BooleanRadio;
