import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { Trans } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    "& button": {
      textTransform: "none",
      "&~button": {
        marginLeft: theme.spacing(2),
      },
    },
  },
}));

const RefferredCode = ({ callback, close, referrerCode }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(referrerCode || "");

  /**
   *
   * @param {Event} event input change event
   * @returns void if text invalid refferred code rule
   */
  const onChange = (event) => {
    if (!event.target.value) {
      setValue(event.target.value);
    } else {
      if (/^[( )a-zA-Z0-9]+$/.test(event.target.value)) {
        if ((event.target.value + "").trim().replace(/\s+/g, "").length > 8)
          return;
        setValue(event.target.value);
      }
    }
  };

  return (
    <Grid container spacing={3} className={classes.container}>
      <Grid item xs={12}>
        <Typography variant="h6">
          <Trans>lang_refferred_question</Trans>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={value}
          variant={"outlined"}
          type="text"
          inputProps={{ style: { textTransform: "uppercase" } }}
          label={<Trans>lang_referrer_code</Trans>}
          onChange={onChange}
          fullWidth
        />
      </Grid>
      <Grid
        item
        xs={12}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Button
          variant="outlined"
          onClick={() => {
            callback?.();
            close?.();
          }}
        >
          <Trans>lang_no</Trans>
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            callback?.((value + "").toUpperCase().trim().replace(/\s+/g, ""));
            close?.();
          }}
          disabled={!value || (value + "").replace(/\s/g, "").length !== 8}
        >
          <Trans>lang_yes</Trans>
        </Button>
      </Grid>
    </Grid>
  );
};

export default RefferredCode;
