import { useMutation } from "react-query";
import { postData } from "api/request";
import { getOtpUrl } from "api/api";
import dataStorage from "dataStorage";

const sendOTP = async ({ phoneCode, phoneNumber, recaptchaToken }) => {
  if (!phoneCode || !phoneNumber | !recaptchaToken) return "";
  const phone = `+${phoneCode}${phoneNumber}`;
  dataStorage.timeSendOTP[phone] = Date.now();
  const result = await postData(getOtpUrl(), {
    phone_number: phone,
    otp_type: "LOGIN",
    recaptcha: recaptchaToken,
  });
  return result;
};

const useSendOTP = () => {
  return useMutation((variables) => sendOTP(variables));
};

const useVerifyOTP = () => {
  return useMutation((variables) => sendOTP(variables));
};

const getPhoneOTP = async ({ phone, recaptchaToken }) => {
  if (!phone) return "";
  dataStorage.timeSendOTP[phone] = Date.now();
  const result = await postData(getOtpUrl(), {
    phone_number: phone,
    otp_type: "ACCOUNT_PHONE",
    recaptcha: recaptchaToken,
  });
  return result;
};

const useGetPhoneOTP = () => {
  return useMutation((variables) => getPhoneOTP(variables));
};

export { useSendOTP, useVerifyOTP, useGetPhoneOTP };
