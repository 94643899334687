import moment from "moment";
import dataStorage from "dataStorage";
import { getLogUrl } from "api/api";
import { postData } from "api/request";
import { v4 as uuidv4 } from "uuid";
import CryptoJS from "react-native-crypto-js";

/**
 * Send log to server to trace error when nessesary
 * @param {any} message message to send to server
 * @param {string} type type of message (error, info, warn)
 */
function sendLogToServer(message, type = "info") {
  try {
    let msg = typeof message === "object" ? JSON.stringify(message) : message;
    const currentTime = moment().format("DD/MM/YYYY HH:mm:ss");
    msg = `MAGPIE_ONBOARDING - ${currentTime} - ${dataStorage.enviroment} - ${(
      dataStorage.env + ""
    ).toUpperCase()} - ${dataStorage.registerEmail} - ${msg}`;
    const key = uuidv4();
    const encrypMsg = CryptoJS.AES.encrypt(msg, key).toString();
    const logData = {
      data: encrypMsg,
      id: key,
      type,
    };
    postData(getLogUrl(), logData);
  } catch (error) {
    console.error("SEND LOG ERROR: ", error);
  }
}

/**
 * console error and log error to server
 * @param  {...any} arg log error data
 */
const error = (...arg) => {
  console.error(...arg);
  sendLogToServer(...arg, "error");
};

/**
 * console warn and log warn to server
 * @param  {...any} arg log warn data
 */
const warn = (...arg) => {
  console.warn(...arg);
  sendLogToServer(...arg, "warning");
};

/**
 * log info to server
 * @param  {...any} arg log info data
 */
const log = (...arg) => {
  sendLogToServer(...arg, "info");
};

const logger = {
  error,
  warn,
  log,
};

export default logger;
