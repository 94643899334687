import { useMutation } from "react-query";
import { getData, postData } from "api/request";
import { getAccountUrl } from "api/api";

const submitAccount = async (data) => {
  const result = await postData(getAccountUrl(), data);
  return result;
};

const useSubmitAccount = () => {
  return useMutation((variables) => submitAccount(variables));
};

const getAccountStatus = async () => {
  const accountOpening = await getData(getAccountUrl());
  return accountOpening;
};

const useAccountStatus = () => {
  return useMutation((variables) => getAccountStatus(variables));
};

export { useSubmitAccount, useAccountStatus };
