/* eslint-disable max-lines */

const countries = [
  {
    code: "AX",
    value: "ALA",
    label: "Åaland Islands",
    phone: "358",
  },
  {
    code: "AF",
    value: "AFG",
    label: "Afghanistan",
    phone: "93",
  },
  {
    code: "AL",
    value: "ALB",
    label: "Albania",
    phone: "355",
  },
  {
    code: "DZ",
    value: "DZA",
    label: "Algeria",
    phone: "213",
  },
  {
    code: "AS",
    value: "ASM",
    label: "American Samoa",
    phone: "1-684",
  },
  {
    code: "AD",
    value: "AND",
    label: "Andorra",
    phone: "376",
  },
  {
    code: "AO",
    value: "AGO",
    label: "Angola",
    phone: "244",
  },
  {
    code: "AI",
    value: "AIA",
    label: "Anguilla",
    phone: "1-264",
  },
  {
    code: "AQ",
    value: "ATA",
    label: "Antarctica",
    phone: "672",
  },
  {
    code: "AG",
    value: "ATG",
    label: "Antigua and Barbuda",
    phone: "1-268",
  },
  {
    code: "AR",
    value: "ARG",
    label: "Argentina",
    phone: "54",
  },
  {
    code: "AM",
    value: "ARM",
    label: "Armenia",
    phone: "374",
  },
  {
    code: "AW",
    value: "ABW",
    label: "Aruba",
    phone: "297",
  },
  {
    code: "AU",
    value: "AUS",
    label: "Australia",
    phone: "61",
  },
  {
    code: "AT",
    value: "AUT",
    label: "Austria",
    phone: "43",
  },
  {
    code: "AZ",
    value: "AZE",
    label: "Azerbaijan",
    phone: "994",
  },
  {
    code: "BS",
    value: "BHS",
    label: "Bahamas",
    phone: "1-242",
  },
  {
    code: "BH",
    value: "BHR",
    label: "Bahrain",
    phone: "973",
  },
  {
    code: "BD",
    value: "BGD",
    label: "Bangladesh",
    phone: "880",
  },
  {
    code: "BB",
    value: "BRB",
    label: "Barbados",
    phone: "1-246",
  },
  {
    code: "BY",
    value: "BLR",
    label: "Belarus",
    phone: "375",
  },
  {
    code: "BE",
    value: "BEL",
    label: "Belgium",
    phone: "32",
  },
  {
    code: "BZ",
    value: "BLZ",
    label: "Belize",
    phone: "501",
  },
  {
    code: "BJ",
    value: "BEN",
    label: "Benin",
    phone: "229",
  },
  {
    code: "BM",
    value: "BMU",
    label: "Bermuda",
    phone: "1-441",
  },
  {
    code: "BT",
    value: "BTN",
    label: "Bhutan",
    phone: "975",
  },
  {
    code: "BO",
    value: "BOL",
    label: "Bolivia, Plurinational State of",
    phone: "591",
  },
  {
    code: "BA",
    value: "BIH",
    label: "Bosnia and Herzegovina",
    phone: "387",
  },
  {
    code: "BW",
    value: "BWA",
    label: "Botswana",
    phone: "267",
  },
  {
    code: "BV",
    value: "BVT",
    label: "Bouvet Island",
    phone: "47",
  },
  {
    code: "BR",
    value: "BRA",
    label: "Brazil",
    phone: "55",
  },
  {
    code: "IO",
    value: "IOT",
    label: "British Indian Ocean Territory",
    phone: "246",
  },
  {
    code: "VG",
    value: "VGB",
    label: "British, Virgin Islands",
    phone: "1-284",
  },
  {
    code: "BN",
    value: "BRN",
    label: "Brunei Darussalam",
    phone: "673",
  },
  {
    code: "BG",
    value: "BGR",
    label: "Bulgaria",
    phone: "359",
  },
  {
    code: "BF",
    value: "BFA",
    label: "Burkina Faso",
    phone: "226",
  },
  {
    code: "BI",
    value: "BDI",
    label: "Burundi",
    phone: "257",
  },
  {
    code: "KH",
    value: "KHM",
    label: "Cambodia",
    phone: "855",
  },
  {
    code: "CM",
    value: "CMR",
    label: "Cameroon",
    phone: "237",
  },
  {
    code: "CA",
    value: "CAN",
    label: "Canada",
    phone: "1",
  },
  {
    code: "CV",
    value: "CPV",
    label: "Cape Verde",
    phone: "238",
  },
  {
    code: "KY",
    value: "CYM",
    label: "Cayman Islands",
    phone: "1-345",
  },
  {
    code: "CF",
    value: "CAF",
    label: "Central African Republic",
    phone: "236",
  },
  {
    code: "TD",
    value: "TCD",
    label: "Chad",
    phone: "235",
  },
  {
    code: "CL",
    value: "CHL",
    label: "Chile",
    phone: "56",
  },
  {
    code: "CN",
    value: "CHN",
    label: "China",
    phone: "86",
  },
  {
    code: "CX",
    value: "CXR",
    label: "Christmas Island",
    phone: "61",
  },
  {
    code: "CC",
    value: "CCK",
    label: "Cocos (Keeling) Islands",
    phone: "61",
  },
  {
    code: "CO",
    value: "COL",
    label: "Colombia",
    phone: "57",
  },
  {
    code: "KM",
    value: "COM",
    label: "Comoros",
    phone: "269",
  },
  {
    code: "CD",
    value: "COD",
    label: "Congo, Democratic Republic of the",
    phone: "243",
  },
  {
    code: "CG",
    value: "COG",
    label: "Congo, Republic of the",
    phone: "242",
  },
  {
    code: "CK",
    value: "COK",
    label: "Cook Islands",
    phone: "682",
  },
  {
    code: "CR",
    value: "CRI",
    label: "Costa Rica",
    phone: "506",
  },
  {
    code: "CI",
    value: "CIV",
    label: "Cote d'Ivoire",
    phone: "225",
  },
  {
    code: "HR",
    value: "HRV",
    label: "Croatia",
    phone: "385",
  },
  {
    code: "CU",
    value: "CUB",
    label: "Cuba",
    phone: "53",
  },
  {
    code: "CW",
    value: "CUW",
    label: "Curacao",
    phone: "599",
  },
  {
    code: "CY",
    value: "CYP",
    label: "Cyprus",
    phone: "357",
  },
  {
    code: "CZ",
    value: "CZE",
    label: "Czech Republic",
    phone: "420",
  },
  {
    code: "DK",
    value: "DNK",
    label: "Denmark",
    phone: "45",
  },
  {
    code: "DJ",
    value: "DJI",
    label: "Djibouti",
    phone: "253",
  },
  {
    code: "DM",
    value: "DMA",
    label: "Dominica",
    phone: "1-767",
  },
  {
    code: "DO",
    value: "DOM",
    label: "Dominican Republic",
    phone: "1-809",
  },
  {
    code: "EC",
    value: "ECU",
    label: "Ecuador",
    phone: "593",
  },
  {
    code: "EG",
    value: "EGY",
    label: "Egypt",
    phone: "20",
  },
  {
    code: "SV",
    value: "SLV",
    label: "El Salvador",
    phone: "503",
  },
  {
    code: "GQ",
    value: "GNQ",
    label: "Equatorial Guinea",
    phone: "240",
  },
  {
    code: "ER",
    value: "ERI",
    label: "Eritrea",
    phone: "291",
  },
  {
    code: "EE",
    value: "EST",
    label: "Estonia",
    phone: "372",
  },
  {
    code: "ET",
    value: "ETH",
    label: "Ethiopia",
    phone: "251",
  },
  {
    code: "FK",
    value: "FLK",
    label: "Falkland Islands (Malvinas)",
    phone: "500",
  },
  {
    code: "FO",
    value: "FRO",
    label: "Faroe Islands",
    phone: "298",
  },
  {
    code: "FJ",
    value: "FJI",
    label: "Fiji",
    phone: "679",
  },
  {
    code: "FI",
    value: "FIN",
    label: "Finland",
    phone: "358",
  },
  {
    code: "FR",
    value: "FRA",
    label: "France",
    phone: "33",
  },
  {
    code: "GF",
    value: "GUF",
    label: "French Guiana",
    phone: "594",
  },
  {
    code: "PF",
    value: "PYF",
    label: "French Polynesia",
    phone: "689",
  },
  {
    code: "TF",
    value: "ATF",
    label: "French Southern Territories",
    phone: "262",
  },
  {
    code: "GA",
    value: "GAB",
    label: "Gabon",
    phone: "241",
  },
  {
    code: "GM",
    value: "GMB",
    label: "Gambia",
    phone: "220",
  },
  {
    code: "GE",
    value: "GEO",
    label: "Georgia",
    phone: "995",
  },
  {
    code: "DE",
    value: "DEU",
    label: "Germany",
    phone: "49",
  },
  {
    code: "GH",
    value: "GHA",
    label: "Ghana",
    phone: "233",
  },
  {
    code: "GI",
    value: "GIB",
    label: "Gibraltar",
    phone: "350",
  },
  {
    code: "GR",
    value: "GRC",
    label: "Greece",
    phone: "30",
  },
  {
    code: "GL",
    value: "GRL",
    label: "Greenland",
    phone: "299",
  },
  {
    code: "GD",
    value: "GRD",
    label: "Grenada",
    phone: "1-473",
  },
  {
    code: "GP",
    value: "GLP",
    label: "Guadeloupe",
    phone: "590",
  },
  {
    code: "GU",
    value: "GUM",
    label: "Guam",
    phone: "1-671",
  },
  {
    code: "GT",
    value: "GTM",
    label: "Guatemala",
    phone: "502",
  },
  {
    code: "GG",
    value: "GGY",
    label: "Guernsey",
    phone: "44",
  },
  {
    code: "GN",
    value: "GIN",
    label: "Guinea",
    phone: "224",
  },
  {
    code: "GW",
    value: "GNB",
    label: "Guinea-Bissau",
    phone: "245",
  },
  {
    code: "GY",
    value: "GUY",
    label: "Guyana",
    phone: "592",
  },
  {
    code: "HT",
    value: "HTI",
    label: "Haiti",
    phone: "509",
  },
  {
    code: "HM",
    value: "HMD",
    label: "Heard Island and McDonald Islands",
    phone: "672",
  },
  {
    code: "VA",
    value: "VAT",
    label: "Holy See (Vatican City State)",
    phone: "379",
  },
  {
    code: "HN",
    value: "HND",
    label: "Honduras",
    phone: "504",
  },
  {
    code: "HK",
    value: "HKG",
    label: "Hong Kong, SAR, China",
    phone: "852",
  },
  {
    code: "HU",
    value: "HUN",
    label: "Hungary",
    phone: "36",
  },
  {
    code: "IS",
    value: "ISL",
    label: "Iceland",
    phone: "354",
  },
  {
    code: "IN",
    value: "IND",
    label: "India",
    phone: "91",
  },
  {
    code: "ID",
    value: "IDN",
    label: "Indonesia",
    phone: "62",
  },
  {
    code: "IR",
    value: "IRN",
    label: "Iran, Islamic Republic of",
    phone: "98",
  },
  {
    code: "IQ",
    value: "IRQ",
    label: "Iraq",
    phone: "964",
  },
  {
    code: "IE",
    value: "IRL",
    label: "Ireland",
    phone: "353",
  },
  {
    code: "IM",
    value: "IMN",
    label: "Isle of Man",
    phone: "44",
  },
  {
    code: "IL",
    value: "ISR",
    label: "Israel",
    phone: "972",
  },
  {
    code: "IT",
    value: "ITA",
    label: "Italy",
    phone: "39",
  },
  {
    code: "JM",
    value: "JAM",
    label: "Jamaica",
    phone: "1-876",
  },
  {
    code: "JP",
    value: "JPN",
    label: "Japan",
    phone: "81",
  },
  {
    code: "JE",
    value: "JEY",
    label: "Jersey",
    phone: "44",
  },
  {
    code: "JO",
    value: "JOR",
    label: "Jordan",
    phone: "962",
  },
  {
    code: "KZ",
    value: "KAZ",
    label: "Kazakhstan",
    phone: "7",
  },
  {
    code: "KE",
    value: "KEN",
    label: "Kenya",
    phone: "254",
  },
  {
    code: "KI",
    value: "KIR",
    label: "Kiribati",
    phone: "686",
  },
  {
    code: "KP",
    value: "PRK",
    label: "Korea, Democratic People's Republic of",
    phone: "850",
  },
  {
    code: "KR",
    value: "KOR",
    label: "Korea, Republic of",
    phone: "82",
  },
  {
    code: "KW",
    value: "KWT",
    label: "Kuwait",
    phone: "965",
  },
  {
    code: "KG",
    value: "KGZ",
    label: "Kyrgyzstan",
    phone: "996",
  },
  {
    code: "LA",
    value: "LAO",
    label: "Lao People's Democratic Republic",
    phone: "856",
  },
  {
    code: "LV",
    value: "LVA",
    label: "Latvia",
    phone: "371",
  },
  {
    code: "LB",
    value: "LBN",
    label: "Lebanon",
    phone: "961",
  },
  {
    code: "LS",
    value: "LSO",
    label: "Lesotho",
    phone: "266",
  },
  {
    code: "LR",
    value: "LBR",
    label: "Liberia",
    phone: "231",
  },
  {
    code: "LY",
    value: "LBY",
    label: "Libya",
    phone: "218",
  },
  {
    code: "LI",
    value: "LIE",
    label: "Liechtenstein",
    phone: "423",
  },
  {
    code: "LT",
    value: "LTU",
    label: "Lithuania",
    phone: "370",
  },
  {
    code: "LU",
    value: "LUX",
    label: "Luxembourg",
    phone: "352",
  },
  {
    code: "MK",
    value: "MKD",
    label: "Macedonia, the Former Yugoslav Republic of",
    phone: "389",
  },
  {
    code: "MG",
    value: "MDG",
    label: "Madagascar",
    phone: "261",
  },
  {
    code: "MW",
    value: "MWI",
    label: "Malawi",
    phone: "265",
  },
  {
    code: "MY",
    value: "MYS",
    label: "Malaysia",
    phone: "60",
  },
  {
    code: "MV",
    value: "MDV",
    label: "Maldives",
    phone: "960",
  },
  {
    code: "ML",
    value: "MLI",
    label: "Mali",
    phone: "223",
  },
  {
    code: "MT",
    value: "MLT",
    label: "Malta",
    phone: "356",
  },
  {
    code: "MH",
    value: "MHL",
    label: "Marshall Islands",
    phone: "692",
  },
  {
    code: "MQ",
    value: "MTQ",
    label: "Martinique",
    phone: "596",
  },
  {
    code: "MR",
    value: "MRT",
    label: "Mauritania",
    phone: "222",
  },
  {
    code: "MU",
    value: "MUS",
    label: "Mauritius",
    phone: "230",
  },
  {
    code: "YT",
    value: "MYT",
    label: "Mayotte",
    phone: "262",
  },
  {
    code: "MX",
    value: "MEX",
    label: "Mexico",
    phone: "52",
  },
  {
    code: "FM",
    value: "FSM",
    label: "Micronesia, Federated States of",
    phone: "691",
  },
  {
    code: "MD",
    value: "MDA",
    label: "Moldova, Republic of",
    phone: "373",
  },
  {
    code: "MC",
    value: "MCO",
    label: "Monaco",
    phone: "377",
  },
  {
    code: "MN",
    value: "MNG",
    label: "Mongolia",
    phone: "976",
  },
  {
    code: "ME",
    value: "MNE",
    label: "Montenegro",
    phone: "382",
  },
  {
    code: "MS",
    value: "MSR",
    label: "Montserrat",
    phone: "1-664",
  },
  {
    code: "MA",
    value: "MAR",
    label: "Morocco",
    phone: "212",
  },
  {
    code: "MZ",
    value: "MOZ",
    label: "Mozambique",
    phone: "258",
  },
  {
    code: "MM",
    value: "MMR",
    label: "Myanmar",
    phone: "95",
  },
  {
    code: "NA",
    value: "NAM",
    label: "Namibia",
    phone: "264",
  },
  {
    code: "NR",
    value: "NRU",
    label: "Nauru",
    phone: "674",
  },
  {
    code: "NP",
    value: "NPL",
    label: "Nepal",
    phone: "977",
  },
  {
    code: "NL",
    value: "NLD",
    label: "Netherlands",
    phone: "31",
  },
  {
    code: "NC",
    value: "NCL",
    label: "New Caledonia",
    phone: "687",
  },
  {
    code: "NI",
    value: "NIC",
    label: "Nicaragua",
    phone: "505",
  },
  {
    code: "NE",
    value: "NER",
    label: "Niger",
    phone: "227",
  },
  {
    code: "NG",
    value: "NGA",
    label: "Nigeria",
    phone: "234",
  },
  {
    code: "NU",
    value: "NIU",
    label: "Niue",
    phone: "683",
  },
  {
    code: "NF",
    value: "NFK",
    label: "Norfolk Island",
    phone: "672",
  },
  {
    code: "MP",
    value: "MNP",
    label: "Northern Mariana Islands",
    phone: "1-670",
  },
  {
    code: "NO",
    value: "NOR",
    label: "Norway",
    phone: "47",
  },
  {
    code: "OM",
    value: "OMN",
    label: "Oman",
    phone: "968",
  },
  {
    code: "PK",
    value: "PAK",
    label: "Pakistan",
    phone: "92",
  },
  {
    code: "PW",
    value: "PLW",
    label: "Palau",
    phone: "680",
  },
  {
    code: "PS",
    value: "PSE",
    label: "Palestine, State of",
    phone: "970",
  },
  {
    code: "PA",
    value: "PAN",
    label: "Panama",
    phone: "507",
  },
  {
    code: "PG",
    value: "PNG",
    label: "Papua New Guinea",
    phone: "675",
  },
  {
    code: "PY",
    value: "PRY",
    label: "Paraguay",
    phone: "595",
  },
  {
    code: "PE",
    value: "PER",
    label: "Peru",
    phone: "51",
  },
  {
    code: "PH",
    value: "PHL",
    label: "Philippines",
    phone: "63",
  },
  {
    code: "PN",
    value: "PCN",
    label: "Pitcairn",
    phone: "870",
  },
  {
    code: "PL",
    value: "POL",
    label: "Poland",
    phone: "48",
  },
  {
    code: "PT",
    value: "PRT",
    label: "Portugal",
    phone: "351",
  },
  {
    code: "PR",
    value: "PRI",
    label: "Puerto Rico",
    phone: "1",
  },
  {
    code: "QA",
    value: "QAT",
    label: "Qatar",
    phone: "974",
  },
  {
    code: "RO",
    value: "ROU",
    label: "Romania",
    phone: "40",
  },
  {
    code: "RU",
    value: "RUS",
    label: "Russian Federation",
    phone: "7",
  },
  {
    code: "RW",
    value: "RWA",
    label: "Rwanda",
    phone: "250",
  },
  {
    code: "BL",
    value: "BLM",
    label: "Saint Barthelemy",
    phone: "590",
  },
  {
    code: "SH",
    value: "SHN",
    label: "Saint Helena",
    phone: "290",
  },
  {
    code: "KN",
    value: "KNA",
    label: "Saint Kitts and Nevis",
    phone: "1-869",
  },
  {
    code: "LC",
    value: "LCA",
    label: "Saint Lucia",
    phone: "1-758",
  },
  {
    code: "MF",
    value: "MAF",
    label: "Saint Martin (French part)",
    phone: "590",
  },
  {
    code: "PM",
    value: "SPM",
    label: "Saint Pierre and Miquelon",
    phone: "508",
  },
  {
    code: "VC",
    value: "VCT",
    label: "Saint Vincent and the Grenadines",
    phone: "1-784",
  },
  {
    code: "WS",
    value: "WSM",
    label: "Samoa",
    phone: "685",
  },
  {
    code: "SM",
    value: "SMR",
    label: "San Marino",
    phone: "378",
  },
  {
    code: "ST",
    value: "STP",
    label: "Sao Tome and Principe",
    phone: "239",
  },
  {
    code: "SA",
    value: "SAU",
    label: "Saudi Arabia",
    phone: "966",
  },
  {
    code: "SN",
    value: "SEN",
    label: "Senegal",
    phone: "221",
  },
  {
    code: "RS",
    value: "SRB",
    label: "Serbia",
    phone: "381",
  },
  {
    code: "SC",
    value: "SYC",
    label: "Seychelles",
    phone: "248",
  },
  {
    code: "SL",
    value: "SLE",
    label: "Sierra Leone",
    phone: "232",
  },
  {
    code: "SG",
    value: "SGP",
    label: "Singapore",
    phone: "65",
  },
  {
    code: "SX",
    value: "SXM",
    label: "Sint Maarten (Dutch part)",
    phone: "1-721",
  },
  {
    code: "SK",
    value: "SVK",
    label: "Slovakia",
    phone: "421",
  },
  {
    code: "SI",
    value: "SVN",
    label: "Slovenia",
    phone: "386",
  },
  {
    code: "SB",
    value: "SLB",
    label: "Solomon Islands",
    phone: "677",
  },
  {
    code: "SO",
    value: "SOM",
    label: "Somalia",
    phone: "252",
  },
  {
    code: "ZA",
    value: "ZAF",
    label: "South Africa",
    phone: "27",
  },
  {
    code: "GS",
    value: "SGS",
    label: "South Georgia and the South Sandwich Islands",
    phone: "500",
  },
  {
    code: "SS",
    value: "SSD",
    label: "South Sudan",
    phone: "211",
  },
  {
    code: "ES",
    value: "ESP",
    label: "Spain",
    phone: "34",
  },
  {
    code: "LK",
    value: "LKA",
    label: "Sri Lanka",
    phone: "94",
  },
  {
    code: "SD",
    value: "SDN",
    label: "Sudan",
    phone: "249",
  },
  {
    code: "SR",
    value: "SUR",
    label: "Suriname",
    phone: "597",
  },
  {
    code: "SJ",
    value: "SJM",
    label: "Svalbard and Jan Mayen",
    phone: "47",
  },
  {
    code: "SZ",
    value: "SWZ",
    label: "Swaziland",
    phone: "268",
  },
  {
    code: "SE",
    value: "SWE",
    label: "Sweden",
    phone: "46",
  },
  {
    code: "CH",
    value: "CHE",
    label: "Switzerland",
    phone: "41",
  },
  {
    code: "SY",
    value: "SYR",
    label: "Syrian Arab Republic",
    phone: "963",
  },
  {
    code: "TW",
    value: "TWN",
    label: "Taiwan, Province of China",
    phone: "886",
  },
  {
    code: "TJ",
    value: "TJK",
    label: "Tajikistan",
    phone: "992",
  },
  {
    code: "TH",
    value: "THA",
    label: "Thailand",
    phone: "66",
  },
  {
    code: "TL",
    value: "TLS",
    label: "Timor-Leste",
    phone: "670",
  },
  {
    code: "TG",
    value: "TGO",
    label: "Togo",
    phone: "228",
  },
  {
    code: "TK",
    value: "TKL",
    label: "Tokelau",
    phone: "690",
  },
  {
    code: "TO",
    value: "TON",
    label: "Tonga",
    phone: "676",
  },
  {
    code: "TT",
    value: "TTO",
    label: "Trinidad and Tobago",
    phone: "1-868",
  },
  {
    code: "TN",
    value: "TUN",
    label: "Tunisia",
    phone: "216",
  },
  {
    code: "TR",
    value: "TUR",
    label: "Turkey",
    phone: "90",
  },
  {
    code: "TM",
    value: "TKM",
    label: "Turkmenistan",
    phone: "993",
  },
  {
    code: "TC",
    value: "TCA",
    label: "Turks and Caicos Islands",
    phone: "1-649",
  },
  {
    code: "TV",
    value: "TUV",
    label: "Tuvalu",
    phone: "688",
  },
  {
    code: "UG",
    value: "UGA",
    label: "Uganda",
    phone: "256",
  },
  {
    code: "UA",
    value: "UKR",
    label: "Ukraine",
    phone: "380",
  },
  {
    code: "AE",
    value: "ARE",
    label: "United Arab Emirates",
    phone: "971",
  },
  {
    code: "GB",
    value: "GBR",
    label: "United Kingdom",
    phone: "44",
  },
  {
    code: "TZ",
    value: "TZA",
    label: "United Republic of Tanzania",
    phone: "255",
  },
  {
    code: "US",
    value: "USA",
    label: "United States",
    phone: "1",
  },
  {
    code: "UY",
    value: "URY",
    label: "Uruguay",
    phone: "598",
  },
  {
    code: "VI",
    value: "VIR",
    label: "US Virgin Islands",
    phone: "1-340",
  },
  {
    code: "UZ",
    value: "UZB",
    label: "Uzbekistan",
    phone: "998",
  },
  {
    code: "VU",
    value: "VUT",
    label: "Vanuatu",
    phone: "678",
  },
  {
    code: "VE",
    value: "VEN",
    label: "Venezuela",
    phone: "58",
  },
  {
    code: "VN",
    value: "VNM",
    label: "Viet Nam",
    phone: "84",
  },
  {
    code: "WF",
    value: "WLF",
    label: "Wallis and Futuna",
    phone: "681",
  },
  {
    code: "EH",
    value: "ESH",
    label: "Western Sahara",
    phone: "212",
  },
  {
    code: "YE",
    value: "YEM",
    label: "Yemen",
    phone: "967",
  },
  {
    code: "ZM",
    value: "ZMB",
    label: "Zambia",
    phone: "260",
  },
  {
    code: "ZW",
    value: "ZWE",
    label: "Zimbabwe",
    phone: "263",
  },
];

export default countries;
