import React from "react";
import { at } from "lodash";
import { useField } from "formik";
import TextField from "@mui/material/TextField";
import { Trans } from "react-i18next";
import dataStorage from "dataStorage";

export default function InputField({ ...props }) {
  const {
    helperText,
    variant = "outlined",
    translate = true,
    textTransform = "none",
    ...rest
  } = props;
  const [field, meta, helper] = useField(props);
  const [touched, error] = at(meta, "touched", "error");

  /**
   * Render helper text or error text
   * @returns translated text
   */
  function _renderHelperText() {
    let text = "";
    if (touched && error) {
      text = error;
    } else if (helperText) {
      text = helperText;
    }
    return (
      <Trans
        values={{
          label: dataStorage.translate(rest.label),
          max: rest.maxLength,
        }}
      >
        {text}
      </Trans>
    );
  }

  /**
   * Update input value
   * @param {Event} event input change event
   */
  const onChange = (event) => {
    let value = event.target.value;
    if (textTransform) {
      switch (textTransform) {
        case "uppercase":
          value = value.toUpperCase();
          break;
        case "lowercase":
          value = value.toLowerCase();
          break;
        default:
          break;
      }
    }
    helper.setValue(value);
  };

  /**
   * Handle blur input, auo trim value
   * @param {event} e Input blur event
   */
  const onBlur = (e) => {
    const value = e.target.value;
    if (value !== (value + "").trim()) {
      helper.setValue((value + "").trim());
    }
    field.onBlur?.(e);
  };

  const extraProps = {};
  if (rest.placeholder) {
    extraProps.placeholder = dataStorage.translate(rest.placeholder);
  }

  return (
    <TextField
      variant={variant}
      type="text"
      error={Boolean(touched && error && true)}
      helperText={_renderHelperText()}
      {...field}
      {...rest}
      {...extraProps}
      onBlur={onBlur}
      inputProps={{
        style: { textTransform },
        maxLength: rest.maxLength || 524288,
      }}
      label={translate ? <Trans>{rest.label}</Trans> : rest.label}
      onChange={onChange}
      value={field.value || ""}
    />
  );
}
