import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Trans } from "react-i18next";
import { makeStyles } from "@mui/styles";
import dataStorage from "dataStorage";
import { useRenewLiveness } from "hooks/useKYC";
import { CircularProgress } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  ifameKyc: {
    height: 500,
    padding: theme.spacing(1, 0),
    background: "#FFF",
    [theme.breakpoints.down("sm")]: {
      height: "70vh",
    },
  },
}));

const EKYC = (props, ref) => {
  const classes = useStyles();
  const [src, setSrc] = React.useState(props.src || "");
  const { mutate: getNewLivenessToken } = useRenewLiveness();

  /**
   * Handle renew jumio token and refresh iframe
   */
  const recheckLiveness = () => {
    getNewLivenessToken(
      {},
      {
        onSuccess: (data) => {
          const jumio = document.getElementById("ekyc");
          jumio.src = "";
          setSrc(data.workflow6_web_url);
          jumio.src = data.workflow6_web_url;
        },
        onError: (error) => console.error("YOLO: ", error),
      }
    );
  };

  /**
   * allow parent component call re check liveness
   */
  React.useImperativeHandle(ref, () => ({
    recheckLiveness,
  }));

  /**
   * add event listener and listen jumio event through iframe message
   */
  React.useEffect(() => {
    if (!src) {
      recheckLiveness();
    }
    const jumio = document.getElementById("ekyc");
    const handleMessage = (message) => {
      if (message.source === jumio.contentWindow) {
        const data = JSON.parse(message.data);
        if (data?.payload?.value === "error") {
          const errorCode = data.payload?.metainfo?.code;
          dataStorage.livenessProgress = false;
          dataStorage.showModal({
            title: "lang_notify",
            content:
              errorCode === 9210
                ? "lang_ekyc_session_timeout"
                : "lang_ekyc_jumio_error",
            confirmText: "lang_try_again",
            callback: () => {
              dataStorage.livenessProgress = true;
              recheckLiveness(true);
            },
          });
        } else {
          dataStorage.livenessProgress = data?.payload?.value === "success";
        }
      }
    };
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="body2">
          <Trans>lang_ekyc_tip</Trans>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {src ? (
          <iframe
            id="ekyc"
            className={classes.ifameKyc}
            src={src}
            width="100%"
            height="100%"
            allow="camera;fullscreen;accelerometer;gyroscope;magnetometer"
            allowFullScreen
          ></iframe>
        ) : (
          <CircularProgress />
        )}
      </Grid>
    </Grid>
  );
};

export default React.forwardRef(EKYC);
