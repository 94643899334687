import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  paper: {
    width: "100%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderRadius: 10,
    // overflow: 'hidden',
    minHeight: "400px",
    padding: theme.spacing(2),
    [theme.breakpoints.up(808 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  bannerSuccessPage: {
    height: "auto",
    width: "40%",
    minWidth: "220px",
    [theme.breakpoints.up(808 + theme.spacing(3) * 2)]: {
      height: "auto",
      width: "100%",
    },
  },
  buttons: {
    display: "flex",
    marginTop: theme.spacing(3),
    justifyContent: "space-between",
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  wrapper: {
    margin: theme.spacing(0),
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
  },
  deleteIcon: {
    display: "none",
  },
  listItem: {
    "&:hover $deleteIcon": {
      display: "block",
    },
    "& .MuiListItemIcon-root": {
      minWidth: 0,
    },
  },
  leftButtons: {
    display: "flex",
    flexDirection: "row",
    "& $wrapper:first-child": {
      marginLeft: 0,
      "& button": {
        marginLeft: 0,
      },
    },
  },
  rightButtons: {
    display: "flex",
    flexDirection: "row",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    width: "73%",
    [theme.breakpoints.between(0, "sm")]: {
      width: "100%",
    },
    "& form": {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      flex: 1,
    },
  },
  boxContainer: {
    padding: theme.spacing(0, 3, 3, 0),
    margin: theme.spacing(3, 0, 0, 3),
    border: `1px solid ${theme.palette.boxBorder}`,
    background: theme.palette.boxBg,
    position: "relative",
    width: "calc(100% - 24px)",
  },
  removeArrBtn: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  addArrBtn: {
    textTransform: "none",
    margin: theme.spacing(1, 0, 0, 2),
  },
}));
