/* eslint-disable max-lines */

import Yup from "helpers/customYup";
import formModels from "./Models";
import {
  EMPLOYMENT_STATUS,
  OCCUPATION_ROLE,
  OCCUPATION_INDUSTRY,
  RESIDENTIAL_STATUS,
  SOURCE_OF_FUND,
  INVESTMENT_OBJECTIVES,
  TAX_ID_REASON,
} from "constants/Options/options";
import dataStorage from "dataStorage";
import { dicStep } from "./ListStep";

const { formField } = formModels;

// List validation <=> list step
const validationSchema = {
  [dicStep.UPLOAD_ID_PHOTO]: Yup.object().shape({
    [formField.applicant_verification_id.name]: Yup.object().shape({
      [formField.applicant_verification_id.applicant_nationality.originName]:
        Yup.mixed().checkRequired("lang_applicant_nationality_is_required"),
      [formField.applicant_verification_id.id_type.originName]:
        Yup.mixed().checkRequired("lang_id_type_is_required"),
      [formField.applicant_verification_id.country_of_residence.originName]:
        Yup.mixed().checkRequired("lang_country_of_residence_is_required"),
      [formField.applicant_verification_id.pass_port.originName]:
        Yup.mixed().when(
          formField.applicant_verification_id.id_type.originName,
          (idType, schema) => {
            return dataStorage.isOverSeas
              ? schema
                  .checkRequired("lang_photo_required")
                  .checkFile("lang_photo_size_limit_exceeded")
              : schema;
          }
        ),
      [formField.applicant_verification_id.front_side.originName]:
        Yup.mixed().when(
          formField.applicant_verification_id.id_type.originName,
          (idType, schema) => {
            return !dataStorage.isOverSeas
              ? schema
                  .checkRequired("lang_photo_required")
                  .checkFile("lang_photo_size_limit_exceeded")
              : schema;
          }
        ),
      [formField.applicant_verification_id.back_side.originName]:
        Yup.mixed().when(
          formField.applicant_verification_id.id_type.originName,
          (idType, schema) => {
            return !dataStorage.isOverSeas
              ? schema
                  .checkRequired("lang_photo_required")
                  .checkFile("lang_photo_size_limit_exceeded")
              : schema;
          }
        ),
    }),
  }),
  [dicStep.EKYC]: Yup.object().shape({}),
  [dicStep.PERSONAL_INFORMATION]: Yup.object().shape({
    [formField.personal_information.name]: Yup.object().shape({
      [formField.personal_information.eng_given_name.originName]: Yup.mixed()
        .checkRequired("lang_given_name_is_required")
        .checkName("lang_please_enter_valid_given_name")
        .checkMaxLength(100, "lang_given_name_max_length_error"),
      [formField.personal_information.eng_middle_name.originName]: Yup.mixed()
        .checkName("lang_please_enter_valid_middle_name")
        .checkMaxLength("lang_middle_name_max_length_error", 100),
      [formField.personal_information.eng_sur_name.originName]: Yup.mixed()
        .checkRequired("lang_surname_is_required")
        .checkName("lang_please_enter_valid_surname")
        .checkMaxLength("lang_surname_max_length_error", 100),
      [formField.personal_information.chinese_name.originName]:
        Yup.mixed().checkMaxLength("lang_chinese_name_max_length_error", 100),
      [formField.personal_information.gender.originName]:
        Yup.mixed().checkRequired("lang_gender_is_required"),
      [formField.personal_information.id_number.originName]: Yup.mixed()
        .checkRequired("lang_id_number_is_required")
        .checkIdNumber(),
      [formField.personal_information.dob.originName]: Yup.mixed()
        .checkRequired("lang_dob_is_required")
        .checkEnoughAge(),
      [formField.personal_information.region.originName]: Yup.mixed()
        .checkRequired("lang_region_is_required")
        .checkMaxLength("lang_region_max_length_error", 200),
      [formField.personal_information.house_number.originName]:
        Yup.mixed().checkMaxLength("lang_house_number_max_length_error", 200),
      [formField.personal_information.street_name.originName]: Yup.mixed()
        .checkRequired("lang_street_name_is_required")
        .checkMaxLength("lang_street_name_max_length_error", 200),
      [formField.personal_information.city_name.originName]: Yup.mixed()
        .checkRequired("lang_city_name_is_required")
        .checkMaxLength("lang_city_name_max_length_error", 200),
      [formField.personal_information.province_name.originName]: Yup.mixed()
        .checkRequired("lang_province_name_is_required")
        .checkMaxLength("lang_province_name_max_length_error", 200),
      [formField.personal_information.zip_code.originName]:
        Yup.mixed().checkMaxLength("lang_zip_code_max_length_error", 100),
      [formField.personal_information.correspondence_address_document
        .originName]: Yup.mixed()
        .checkRequired("lang_photo_required")
        .checkFile("lang_photo_size_limit_exceeded"),
      [formField.personal_information.mobile_number.originName]: Yup.mixed()
        .checkRequired()
        .checkPhone(),
      [formField.personal_information.email_address.originName]: Yup.mixed()
        .checkRequired("lang_email_address_required")
        .checkEmail(),
      [formField.personal_information.education.originName]:
        Yup.mixed().checkRequired("lang_education_is_required"),
    }),
  }),
  [dicStep.EMPLOYMENT_INFORMATION]: Yup.object().shape({
    [formField.employment_information.name]: Yup.object().shape({
      [formField.employment_information.employment_status.originName]:
        Yup.mixed().checkRequired("lang_employment_status_is_required"),
      [formField.employment_information.employment_status_desc.originName]:
        Yup.mixed().when(
          formField.employment_information.employment_status.originName,
          (employmentStatus, schema) => {
            return employmentStatus === EMPLOYMENT_STATUS.OTHER
              ? schema
                  .checkRequired("lang_employment_status_description_required")
                  .checkMaxLength(100, "lang_description_max")
              : schema;
          }
        ),
      [formField.employment_information.employer_name.originName]:
        Yup.mixed().when(
          formField.employment_information.employment_status.originName,
          (employmentStatus, schema) => {
            return employmentStatus !== EMPLOYMENT_STATUS.RETIRED
              ? schema
                  .checkRequired("lang_please_enter_full_company_name")
                  .checkMaxLength(200, "lang_employer_name_max_length_error")
              : schema;
          }
        ),
      [formField.employment_information.occupation_role.originName]:
        Yup.mixed().when(
          formField.employment_information.employment_status.originName,
          (employmentStatus, schema) => {
            return employmentStatus !== EMPLOYMENT_STATUS.RETIRED
              ? schema.checkRequired("lang_please_enter_occupation_role")
              : schema;
          }
        ),
      [formField.employment_information.occupation_role_desc.originName]:
        Yup.mixed().when(
          [
            formField.employment_information.employment_status.originName,
            formField.employment_information.occupation_role.originName,
          ],
          (employmentStatus, occupationRole, schema) => {
            return employmentStatus === EMPLOYMENT_STATUS.RETIRED ||
              occupationRole !== OCCUPATION_ROLE.OTHER
              ? schema
              : schema
                  .checkRequired("lang_description_occupation")
                  .checkMaxLength(100, "lang_description_max");
          }
        ),
      [formField.employment_information.occupation_industry.originName]:
        Yup.mixed().when(
          formField.employment_information.employment_status.originName,
          (employmentStatus, schema) => {
            return employmentStatus !== EMPLOYMENT_STATUS.RETIRED
              ? schema.checkRequired("lang_please_enter_occupation_industry")
              : schema;
          }
        ),
      [formField.employment_information.occupation_industry_desc.originName]:
        Yup.mixed().when(
          [
            formField.employment_information.employment_status.originName,
            formField.employment_information.occupation_industry.originName,
          ],
          (employmentStatus, occupationIndustry, schema) => {
            return employmentStatus === EMPLOYMENT_STATUS.RETIRED ||
              occupationIndustry !== OCCUPATION_INDUSTRY.OTHERS
              ? schema
              : schema
                  .checkRequired("lang_description_industry")
                  .checkMaxLength(200, "lang_description_max");
          }
        ),
      [formField.employment_information.working_duration.originName]:
        Yup.mixed().when(
          formField.employment_information.employment_status.originName,
          (employmentStatus, schema) => {
            return employmentStatus !== EMPLOYMENT_STATUS.RETIRED
              ? schema.checkRequired("lang_please_enter_working_duration")
              : schema;
          }
        ),
    }),
  }),
  [dicStep.FINANCIAL_INVESTMENT]: Yup.object().shape({
    [formField.personal_financial_information.name]: Yup.object().shape({
      [formField.personal_financial_information.annual_income.originName]:
        Yup.mixed().checkRequired("lang_annual_income_is_required"),
      [formField.personal_financial_information.net_asset_value.originName]:
        Yup.mixed().checkRequired("lang_net_asset_value_is_required"),
      [formField.personal_financial_information.housing_status.originName]:
        Yup.mixed().checkRequired("lang_housing_status_is_required"),
      [formField.personal_financial_information.housing_status_desc.originName]:
        Yup.mixed().when(
          formField.personal_financial_information.housing_status.originName,
          (residentialStatus, schema) => {
            return residentialStatus === RESIDENTIAL_STATUS.OTHER
              ? schema
                  .checkRequired("lang_description_industry")
                  .checkMaxLength(200, "lang_description_max")
              : schema;
          }
        ),
      [formField.personal_financial_information.source_of_fund.originName]:
        Yup.mixed().checkRequired("lang_source_of_fund_is_required"),
      [formField.personal_financial_information.source_of_fund_desc.originName]:
        Yup.mixed().when(
          formField.personal_financial_information.source_of_fund.originName,
          (sourceOfFund, schema) => {
            return sourceOfFund.includes(SOURCE_OF_FUND.OTHER)
              ? schema
                  .checkRequired("lang_description_src_of_fund")
                  .checkMaxLength(200, "lang_description_max")
              : schema;
          }
        ),
      [formField.personal_financial_information.liquid_net_worth.originName]:
        Yup.mixed().checkRequired("lang_liquid_net_worth_is_required"),
    }),
    [formField.invesment_exp.name]: Yup.object().shape({
      [formField.invesment_exp.stock.originName]: Yup.mixed().checkRequired(
        "lang_stock_is_required"
      ),
      [formField.invesment_exp.cbbc.originName]: Yup.mixed().checkRequired(
        "lang_cbbc_is_required"
      ),
      [formField.invesment_exp.derivatives.originName]:
        Yup.mixed().checkRequired("lang_derivatives_is_required"),
      [formField.invesment_exp.futures.originName]: Yup.mixed().checkRequired(
        "lang_futures_is_required"
      ),
      [formField.invesment_exp.options_warrants.originName]:
        Yup.mixed().checkRequired("lang_options_warrants_is_required"),
      [formField.invesment_exp.forex.originName]: Yup.mixed().checkRequired(
        "lang_forex_is_required"
      ),
      [formField.invesment_exp.commodity.originName]: Yup.mixed().checkRequired(
        "lang_commodity_is_required"
      ),
      [formField.invesment_exp.other.originName]:
        Yup.mixed().checkMaxLength(200),
      [formField.invesment_exp.other_period.originName]: Yup.mixed().when(
        formField.invesment_exp.other.originName,
        (otherInvestmentExp, schema) => {
          return otherInvestmentExp
            ? schema.checkRequired("lang_please_enter_other_period")
            : schema;
        }
      ),
    }),
    [formField.investment_objectives_and_risk_tolerance.name]:
      Yup.object().shape({
        [formField.investment_objectives_and_risk_tolerance
          .investment_objectives.originName]: Yup.mixed().checkRequired(
          "lang_investment_objectives_is_required"
        ),
        [formField.investment_objectives_and_risk_tolerance
          .investment_objectives_desc.originName]: Yup.mixed().when(
          formField.investment_objectives_and_risk_tolerance
            .investment_objectives.originName,
          (investmentObjectives, schema) => {
            return investmentObjectives === INVESTMENT_OBJECTIVES.OTHER
              ? schema
                  .checkRequired("lang_description_investment_obj_required")
                  .checkMaxLength(200, "lang_description_max")
              : schema;
          }
        ),
        [formField.investment_objectives_and_risk_tolerance.investment_time
          .originName]: Yup.mixed().checkRequired(
          "lang_investment_time_is_required"
        ),
        [formField.investment_objectives_and_risk_tolerance.investment_volume
          .originName]: Yup.mixed().checkRequired(
          "lang_investment_volume_is_required"
        ),
        [formField.investment_objectives_and_risk_tolerance.risk_tolerance
          .originName]: Yup.mixed().checkRequired(
          "lang_risk_tolerance_is_required"
        ),
      }),
    [formField.derivative_awareness.name]:
      Yup.mixed().checkDerivativesAwarenessAssessment(),
  }),
  [dicStep.IDENTITY_DISCLOSURES_TAX_INFORMATION]: Yup.object().shape({
    [formField.identity_disclosure.name]: Yup.object().shape({
      [formField.identity_disclosure.licensed_person_comp_name.originName]:
        Yup.mixed().when(
          formField.identity_disclosure.registered_securities_futures
            .originName,
          (registeredSecuritiesFutures, schema) => {
            return registeredSecuritiesFutures
              ? schema
                  .checkRequired("lang_name_of_the_licensed_required")
                  .checkMaxLength(
                    200,
                    "lang_name_of_the_licensed_max_length_error"
                  )
              : schema;
          }
        ),
      [formField.identity_disclosure.upload_consent_letter.originName]:
        Yup.mixed().when(
          formField.identity_disclosure.registered_securities_futures
            .originName,
          (registeredSecuritiesFutures, schema) => {
            return registeredSecuritiesFutures
              ? schema
                  .checkRequired("lang_upload_consent_letter_required")
                  .checkFile("lang_photo_size_limit_exceeded")
              : schema;
          }
        ),
      [formField.identity_disclosure.relationship_desc.originName]:
        Yup.mixed().when(
          formField.identity_disclosure.related_to_public_function.originName,
          (relatedToPublicFunction, schema) => {
            return relatedToPublicFunction
              ? schema
                  .checkRequired("lang_relationship_desc")
                  .checkMaxLength(300, "lang_litmit_descrip")
              : schema;
          }
        ),
      [formField.identity_disclosure.comp_officer_name.originName]:
        Yup.mixed().when(
          formField.identity_disclosure.related_to_magpie.originName,
          (relatedToMagpie, schema) => {
            return relatedToMagpie
              ? schema
                  .checkRequired("lang_comp_officer_name")
                  .checkMaxLength(100, "lang_comp_officer_name_format_err")
              : schema;
          }
        ),
      [formField.identity_disclosure.comp_officer_relationship.originName]:
        Yup.mixed().when(
          formField.identity_disclosure.related_to_magpie.originName,
          (relatedToMagpie, schema) => {
            return relatedToMagpie
              ? schema
                  .checkRequired("lang_desc_relationship")
                  .checkMaxLength(300, "lang_litmit_descrip")
              : schema;
          }
        ),
      [formField.identity_disclosure.spouse_name.originName]: Yup.mixed().when(
        formField.identity_disclosure.spouse_margin.originName,
        (spouseMargin, schema) => {
          return spouseMargin
            ? schema
                .checkRequired()
                .checkMaxLength(100, "lang_spouse_name_format_err")
            : schema;
        }
      ),
      [formField.identity_disclosure.spouse_account_number.originName]:
        Yup.mixed().when(
          formField.identity_disclosure.spouse_margin.originName,
          (spouseMargin, schema) => {
            return spouseMargin
              ? schema
                  .checkRequired("lang_spouse_account_number_required")
                  .checkMaxLength(20, "lang_spouse_account_number_format_err")
              : schema;
          }
        ),
      [formField.identity_disclosure.client_name.originName]: Yup.mixed().when(
        formField.identity_disclosure.control_magpie_voting.originName,
        (controlMagpieVoting, schema) => {
          return controlMagpieVoting
            ? schema
                .checkRequired("lang_client_name_required")
                .checkMaxLength(100, "lang_client_name_format_err")
            : schema;
        }
      ),
      [formField.identity_disclosure.client_account_number.originName]:
        Yup.mixed().when(
          formField.identity_disclosure.control_magpie_voting.originName,
          (controlMagpieVoting, schema) => {
            return controlMagpieVoting
              ? schema
                  .checkRequired("lang_client_account_number_required")
                  .checkMaxLength(20, "lang_client_account_number_format_err")
              : schema;
          }
        ),
    }),
    [formField.tax_information.name]: Yup.array().of(
      Yup.object().shape({
        [formField.tax_information.tax_country.originName]:
          Yup.mixed().checkRequired("lang_tax_country_is_required"),
        [formField.tax_information.tax_id.originName]: Yup.mixed().when(
          formField.tax_information.is_there_taxid.originName,
          (isThereTaxId, schema) => {
            return isThereTaxId
              ? schema
                  .checkRequired("lang_tax_id_required")
                  .checkTaxIdNumber("lang_tax_id_format_error")
              : schema;
          }
        ),
        [formField.tax_information.taxid_reason.originName]: Yup.mixed().when(
          formField.tax_information.is_there_taxid.originName,
          (isThereTaxId, schema) => {
            return isThereTaxId
              ? schema
              : schema.checkRequired("lang_taxid_reason_is_required");
          }
        ),
        [formField.tax_information.taxid_reason_desc.originName]:
          Yup.mixed().when(
            [
              formField.tax_information.is_there_taxid.originName,
              formField.tax_information.taxid_reason.originName,
            ],
            (isThereTaxId, taxIdReason, schema) => {
              return isThereTaxId || taxIdReason !== TAX_ID_REASON.REASON_B
                ? schema
                : schema
                    .checkRequired("lang_taxid_reason_desc_required")
                    .checkMaxLength(300, "lang_description_max");
            }
          ),
      })
    ),
    [formField.us_person_info.name]: Yup.object().shape({
      [formField.us_person_info.country_of_citizenship.originName]:
        Yup.mixed().when(
          formField.us_person_info.are_you_us_person.originName,
          (isUSPerson, schema) => {
            return isUSPerson
              ? schema
              : schema.checkRequired("lang_country_of_citizenship_is_required");
          }
        ),
      [formField.us_person_info.country_of_tax_treaty_benefits.originName]:
        Yup.mixed().when(
          formField.us_person_info.are_you_us_person.originName,
          (isUSPerson, schema) => {
            return isUSPerson
              ? schema
              : schema.checkRequired(
                  "lang_country_of_tax_treaty_benefits_is_required"
                );
          }
        ),
      [formField.us_person_info.taxpayer_id.originName]: Yup.mixed().when(
        formField.us_person_info.are_you_us_person.originName,
        (isUSPerson, schema) => {
          return isUSPerson
            ? schema
                .checkRequired("lang_taxpayer_id_is_required")
                .checkTaxPayerId()
            : schema;
        }
      ),
    }),
  }),
  [dicStep.ACCOUNT_TYPE]: Yup.object().shape({}),
  [dicStep.RISK_DISCLOSURE]: Yup.object().shape({
    [formField.risk_disclosure.name]:
      Yup.mixed().checkRiskDisclosureAndDeclarationSignature(),
  }),
  [dicStep.ESIGNATURE]: Yup.object().shape({
    [formField.e_signature.name]: Yup.mixed().checkRequired(
      "lang_e_signature_is_required"
    ),
  }),
};

// const validationSchema1 = []

export default validationSchema;
