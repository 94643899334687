import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { makeStyles, useTheme } from "@mui/styles";
import { Trans } from "react-i18next";
import Onboarding from "containers/Onboarding/Onboarding";
import dataStorage from "dataStorage";
import initialValues from "containers/Onboarding/FormModels/InitialValue";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import ChooseCountry from "containers/ChooseCountry/ChooseCountry";
import { ID_TYPE, BANK_LOCATION } from "constants/Options/options";
import { clone } from "helpers/utils";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: theme.spacing(12),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
    },
    "& button": {
      width: "fit-content",
    },
    "& .MuiFormControl-root": {
      padding: theme.spacing(2, 0),
    },
  },
  subTitle: {
    color: theme.palette.text.secondary,
  },
}));

export default function ChooseBankLocation(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [location, setLocation] = React.useState("");
  const [already, setAlready] = React.useState(false);

  React.useEffect(() => {
    initialValues.personal_information.mobile_number = dataStorage.phone;
  }, []);

  /**
   * Handle back to previous step
   */
  const onBack = () => {
    setAlready(false);
  };

  /**
   * Handle setup config, variables and render correct step after choose onboarding flow
   */
  if (already) {
    const cloneProps = clone(props);
    if (cloneProps.data && Object.keys(cloneProps.data).length) {
      if (!cloneProps.data.personal_information)
        cloneProps.data.personal_information = {};
      if (!cloneProps.data.applicant_verification_id)
        cloneProps.data.applicant_verification_id = {};
      if (
        cloneProps.data.applicant_country === BANK_LOCATION.OVERSEAS &&
        location === BANK_LOCATION.HONG_KONG
      ) {
        // cloneProps.data.applicant_verification_id.applicant_nationality = 'HKG'
        cloneProps.data.applicant_verification_id.applicant_nationality = "";
        cloneProps.data.applicant_verification_id.country_of_residence = "HKG";
        cloneProps.step = 0;
      } else if (
        cloneProps.data.applicant_country === BANK_LOCATION.HONG_KONG &&
        location === BANK_LOCATION.OVERSEAS
      ) {
        // cloneProps.data.applicant_verification_id.applicant_nationality = ListNationality[0].value
        // cloneProps.data.applicant_verification_id.country_of_residence = ListCountry[0].value
        cloneProps.data.applicant_verification_id.applicant_nationality = "";
        cloneProps.data.applicant_verification_id.country_of_residence = "";
        cloneProps.data.applicant_country = location;
        cloneProps.step = 0;
        return <ChooseCountry {...cloneProps} onBack={onBack} />;
      }
      cloneProps.data.applicant_country = location;
      if (location === BANK_LOCATION.OVERSEAS) {
        return <ChooseCountry {...cloneProps} onBack={onBack} />;
      } else {
        return <Onboarding {...cloneProps} onBack={onBack} />;
      }
    }
    if (location === BANK_LOCATION.OVERSEAS) {
      // initialValues.applicant_verification_id.applicant_nationality = ListNationality[0].value
      // initialValues.applicant_verification_id.country_of_residence = ListCountry[0].value
      initialValues.applicant_verification_id.applicant_nationality = "";
      initialValues.applicant_verification_id.country_of_residence = "";
      return <ChooseCountry onBack={onBack} />;
    } else {
      // initialValues.applicant_verification_id.applicant_nationality = 'HKG'
      initialValues.applicant_verification_id.applicant_nationality = "";
      initialValues.applicant_verification_id.country_of_residence = "HKG";
      return <Onboarding onBack={onBack} />;
    }
  }

  /**
   * Handle onboarding flow change (HK or Oversea)
   * @param {Event} event select change event
   */
  const handleChange = (event) => {
    setLocation(event.target.value);
    initialValues.applicant_country = event.target.value;
    if (event.target.value === BANK_LOCATION.OVERSEAS) {
      dataStorage.isOverSeas = true;
      initialValues.applicant_verification_id.id_type = ID_TYPE.PASSPORT;
    } else {
      dataStorage.isOverSeas = false;
      initialValues.applicant_verification_id.id_type = ID_TYPE.HK_ID_CARD;
    }
  };

  /**
   * Render option create account (by HK flow or Oversea flow)
   * @returns HTML UI for onboarding flow select
   */
  const renderLocationSelect = () => {
    return (
      <FormControl sx={{ py: 4 }}>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={location}
          onChange={handleChange}
        >
          <FormControlLabel
            value={BANK_LOCATION.HONG_KONG}
            control={<Radio />}
            label={
              <Trans
                components={[
                  <strong />,
                  <span style={{ color: theme.palette.text.secondary }} />,
                ]}
              >
                lang_hong_kong_bank_acct_and_residency
              </Trans>
            }
          />
          <FormControlLabel
            value={BANK_LOCATION.OVERSEAS}
            control={<Radio />}
            label={
              <Trans
                components={[
                  <strong />,
                  <span style={{ color: theme.palette.text.secondary }} />,
                ]}
              >
                lang_overseas_bank_acct_and_residency_excluding_us
              </Trans>
            }
          />
        </RadioGroup>
      </FormControl>
    );
  };

  return (
    <Box className={classes.container}>
      <Typography variant="h6">
        <Trans>lang_open_with_magpie_securities</Trans>
      </Typography>
      <Typography variant="body1" className={classes.subTitle}>
        <Trans>lang_bank_location_and_residency</Trans>
      </Typography>
      {renderLocationSelect()}
      <Box display="flex" justifyContent="center" sx={{ mt: 3 }}>
        <Button
          variant="contained"
          disabled={!location}
          onClick={() => setAlready(true)}
        >
          <Trans>lang_open_account_now</Trans>
        </Button>
      </Box>
    </Box>
  );
}
