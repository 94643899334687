const dataStorage = {
  theme: "dark",
  deviceId: "",
  session: {},
  phone: "",
  accountStatus: "",
  isOverSeas: true,
  timeSendOTP: {},
  auth: {},
  config: {},
};
export default dataStorage;
