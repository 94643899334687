import React from "react";
import at from "lodash/at";
import { useField } from "formik";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormHelperText from "@mui/material/FormHelperText";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import { Trans } from "react-i18next";
import dataStorage from "dataStorage";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    "&.radio-horizontal": {
      width: "fit-content",
      "& $group": {
        display: "flex",
        flexWrap: "nowrap",
        flexDirection: "row",
        alignItems: "center",
      },
    },
    "& .MuiFormControlLabel-root": {
      alignItems: "flex-start",
      paddingTop: theme.spacing(2),
      "& .MuiRadio-root": {
        marginTop: -8,
      },
    },
  },
  group: {
    margin: theme.spacing(0),
  },
}));

function RadioField(props) {
  const { label, isHorizontal, data, style, translate = true, ...rest } = props;
  const classes = useStyles();
  const [field, meta, helper] = useField(props);
  const { setValue: setValueForm, setTouched } = helper || {};
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, "touched", "error");
  const isError = touched && error && true;

  /**
   * Render helper text or error text
   * @returns translated text
   */
  function _renderHelperText() {
    if (isError) {
      return (
        <FormHelperText>
          <Trans values={{ label: dataStorage.translate(label) }}>
            {error}
          </Trans>
        </FormHelperText>
      );
    }
  }

  /**
   * Update new value for radio
   * @param {Event} event material ui Radio change event
   */
  const _onChange = (event) => {
    if (!touched) setTouched(true);
    setValueForm(event.target.value, true);
  };

  return (
    <FormControl
      {...rest}
      error={isError}
      className={classnames(classes.formControl, {
        "radio-horizontal": isHorizontal,
        "radio-vertical": !isHorizontal,
      })}
    >
      {label ? (
        <FormLabel component="legend">
          {translate ? <Trans>{label}</Trans> : label}
        </FormLabel>
      ) : (
        <React.Fragment />
      )}
      <RadioGroup
        aria-label={label}
        name={label}
        className={classes.group}
        style={style}
        value={selectedValue}
        onBlur={field.onBlur}
        onChange={_onChange}
      >
        {data.map((item, index) => (
          <FormControlLabel
            key={index}
            value={item.value}
            control={<Radio />}
            label={
              translate ? (
                <Trans {...(item.transProps || {})}>{item.label}</Trans>
              ) : (
                item.label
              )
            }
          />
        ))}
      </RadioGroup>
      <input
        readOnly
        style={{
          opacity: 0,
          position: "absolute",
          pointerEvents: "none",
          left: 136,
        }}
        type="checkbox"
        id="gender"
        required={rest.required}
        checked={
          !!(selectedValue && data.length
            ? selectedValue.value ?? selectedValue
            : "")
        }
      />
      {_renderHelperText()}
    </FormControl>
  );
}

export default RadioField;
