/* eslint-disable max-lines */
// keep each step in a file

import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import formModel from "containers/Onboarding/FormModels/Models";
import { useFormikContext } from "formik";
import { SelectField, InputField, RadioField } from "components/CustomFields";
import {
  ANNUAL_INCOME_OPTIONS,
  NET_ASSETS_VALUE_OPTIONS,
  RESIDENTIAL_STATUS,
  RESIDENTIAL_STATUS_OPTIONS,
  SOURCE_OF_FUND,
  SOURCE_OF_FUND_OPTIONS,
  INVESTMENT_EXP_OPTIONS,
  INVESTMENT_OBJECTIVES,
  INVESTMENT_OBJECTIVES_OPTIONS,
  ESTIMATED_INVESTMENT_TIME_OPTIONS,
  RISK_TOLERANCE_OPTIONS,
  DERIVATIVES_AWARENESS_ASSESSMENT_OPTIONS,
} from "constants/Options/options";
import { Trans } from "react-i18next";

const { formField } = formModel;

const FinancialAndInvestment = () => {
  const { values } = useFormikContext();

  const renderInvestmentExperience = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Typography variant="h6">
            <Trans>lang_investment_experience</Trans>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectField
            required
            name={formField.invesment_exp.stock.name}
            label={formField.invesment_exp.stock.label}
            data={INVESTMENT_EXP_OPTIONS}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectField
            required
            name={formField.invesment_exp.cbbc.name}
            label={formField.invesment_exp.cbbc.label}
            data={INVESTMENT_EXP_OPTIONS}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectField
            required
            name={formField.invesment_exp.derivatives.name}
            label={formField.invesment_exp.derivatives.label}
            data={INVESTMENT_EXP_OPTIONS}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectField
            required
            name={formField.invesment_exp.futures.name}
            label={formField.invesment_exp.futures.label}
            data={INVESTMENT_EXP_OPTIONS}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectField
            required
            name={formField.invesment_exp.options_warrants.name}
            label={formField.invesment_exp.options_warrants.label}
            data={INVESTMENT_EXP_OPTIONS}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectField
            required
            name={formField.invesment_exp.forex.name}
            label={formField.invesment_exp.forex.label}
            data={INVESTMENT_EXP_OPTIONS}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectField
            required
            name={formField.invesment_exp.commodity.name}
            label={formField.invesment_exp.commodity.label}
            data={INVESTMENT_EXP_OPTIONS}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            maxLength={200}
            name={formField.invesment_exp.other.name}
            label={formField.invesment_exp.other.label}
            fullWidth
          />
        </Grid>
        {values.invesment_exp.other ? (
          <Grid item xs={12} md={6}>
            <SelectField
              required
              name={formField.invesment_exp.other_period.name}
              label={formField.invesment_exp.other_period.label}
              data={INVESTMENT_EXP_OPTIONS}
              fullWidth
            />
          </Grid>
        ) : (
          <React.Fragment />
        )}
      </React.Fragment>
    );
  };

  const renderInvestmentObjectivesAndRiskTolerance = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Typography variant="h6">
            <Trans>lang_investment_objectives_and_risk_tolerance</Trans>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectField
            required
            name={
              formField.investment_objectives_and_risk_tolerance
                .investment_objectives.name
            }
            label={
              formField.investment_objectives_and_risk_tolerance
                .investment_objectives.label
            }
            data={INVESTMENT_OBJECTIVES_OPTIONS}
            fullWidth
          />
        </Grid>
        {values.investment_objectives_and_risk_tolerance
          ?.investment_objectives === INVESTMENT_OBJECTIVES.OTHER ? (
          <Grid item xs={12} md={6}>
            <InputField
              required
              maxLength={200}
              name={
                formField.investment_objectives_and_risk_tolerance
                  .investment_objectives_desc.name
              }
              label={
                formField.investment_objectives_and_risk_tolerance
                  .investment_objectives_desc.label
              }
              fullWidth
            />
          </Grid>
        ) : (
          <React.Fragment />
        )}
        <Grid item xs={12} md={6}>
          <SelectField
            required
            name={
              formField.investment_objectives_and_risk_tolerance.investment_time
                .name
            }
            label={
              formField.investment_objectives_and_risk_tolerance.investment_time
                .label
            }
            data={ESTIMATED_INVESTMENT_TIME_OPTIONS}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectField
            required
            name={
              formField.investment_objectives_and_risk_tolerance
                .investment_volume.name
            }
            label={
              formField.investment_objectives_and_risk_tolerance
                .investment_volume.label
            }
            data={NET_ASSETS_VALUE_OPTIONS}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectField
            required
            name={
              formField.investment_objectives_and_risk_tolerance.risk_tolerance
                .name
            }
            label={
              formField.investment_objectives_and_risk_tolerance.risk_tolerance
                .label
            }
            data={RISK_TOLERANCE_OPTIONS}
            fullWidth
          />
        </Grid>
      </React.Fragment>
    );
  };

  const renderDerivativesAwarenessAssessment = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <RadioField
            required
            name={formField.derivative_awareness.name}
            label={formField.derivative_awareness.label}
            data={DERIVATIVES_AWARENESS_ASSESSMENT_OPTIONS}
            fullWidth
          />
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <SelectField
          required
          name={formField.personal_financial_information.annual_income.name}
          label={formField.personal_financial_information.annual_income.label}
          data={ANNUAL_INCOME_OPTIONS}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectField
          required
          name={formField.personal_financial_information.net_asset_value.name}
          label={formField.personal_financial_information.net_asset_value.label}
          data={NET_ASSETS_VALUE_OPTIONS}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectField
          required
          name={formField.personal_financial_information.housing_status.name}
          label={formField.personal_financial_information.housing_status.label}
          data={RESIDENTIAL_STATUS_OPTIONS}
          fullWidth
        />
      </Grid>
      {values.personal_financial_information?.housing_status ===
      RESIDENTIAL_STATUS.OTHER ? (
        <Grid item xs={12} md={6}>
          <InputField
            required
            maxLength={200}
            name={
              formField.personal_financial_information.housing_status_desc.name
            }
            label={
              formField.personal_financial_information.housing_status_desc.label
            }
            fullWidth
          />
        </Grid>
      ) : (
        <React.Fragment />
      )}
      <Grid item xs={12} md={6}>
        <SelectField
          required
          multiple
          name={formField.personal_financial_information.source_of_fund.name}
          label={formField.personal_financial_information.source_of_fund.label}
          data={SOURCE_OF_FUND_OPTIONS}
          fullWidth
        />
      </Grid>
      {values.personal_financial_information?.source_of_fund?.includes(
        SOURCE_OF_FUND.OTHER
      ) ? (
        <Grid item xs={12} md={6}>
          <InputField
            required
            maxLength={200}
            name={
              formField.personal_financial_information.source_of_fund_desc.name
            }
            label={
              formField.personal_financial_information.source_of_fund_desc.label
            }
            fullWidth
          />
        </Grid>
      ) : (
        <React.Fragment />
      )}
      <Grid item xs={12} md={6}>
        <SelectField
          required
          name={formField.personal_financial_information.liquid_net_worth.name}
          label={
            formField.personal_financial_information.liquid_net_worth.label
          }
          data={NET_ASSETS_VALUE_OPTIONS}
          fullWidth
        />
      </Grid>
      {renderInvestmentExperience()}
      {renderInvestmentObjectivesAndRiskTolerance()}
      {renderDerivativesAwarenessAssessment()}
    </Grid>
  );
};

export default FinancialAndInvestment;
