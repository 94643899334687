/* eslint-disable max-lines */

import logger from "helpers/logger";
import formModel from "containers/Onboarding/FormModels/Models";
import at from "lodash/at";
import dataStorage from "dataStorage";

const { formField } = formModel;

/**
 * Support scroll to top when next or prev screen or when have error message
 */
export function scrollToTop() {
  const root = document.getElementById("topMark");
  root?.scrollIntoView?.({
    behavior: "smooth",
    block: "center",
    inline: "center",
  });
}

/**
 * Add cutom capitalize method for string builder
 */
export function makeCapitalizeFunc() {
  // eslint-disable-next-line no-extend-native
  String.prototype.toCapitalize = function () {
    return this.split(" ")
      .map((x) => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())
      .join(" ");
  };
}

/**
 * Remove vietnamese character
 * @param {string} str origin string
 * @returns new string after remove viet nam symbol
 */
export function removeVietnamese(str) {
  // Gộp nhiều dấu space thành 1 space
  str = str.replace(/\s+/g, " ");
  // loại bỏ toàn bộ dấu space (nếu có) ở 2 đầu của chuỗi
  str = str.trim();
  // bắt đầu xóa dấu tiếng việt  trong chuỗi
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  return str;
}

/**
 * Convert string to boolean
 * @param {string | boolean} str value need convert
 * @returns boolean type of input value
 */
export function booleaner(str) {
  if (typeof str === "boolean") return str;
  return !!(str === "true");
}

/**
 * Support clone object without reference
 * @param {any} any object need clone
 * @returns new object after clone
 */
export function clone(any) {
  try {
    return any ? JSON.parse(JSON.stringify(any)) : any;
  } catch (error) {
    return any;
  }
}

/**
 * Support transfer file to base64 data type
 * @param {File} file file need transfer to base64
 * @returns a promise
 */
export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

/**
 * Check to keep or delete field before submit base on list field depend
 * @param {object} listDepend list condition to show field
 * @param {object} values origin data object
 * @param {number} arrIndex index item of array need to check show
 * @returns
 */
export function checkShow(listDepend, values, arrIndex = 0) {
  const keys = Object.keys(listDepend);
  for (let index = 0; index < keys.length; index++) {
    let key = keys[index];
    if (key.includes("[index]")) {
      key = key.replace("[index]", `[${arrIndex}]`);
    }
    const dependValue = at(values, key)[0] ?? {};
    const refs = listDepend[keys[index]];
    if (Array.isArray(dependValue)) {
      for (let i = 0; i < dependValue.length; i++) {
        if (refs.includes(dependValue[i])) return true;
      }
      return false;
    }
    if (typeof dependValue === "string" && dependValue.split(",").length) {
      const list = dependValue.split(",");
      for (let i = 0; i < list.length; i++) {
        if (refs.includes(list[i])) return true;
      }
      return false;
    } else {
      if (!refs.includes(dependValue)) return false;
    }
  }
  return true;
}

/**
 * Handle object data before submit
 * @param {object} object need handle
 * @param {object} originObject origin object (formik values form)
 * @param {string} path path at object need to check
 * @returns new object after clean
 */
export function handleDataBeforeSubmit(object, originObject, path = "") {
  try {
    Object.entries(object).forEach(([k, v]) => {
      let pathField = `${path ? path + "." : ""}${k}`;
      if (!isNaN(k)) {
        pathField = `${path}[${k}]`;
      }
      if (v && typeof v === "object") {
        handleDataBeforeSubmit(v, originObject, pathField);
      }
      if (
        (v && typeof v === "object" && !Object.keys(v).length) ||
        v === null ||
        v === undefined
      ) {
        if (Array.isArray(object)) {
          object.splice(k, 1);
        } else {
          delete object[k];
        }
      } else {
        const model = at(formField, pathField.replace(/\[\d]/, ""))[0] ?? {};
        if (model && model.depend) {
          let index = 0;
          if (pathField !== pathField.replace(/\[\d]/, "")) {
            index = +pathField.replace(/^.*\[(\d)\].*$/, "$1");
          }
          // if (pathField !== pathField.replace(/\[\d]/, '')) {
          //     let values = clone(originObject)
          //     const key = pathField.split('[')[0]
          //     const index = +pathField.replace(/^.*\[(\d)\].*$/, '$1')
          //     values[key] = values[key][index]
          //     if (!checkShow(model.depend, values)) {
          //         delete object[k]
          //     }
          // } else {
          //     if (!checkShow(model.depend, originObject)) {
          //         delete object[k]
          //     }
          // }
          if (!checkShow(model.depend, originObject, index)) {
            delete object[k];
          }
        }
      }
    });
    return object;
  } catch (error) {
    logger.error("handleDataBeforeSubmit error: ", error);
  }
}

// format: '+phone_code|phone_number'
/**
 * Hidden some number in a phone number
 * @param {string} phone phone number need format
 * @returns phone number after hidden some number
 */
export const hiddenPhone = (phone) => {
  try {
    if (!phone) return "";
    let phoneNumber = phone.split("|")[1] || phone;
    if (phoneNumber.startsWith("0")) {
      phoneNumber = phoneNumber.slice(1);
      phone.replace(/^(.*)\s(.*)$/, "$1" + " " + phoneNumber);
    }
    phone = phone.replace(/^(.*)(....)(..)$/, "$1****$3").replace(/\|/, "");
    return phone;
  } catch (error) {
    console.error("convert phone to * error: ", error);
    return "";
  }
};

/**
 * Check if a api link is valid
 * @param {string} string api link need to check
 * @returns true if is valid url, esle return false
 */
export function isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

/**
 * Check user platform is mobile?
 * @returns boolean true if user current using mobile browser, esle return false
 */
export const isMobile = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

/**
 * Get state of next button by data and step
 * @param {object} values formik form values
 * @returns boolean is next or submit button status
 */
export const checkBtnDisable = (values, kycResult, activeStep, errors) => {
  if (dataStorage.isOverSeas) {
    switch (activeStep) {
      case 1: {
        if (
          kycResult.current?.PHOTO_MATCH?.decision +
            kycResult.current?.DOCUMENT?.decision +
            kycResult.current?.LIVENESS?.decision ===
          "PASSEDPASSEDPASSED"
        )
          return false;
        return true;
      }
      case 5: {
        // Required to select No all
        if (
          values.identity_disclosure.acting_as_intermediary ||
          errors?.identity_disclosure
        ) {
          return true;
        }
        return false;
      }
      case 7: {
        // Must check all checkbox
        if (!values.risk_disclosure || values.risk_disclosure.length !== 10) {
          return true;
        }
        return false;
      }
      default:
        return false;
    }
  } else {
    switch (activeStep) {
      case 4: {
        // Required to select No all
        if (
          values.identity_disclosure.acting_as_intermediary ||
          errors?.identity_disclosure
        ) {
          return true;
        }
        return false;
      }
      case 6: {
        // Must check all checkbox
        if (!values.risk_disclosure || values.risk_disclosure.length !== 10) {
          return true;
        }
        return false;
      }
      default:
        return false;
    }
  }
};
