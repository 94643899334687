import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "center",
    minHeight: 0,
    backgroundColor: "transparent",
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderRadius: 15,
    minHeight: "400px",
    padding: theme.spacing(2),
    [theme.breakpoints.up(808 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  appHeader: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up(808 + theme.spacing(3) * 2)]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  appbar: {
    background: "#003300",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  appFooter: {
    padding: theme.spacing(2),
    [theme.breakpoints.down(600)]: {
      padding: theme.spacing(2),
    },
    backgroundColor: "transparent",
    "& *": {
      color: "#ffffff",
    },
  },
}));
