import { useQuery, useMutation } from "react-query";
import { postData, getData } from "api/request";
import { getDraftUrl } from "api/api";
import initialValues from "containers/Onboarding/FormModels/InitialValue";
import { EMPTY_TAX_RESIDENCY } from "constants/constants";
import { clone } from "helpers/utils";
import moment from "moment";

const handleDraft = (draft = {}) => {
  for (const key in draft) {
    if (typeof draft[key] === "object" && !Array.isArray(draft[key])) {
      draft[key] = Object.assign(initialValues[key] || {}, draft[key]);
    } else if (key === "tax_information" && Array.isArray(draft[key])) {
      for (let index = 0; index < draft[key].length; index++) {
        draft[key][index] = Object.assign(
          clone(EMPTY_TAX_RESIDENCY),
          draft[key][index]
        );
      }
    }
  }
};

const getDraft = async () => {
  const result = await getData(getDraftUrl());
  result && handleDraft(result);
  return result;
};

const useGetDraft = (options = {}) => {
  return useQuery(["getDraft"], getDraft, options);
};

const saveDraft = async (data) => {
  if (Array.isArray(data.personal_financial_information.source_of_fund)) {
    data.personal_financial_information.source_of_fund =
      data.personal_financial_information.source_of_fund.join(",");
  }
  if (Array.isArray(data.account_type)) {
    data.account_type = data.account_type.join(",");
  }
  if (data?.personal_information?.dob) {
    data.personal_information.dob = moment(
      data.personal_information.dob
    ).format("YYYY/MM/DD");
  }
  data.e_signature = "";
  const result = await postData(getDraftUrl(), data);
  return result;
};

const useSaveDraft = () => {
  return useMutation((variables) => saveDraft(variables));
};

export { useGetDraft, useSaveDraft };
