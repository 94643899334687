import React from "react";
import Grid from "@mui/material/Grid";
import formModel from "containers/Onboarding/FormModels/Models";
import { MultipleSelectField } from "components/CustomFields";
import { ACCOUNT_TYPE_OPTIONS } from "constants/Options/options";

const { formField } = formModel;

const AccountType = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <MultipleSelectField
          name={formField.account_type.name}
          label={formField.account_type.label}
          data={ACCOUNT_TYPE_OPTIONS}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default AccountType;
