const taxCountries = [
  { code: "CN", value: "CHN", label: "China", phone: "86" },
  { code: "US", value: "USA", label: "United States", phone: "1" },
  { code: "HK", value: "HKG", label: "Hong Kong, SAR, China", phone: "852" },
  { code: "AU", value: "AUS", label: "Australia", phone: "61" },
  { code: "AT", value: "AUT", label: "Austria", phone: "43" },
  { code: "BE", value: "BEL", label: "Belgium", phone: "32" },
  { code: "CA", value: "CAN", label: "Canada", phone: "1" },
  { code: "IE", value: "IRL", label: "Ireland", phone: "353" },
  { code: "IL", value: "ISR", label: "Israel", phone: "972" },
  { code: "IT", value: "ITA", label: "Italy", phone: "39" },
  { code: "NO", value: "NOR", label: "Norway", phone: "47" },
  { code: "PT", value: "PRT", label: "Portugal", phone: "351" },
  { code: "SG", value: "SGP", label: "Singapore", phone: "65" },
  { code: "ES", value: "ESP", label: "Spain", phone: "34" },
  { code: "SE", value: "SWE", label: "Sweden", phone: "46" },
  { code: "CH", value: "CHE", label: "Switzerland", phone: "41" },
  { code: "GB", value: "GBR", label: "United Kingdom", phone: "44" },
];

export default taxCountries;
