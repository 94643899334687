import React from "react";
import LogoDark from "assets/logoDark.svg";
import LogoLight from "assets/logoLight.svg";

const Logo = () => {
  return (
    <React.Fragment>
      <img className="darkResource logoDark" src={LogoDark} alt="LogoDark" />
      <img
        className="lightResource logoLight"
        src={LogoLight}
        alt="LogoLight"
      />
    </React.Fragment>
  );
};

export default Logo;
