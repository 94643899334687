import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import { makeStyles } from "@mui/styles";
import { Trans } from "react-i18next";
import ChooseBankLocation from "containers/ChooseBankLocation/ChooseBankLocation";
import passportTip from "assets/passport_tip.svg";
import wifiTip from "assets/wifi_tip.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: theme.spacing(12),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
    },
    "& .MuiListItem-root": {
      paddingLeft: 0,
    },
    "& button": {
      width: "fit-content",
    },
  },
}));

export default function ServicesAgreement() {
  const classes = useStyles();
  const [agree, setAgree] = React.useState(false);
  const [already, setAlready] = React.useState(false);

  /**
   * if user agree go to next step
   */
  if (already) {
    return <ChooseBankLocation />;
  }

  /**
   * Handle check or uncheck agree policy and terms
   * @param {Event} e check box change event
   */
  const onCheck = (e) => {
    setAgree(e.target.checked);
  };

  return (
    <Box className={classes.container}>
      <Typography variant="h6">
        <Trans>lang_account_opening_tips</Trans>
      </Typography>
      <List>
        <ListItem>
          <ListItemAvatar>
            <Avatar src={passportTip} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <span>
                <Trans>lang_id_card_tip</Trans>
              </span>
            }
            secondary={
              <span>
                <Trans>lang_id_card_tip_explain</Trans>
              </span>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar src={wifiTip} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <span>
                <Trans>lang_wifi_tip</Trans>
              </span>
            }
            secondary={
              <span>
                <Trans>lang_wifi_tip_explain</Trans>
              </span>
            }
          />
        </ListItem>
      </List>
      <FormControlLabel
        control={<Checkbox onChange={onCheck} />}
        label={
          <Trans
            i18nKey="lang_agree_confirm_text"
            components={[<Link href="/pdf/CA-v81111.pdf" target="_blank" />]}
          ></Trans>
        }
      />
      <Box display="flex" justifyContent="center" sx={{ mt: 3 }}>
        <Button
          variant="contained"
          disabled={!agree}
          onClick={() => setAlready(true)}
        >
          <Trans>lang_open_account_now</Trans>
        </Button>
      </Box>
    </Box>
  );
}
