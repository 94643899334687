import React from "react";
import MaterialLayout from "layouts/MaterialLayout";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./i18n/i18n";
import OnboardingPage from "pages/OnboardingPage/OnboardingPage";
import LoginPage from "pages/LoginPage/LoginPage";
import ProtectedRoute from "routers/ProtectedRoute";
import { withTranslation } from "react-i18next";
import dataStorage from "dataStorage";

export const LoginContext = React.createContext();

function App() {
  const [isLogin, setIsLogin] = React.useState(!!dataStorage.auth?.accessToken);

  /**
   * Change login state
   * @param {boolean} newLoginState state of login
   */
  const setLogin = (newLoginState) => {
    setIsLogin(newLoginState);
  };

  return (
    <LoginContext.Provider value={{ isLogin, setLogin }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MaterialLayout />}>
            <Route
              path=""
              element={
                <ProtectedRoute>
                  <OnboardingPage />
                </ProtectedRoute>
              }
            />
            <Route path="login" element={<LoginPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </LoginContext.Provider>
  );
}

export default withTranslation()(App);
