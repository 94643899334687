import React from "react";
import { at } from "lodash";
import { useField } from "formik";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import { makeStyles } from "@mui/styles";
import { Trans } from "react-i18next";
import dataStorage from "dataStorage";

const useStyles = makeStyles((theme) => ({
  formControl: {
    "& .MuiFormControlLabel-root": {
      alignItems: "flex-start",
      hyphens: "auto",
      paddingTop: theme.spacing(2),
      "& .MuiCheckbox-root": {
        marginTop: -8,
      },
    },
  },
}));

export default function MultipleSelectField(props) {
  const classes = useStyles();
  const { label, data, translate = true, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const [touched, error] = at(meta, "touched", "error");
  const isError = touched && error && true;

  /**
   * Render helper text or error text
   * @returns translated text
   */
  function _renderHelperText() {
    if (touched && error) {
      return (
        <FormHelperText>
          <Trans values={{ label: dataStorage.translate(label) }}>
            {error}
          </Trans>
        </FormHelperText>
      );
    }
  }

  /**
   * Handle check or uncheck checkbox
   * @param {object} option object data of checkbox handling
   */
  const _onChange = (option) => (event) => {
    let curValue = field.value
      ? Array.isArray(field.value)
        ? field.value
        : field.value.split(",")
      : [];
    curValue = [...new Set(curValue)];
    if (event.target.checked) curValue.push(option.value);
    else {
      const index = curValue.findIndex((e) => e === option.value);
      if (index !== -1) curValue.splice(index, 1);
    }
    helper.setValue(curValue, true);
  };

  return (
    <FormControl className={classes.formControl} {...rest} error={isError}>
      <FormLabel component="legend" style={{ marginBottom: 8 }}>
        {translate ? <Trans>{label}</Trans> : label}
      </FormLabel>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {data.map((e, i) => {
          return (
            <FormControlLabel
              key={`MultipleSelectField_item_${i}`}
              value={e.value}
              disabled={e.disabled}
              checked={field.value && field.value?.includes?.(e.value) && true}
              control={<Checkbox {...field} onChange={_onChange(e)} />}
              label={
                translate ? (
                  <Trans {...(e.transProps || {})}>{e.label}</Trans>
                ) : (
                  e.label
                )
              }
            />
          );
        })}
      </div>
      {_renderHelperText()}
    </FormControl>
  );
}
