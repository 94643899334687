import React from "react";
import at from "lodash/at";
import { useField } from "formik";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles } from "@mui/styles";
import { Trans } from "react-i18next";
import dataStorage from "dataStorage";
import i18n from "i18next";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const useStyles = makeStyles(() => ({
  paper: {
    height: 230,
    overflow: "hidden",
    "& ul": {
      maxHeight: "100%",
    },
  },
}));

function AutocompleteField(props) {
  const { label, data = [], helperText, translate = true, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const [touched, error] = at(meta, "touched", "error");
  const classes = useStyles();
  const { setValue: setFieldValue } = helper;
  const { value: selectedValue } = field;
  const timeoutId = React.useRef(0);
  const textSearch = React.useRef("");
  const [options, setOption] = React.useState(
    data.map((e) => ({
      ...e,
      label: dataStorage.translate(e.label),
    }))
  );

  /**
   * add listener for language change event
   */
  React.useEffect(() => {
    i18n.on("languageChanged", function () {
      setOption(
        data.map((e) => ({
          ...e,
          label: dataStorage.translate(e.label),
        }))
      );
    });
    return () => {
      timeoutId.current && clearTimeout(timeoutId.current);
    };
  }, []);

  /**
   * Render helper or error text
   * @returns helper text component translated
   */
  function _renderHelperText() {
    let text = "";
    if (touched && error) {
      text = error;
    } else if (helperText) {
      text = helperText;
    }
    return (
      <Trans values={{ label: dataStorage.translate(label) }}>{text}</Trans>
    );
  }

  /**
   * set option by user filter text
   * @returns void if dont have options
   */
  const autoSelectOptionByText = () => {
    if (!options || !options.length) return;
    const option = options.find(
      (e) => e.label.toLowerCase() === (textSearch.current + "").toLowerCase()
    );
    if (option) setFieldValue && setFieldValue(option.value, true);
  };

  /**
   * Handle user change filter text
   * @param {Event} e input change event
   */
  function onChangeText(e) {
    textSearch.current = e.target.value.trim() || "";
    timeoutId.current && clearTimeout(timeoutId.current);
    timeoutId.current = window.setTimeout(() => autoSelectOptionByText(), 500);
  }

  /**
   * Handle autocomplete option click
   * @param {Event} event select change event
   * @param {object} option selected option
   * @param {string} reason select action
   */
  function _onChange(event, option, reason) {
    if (reason === "selectOption") {
      timeoutId.current && clearTimeout(timeoutId.current);
      setFieldValue && setFieldValue(option.value, true);
    }
  }

  /**
   * custom filter function for material ui autocomplete
   * @param {Array} options list option of autocomplete
   * @param {string} inputValue is the current input value
   * @returns list new option by user filter text
   */
  const filterOptionsCustom = (options, { inputValue }) => {
    if (!inputValue) return options;
    return options.filter((e) =>
      e.label.toLowerCase().includes(inputValue.toLowerCase().trim())
    );
  };

  return (
    <Autocomplete
      {...field}
      {...rest}
      openText={dataStorage.translate("lang_open")}
      closeText={dataStorage.translate("lang_close")}
      noOptionsText={dataStorage.translate("lang_no_options")}
      onChange={_onChange}
      value={selectedValue || null}
      options={options}
      isOptionEqualToValue={(option, select) => {
        return select && option.value === select;
      }}
      classes={{ paper: classes.paper }}
      filterOptions={filterOptionsCustom}
      popupIcon={
        <ArrowDropDownIcon titleAccess={dataStorage.translate("lang_open")} />
      }
      getOptionLabel={(item) => {
        if (!item) return "";
        if (item.label) {
          return translate ? dataStorage.translate(item.label) : item.label;
        } else {
          const option = data.find((e) => e.value === item);
          return translate
            ? dataStorage.translate(option?.label || item)
            : option?.label || item;
        }
      }}
      id="disable-clearable"
      disableClearable
      renderInput={(params) => {
        return (
          <TextField
            onBlur={() => helper.setTouched(true, true)}
            name={"password"}
            {...params}
            inputProps={{
              ...params.inputProps,
              autoComplete: "off",
              form: {
                autocomplete: "off",
              },
            }}
            value={
              (selectedValue && selectedValue.label) || textSearch.current || ""
            }
            required={rest.required}
            variant="outlined"
            onChange={onChangeText}
            error={touched && error && true}
            helperText={_renderHelperText()}
            label={<Trans>{label}</Trans>}
            type="text"
          />
        );
      }}
    />
  );
}

export default AutocompleteField;
