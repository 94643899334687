/* eslint-disable max-lines */

import Link from "@mui/material/Link";

export const TITLE_OPTIONS = [
  { label: "Mr", value: "MR" },
  { label: "Mrs", value: "MRS" },
  { label: "Ms", value: "MS" },
  { label: "Miss", value: "MISS" },
  { label: "Dr", value: "DR" },
  { label: "Prof", value: "PROF" },
  { label: "Adml", value: "ADML" },
  { label: "Att", value: "ATT" },
  { label: "Brig", value: "BRIG" },
  { label: "Brn", value: "BRN" },
  { label: "Bshp", value: "BSHP" },
  { label: "Capt", value: "CAPT" },
  { label: "Cmdr", value: "CMDR" },
  { label: "Clr", value: "CLR" },
  { label: "Col", value: "COL" },
  { label: "Comm", value: "COMM" },
  { label: "Cpl", value: "CPL" },
  { label: "Dame", value: "DAME" },
  { label: "Est", value: "EST" },
  { label: "Flt", value: "FLT" },
  { label: "Fr", value: "FR" },
  { label: "Gcpt", value: "GCPT" },
  { label: "Hon", value: "HON" },
  { label: "Jdg", value: "JDG" },
  { label: "Lady", value: "LADY" },
  { label: "Lt", value: "LT" },
  { label: "Ltcl", value: "LTCL" },
  { label: "Maj", value: "MAJ" },
  { label: "Mdm", value: "MDM" },
  { label: "Msrs", value: "MSRS" },
  { label: "Mstr", value: "MSTR" },
  { label: "Pstr", value: "PSTR" },
  { label: "Rab", value: "RAB" },
  { label: "Rev", value: "REV" },
  { label: "Sen", value: "SEN" },
  { label: "Sgt", value: "SGT" },
  { label: "Sir", value: "SIR" },
  { label: "Sr", value: "SR" },
  { label: "Wcmd", value: "WCMD" },
  { label: "Other", value: "OTHER" },
];

export const BANK_LOCATION = {
  OVERSEAS: "USERS IN OVERSEAS",
  HONG_KONG: "USERS IN HONG KONG SAR",
};

export const ID_TYPE = {
  PASSPORT: "PASSPORT",
  HK_ID_CARD: "HK ID CARD",
};
export const ID_TYPE_OPTIONS = [
  { label: "lang_passport", value: ID_TYPE.PASSPORT },
  { label: "lang_hk_id_card", value: ID_TYPE.HK_ID_CARD },
];

export const GENDER_OPTIONS = [
  { label: "lang_male", value: "MALE" },
  { label: "lang_female", value: "FEMALE" },
];

export const EDUCATION = {
  PRIMARY_SCHOOL: "PRIMARY SCHOOL",
  MIDDLE_SCHOOL: "MIDDLE SCHOOL",
  UNIVERSITY: "UNIVERSITY",
  MASTER_OR_ABOVE: "MASTER OR ABOVE",
};
export const EDUCATION_OPTIONS = [
  { label: "lang_primary_school", value: EDUCATION.PRIMARY_SCHOOL },
  { label: "lang_middle_school", value: EDUCATION.MIDDLE_SCHOOL },
  { label: "lang_university", value: EDUCATION.UNIVERSITY },
  { label: "lang_master_or_above", value: EDUCATION.MASTER_OR_ABOVE },
];

export const EMPLOYMENT_STATUS = {
  EMPLOYED: "EMPLOYED",
  SELF_EMPLOYED: "SELF-EMPLOYED",
  RETIRED: "RETIRED",
  OTHER: "OTHER",
};
export const EMPLOYMENT_STATUS_OPTIONS = [
  { label: "lang_employed", value: EMPLOYMENT_STATUS.EMPLOYED },
  { label: "lang_self_employed", value: EMPLOYMENT_STATUS.SELF_EMPLOYED },
  { label: "lang_retired", value: EMPLOYMENT_STATUS.RETIRED },
  { label: "lang_other", value: EMPLOYMENT_STATUS.OTHER },
];

export const OCCUPATION_ROLE = {
  OFFICER: "OFFICER",
  MANAGER: "MANAGER",
  MIDDLE_MANAGEMENT: "MIDDLE MANAGEMENT",
  SENIOR_MANAGEMENT: "SENIOR MANAGEMENT",
  COMPANY_OWNER_SHAREHOLDER: "COMPANY OWNER/SHAREHOLDER",
  PROFESSIONAL: "PROFESSIONAL",
  SELF_EMPLOYED: "SELF-EMPLOYED",
  OTHER: "OTHER",
};
export const OCCUPATION_ROLE_OPTIONS = [
  { label: "lang_officer", value: OCCUPATION_ROLE.OFFICER },
  { label: "lang_manager", value: OCCUPATION_ROLE.MANAGER },
  { label: "lang_middle_management", value: OCCUPATION_ROLE.MIDDLE_MANAGEMENT },
  { label: "lang_senior_management", value: OCCUPATION_ROLE.SENIOR_MANAGEMENT },
  {
    label: "lang_company_owner_shareholder",
    value: OCCUPATION_ROLE.COMPANY_OWNER_SHAREHOLDER,
  },
  { label: "lang_professional", value: OCCUPATION_ROLE.PROFESSIONAL },
  { label: "lang_self_employed", value: OCCUPATION_ROLE.SELF_EMPLOYED },
  { label: "lang_other", value: OCCUPATION_ROLE.OTHER },
];

export const OCCUPATION_INDUSTRY = {
  INTERNET: "Internet",
  FINANCIAL: "Financial",
  SOFTWARE_AND_INFORMATION: "Software and Information",
  MANUFACTURING: "Manufacturing",
  PROPERTY_AND_REAL_ESTATE: "Property and Real Estate",
  ENERGY_UTITLITIES_TELECOM: "Energy/Utitlities/Telecom",
  ARCHITECTURE_SURVEYING_ENGINEERING: "Architecture/Surveying/Engineering",
  TRADE_AND_RETAIL: "Trade and Retail",
  MEDIA_AND_COMMUNICATIONS: "Media and Communications",
  TRANSPORTATION_LOGISTIC: "Transportation/Logistic",
  FOOD_AND_BEVERAGE: "Food and Beverage",
  AGRICULTURE_FORESTRY_PASTURING_FISHERY:
    "Agriculture/Forestry/Pasturing/Fishery",
  SOCIAL_SERVICE_NGO: "Social Service/NGO",
  MEDICAL_AND_HEALTHCARE: "Medical and Health",
  EDUCATION_TRAINING_RESEARCH: "Education/Training/Research",
  OTHERS: "Others",
};
export const OCCUPATION_INDUSTRY_OPTIONS = [
  {
    label: "lang_internet_industry",
    value: OCCUPATION_INDUSTRY.INTERNET,
  },
  {
    label: "lang_financial_industry",
    value: OCCUPATION_INDUSTRY.FINANCIAL,
  },
  {
    label: "lang_software_and_information",
    value: OCCUPATION_INDUSTRY.SOFTWARE_AND_INFORMATION,
  },
  {
    label: "lang_manufacturing",
    value: OCCUPATION_INDUSTRY.MANUFACTURING,
  },
  {
    label: "lang_property_and_real_estate",
    value: OCCUPATION_INDUSTRY.PROPERTY_AND_REAL_ESTATE,
  },
  {
    label: "lang_energy_utitlities_telecom",
    value: OCCUPATION_INDUSTRY.ENERGY_UTITLITIES_TELECOM,
  },
  {
    label: "lang_architecture_surveying_engineering",
    value: OCCUPATION_INDUSTRY.ARCHITECTURE_SURVEYING_ENGINEERING,
  },
  {
    label: "lang_trade_and_retail",
    value: OCCUPATION_INDUSTRY.TRADE_AND_RETAIL,
  },
  {
    label: "lang_media_and_communications",
    value: OCCUPATION_INDUSTRY.MEDIA_AND_COMMUNICATIONS,
  },
  {
    label: "lang_transportation_logistic",
    value: OCCUPATION_INDUSTRY.TRANSPORTATION_LOGISTIC,
  },
  {
    label: "lang_food_and_beverage",
    value: OCCUPATION_INDUSTRY.FOOD_AND_BEVERAGE,
  },
  {
    label: "lang_agriculture_forestry_pasturing_fishery",
    value: OCCUPATION_INDUSTRY.AGRICULTURE_FORESTRY_PASTURING_FISHERY,
  },
  {
    label: "lang_social_service_ngo",
    value: OCCUPATION_INDUSTRY.SOCIAL_SERVICE_NGO,
  },
  {
    label: "lang_medical_and_healthcare",
    value: OCCUPATION_INDUSTRY.MEDICAL_AND_HEALTHCARE,
  },
  {
    label: "lang_education_training_research",
    value: OCCUPATION_INDUSTRY.EDUCATION_TRAINING_RESEARCH,
  },
  {
    label: "lang_others",
    value: OCCUPATION_INDUSTRY.OTHERS,
  },
];

export const DURATION = {
  NO: "NO",
  LESS_THAN_ONE_YEAR: "LESS THAN 1 YEAR",
  ONE_TO_THREE_YEAR: "1 TO 3 YEARS",
  ONE_TO_FIVE_YEAR: "1 TO 5 YEARS",
  THREE_TO_SIX_YEAR: "3 TO 6 YEARS",
  SIX_TO_TEN_YEAR: "6 TO 10 YEARS",
  ONE_YEARS: "1 YEAR",
  TWO_YEARS: "2 YEARS",
  THREE_YEARS: "3 YEARS",
  FOUR_YEARS: "4 YEARS",
  FIVE_YEARS: "5 YEARS",
  SIX_YEARS: "6 YEARS",
  SEVEN_YEARS: "7 YEARS",
  EIGHT_YEARS: "8 YEARS",
  NINE_YEARS: "9 YEARS",
  MORE_THAN_TEN_YEAR: "10+ YEARS",
};
export const WORKING_DURATION_OPTIONS = [
  { label: "lang_less_than_one_year", value: DURATION.LESS_THAN_ONE_YEAR },
  { label: "lang_one_year", value: DURATION.ONE_YEARS },
  { label: "lang_two_year", value: DURATION.TWO_YEARS },
  { label: "lang_three_year", value: DURATION.THREE_YEARS },
  { label: "lang_four_year", value: DURATION.FOUR_YEARS },
  { label: "lang_five_year", value: DURATION.FIVE_YEARS },
  { label: "lang_six_year", value: DURATION.SIX_YEARS },
  { label: "lang_seven_year", value: DURATION.SEVEN_YEARS },
  { label: "lang_eight_year", value: DURATION.EIGHT_YEARS },
  { label: "lang_nine_year", value: DURATION.NINE_YEARS },
  { label: "lang_more_than_ten_year", value: DURATION.MORE_THAN_TEN_YEAR },
];

export const AMOUNT_VALUE = {
  LESS_THAN_25K_HKD: "LESS THAN 25K HKD",
  "25K_500K_HKD": "25K-500K HKD",
  LESS_THAN_200K_HKD: "LESS THAN 200K HKD",
  "200K_500K_HKD": "200K-500K HKD",
  "500K_1M_HKD": "500K-1M HKD",
  "1M_5M_HKD": "1M-5M HKD",
  ABOVE_5M_HKD: "ABOVE 5M HKD",
  "5M_10M_HKD": "5M-10M HKD",
  "10M_30M_HKD": "10M-30M HKD",
  "30M_50M_HKD": "30M-50M HKD",
  ABOVE_50M_HKD: "ABOVE 50M HKD",
};

export const ANNUAL_INCOME_OPTIONS = [
  {
    label: "lang_less_than_200k_hkd",
    value: AMOUNT_VALUE.LESS_THAN_200K_HKD,
  },
  {
    label: "lang_200k_to_500k_hkd",
    value: AMOUNT_VALUE["200K_500K_HKD"],
  },
  {
    label: "lang_500k_to_1m_hkd",
    value: AMOUNT_VALUE["500K_1M_HKD"],
  },
  {
    label: "lang_1m_to_5m_hkd",
    value: AMOUNT_VALUE["1M_5M_HKD"],
  },
  {
    label: "lang_above_5m_hkd",
    value: AMOUNT_VALUE.ABOVE_5M_HKD,
  },
];

export const NET_ASSETS_VALUE_OPTIONS = [
  {
    label: "lang_less_than_25k_hkd",
    value: AMOUNT_VALUE.LESS_THAN_25K_HKD,
  },
  {
    label: "lang_25k_to_500k_hkd",
    value: AMOUNT_VALUE["25K_500K_HKD"],
  },
  {
    label: "lang_500k_to_1m_hkd",
    value: AMOUNT_VALUE["500K_1M_HKD"],
  },
  {
    label: "lang_1m_to_5m_hkd",
    value: AMOUNT_VALUE["1M_5M_HKD"],
  },
  {
    label: "lang_5m_to_10m_hkd",
    value: AMOUNT_VALUE["5M_10M_HKD"],
  },
  {
    label: "lang_10m_to_30m_hkd",
    value: AMOUNT_VALUE["10M_30M_HKD"],
  },
  {
    label: "lang_30m_to_50m_hkd",
    value: AMOUNT_VALUE["30M_50M_HKD"],
  },
  {
    label: "lang_above_50m_hkd",
    value: AMOUNT_VALUE.ABOVE_50M_HKD,
  },
];

export const RESIDENTIAL_STATUS = {
  OWNED: "OWNED",
  LIVING_WITH_PARENTS: "LIVING WITH PARENTS",
  RENTED: "RENTED",
  MORTGAGED: "MORTGAGED",
  QUARTERS: "QUARTERS",
  OTHER: "OTHER",
};
export const RESIDENTIAL_STATUS_OPTIONS = [
  { label: "lang_owned", value: RESIDENTIAL_STATUS.OWNED },
  {
    label: "lang_living_with_parents",
    value: RESIDENTIAL_STATUS.LIVING_WITH_PARENTS,
  },
  { label: "lang_rented", value: RESIDENTIAL_STATUS.RENTED },
  { label: "lang_mortgaged", value: RESIDENTIAL_STATUS.MORTGAGED },
  { label: "lang_quarters", value: RESIDENTIAL_STATUS.QUARTERS },
  { label: "lang_other", value: RESIDENTIAL_STATUS.OTHER },
];

export const SOURCE_OF_FUND = {
  SALARY: "SALARY",
  SAVINGS: "SAVINGS",
  RETURN_ON_INVESTMENT: "RETURN ON INVESTMENT",
  BUSINESS_REVENUE: "BUSINESS REVENUE",
  LOAN: "LOAN",
  RETIREMENT_FUND: "PENSION",
  RENTAL_INCOME: "RENTAL INCOME",
  OTHER: "OTHER",
};
export const SOURCE_OF_FUND_OPTIONS = [
  { label: "lang_salary", value: SOURCE_OF_FUND.SALARY },
  { label: "lang_savings", value: SOURCE_OF_FUND.SAVINGS },
  {
    label: "lang_return_on_investment",
    value: SOURCE_OF_FUND.RETURN_ON_INVESTMENT,
  },
  { label: "lang_business_revenue", value: SOURCE_OF_FUND.BUSINESS_REVENUE },
  { label: "lang_loan", value: SOURCE_OF_FUND.LOAN },
  { label: "lang_retirement_fund", value: SOURCE_OF_FUND.RETIREMENT_FUND },
  { label: "lang_rental_income", value: SOURCE_OF_FUND.RENTAL_INCOME },
  { label: "lang_other", value: SOURCE_OF_FUND.OTHER },
];

export const INVESTMENT_EXP_OPTIONS = [
  { label: "lang_no", value: DURATION.NO },
  { label: "lang_less_than_one_year", value: DURATION.LESS_THAN_ONE_YEAR },
  { label: "lang_1_to_5_year", value: DURATION.ONE_TO_FIVE_YEAR },
  { label: "lang_6_to_10_year", value: DURATION.SIX_TO_TEN_YEAR },
  { label: "lang_more_than_ten_year", value: DURATION.MORE_THAN_TEN_YEAR },
];

export const INVESTMENT_OBJECTIVES = {
  DIVIDEND_INCOME: "DIVIDEND INCOME",
  HEDGING: "HEDGING",
  GROWTH: "GROWTH",
  SPECULATION: "SPECULATION",
  OTHER: "OTHER",
};
export const INVESTMENT_OBJECTIVES_OPTIONS = [
  {
    label: "lang_dividend_income",
    value: INVESTMENT_OBJECTIVES.DIVIDEND_INCOME,
  },
  { label: "lang_hedging", value: INVESTMENT_OBJECTIVES.HEDGING },
  { label: "lang_growth", value: INVESTMENT_OBJECTIVES.GROWTH },
  { label: "lang_speculation", value: INVESTMENT_OBJECTIVES.SPECULATION },
  { label: "lang_other", value: INVESTMENT_OBJECTIVES.OTHER },
];

export const ESTIMATED_INVESTMENT_TIME = {
  SHORT_TERM: "SHORT-TERM",
  MID_TERM: "MID-TERM",
  LONG_TERM: "LONG-TERM",
};

export const ESTIMATED_INVESTMENT_TIME_OPTIONS = [
  { label: "lang_short_term", value: ESTIMATED_INVESTMENT_TIME.SHORT_TERM },
  { label: "lang_middle_term", value: ESTIMATED_INVESTMENT_TIME.MID_TERM },
  { label: "lang_long_term", value: ESTIMATED_INVESTMENT_TIME.LONG_TERM },
];

export const RISK_TOLERANCE = {
  VERY_LOW: "VERY LOW",
  LOW: "LOW",
  MEDIUM: "MEDIUM",
  HIGH: "HIGH",
  VERY_HIGH: "VERY HIGH",
};
export const RISK_TOLERANCE_OPTIONS = [
  { label: "lang_very_low", value: RISK_TOLERANCE.VERY_LOW },
  { label: "lang_low", value: RISK_TOLERANCE.LOW },
  { label: "lang_medium", value: RISK_TOLERANCE.MEDIUM },
  { label: "lang_high", value: RISK_TOLERANCE.HIGH },
  { label: "lang_very_high", value: RISK_TOLERANCE.VERY_HIGH },
];

export const TAX_ID_REASON = {
  REASON_A: "ReasonA",
  REASON_B: "ReasonB",
  REASON_C: "ReasonC",
};
export const TAX_ID_REASON_OPTIONS = [
  { label: "lang_reason_a", value: TAX_ID_REASON.REASON_A },
  { label: "lang_reason_b", value: TAX_ID_REASON.REASON_B },
  { label: "lang_reason_c", value: TAX_ID_REASON.REASON_C },
];

export const BOOLEAN_OPTIONS = [
  { label: "lang_yes", value: true },
  { label: "lang_no", value: false },
];

export const ACCOUNT_TYPE = {
  GLOBAL_MARGIN_ACCOUNT: "GLOBAL MARGIN ACCOUNT",
  CHINA_SHARES: "CHINA A-SHARES",
};
export const ACCOUNT_TYPE_OPTIONS = [
  {
    label: "lang_global_margin_account",
    value: ACCOUNT_TYPE.GLOBAL_MARGIN_ACCOUNT,
    disabled: true,
  },
  { label: "lang_china_shares_account", value: ACCOUNT_TYPE.CHINA_SHARES },
];

export const DERIVATIVES_AWARENESS_ASSESSMENT_OPTIONS = [
  {
    label: "lang_derivatives_awareness_assessment_option_1",
    value: "have_received_training",
  },
  {
    label: "lang_derivatives_awareness_assessment_option_2",
    value: "have_working_experience",
  },
  {
    label: "lang_derivatives_awareness_assessment_option_3",
    value: "have_derivative_transactions",
  },
  {
    label: "lang_derivatives_awareness_assessment_option_4",
    transProps: {
      components: [
        <Link
          href="https://www.magpiesecurities.com/pdf/risk-disclosure-statement.pdf"
          target="_blank"
        />,
      ],
    },
    value: "no_experience",
  },
];

export const risk_disclosure_OPTIONS = [
  {
    label: "lang_risk_disclosure_option_1",
    transProps: {
      components: [
        <Link
          href="https://www.magpiesecurities.com/pdf/Personal-Information-Collection-Statement.pdf"
          target="_blank"
        />,
      ],
    },
    value: 1,
  },
  {
    label: "lang_risk_disclosure_option_2",
    value: 2,
  },
  {
    label: "lang_risk_disclosure_option_3",
    transProps: {
      components: [
        <Link
          href="https://www.magpiesecurities.com/pdf/CA-v9.pdf"
          target="_blank"
        />,
        <Link
          href="https://www.magpiesecurities.com/pdf/standing-authority-on-margin-client-securities-and-securities-collateral.pdf"
          target="_blank"
        />,
      ],
    },
    value: 3,
  },
  {
    label: "lang_risk_disclosure_option_4",
    transProps: {
      components: [
        <Link
          href="https://www.magpiesecurities.com/pdf/W-8BEN.pdf"
          target="_blank"
        />,
        <Link
          href="https://www.magpiesecurities.com/pdf/W-9.pdf"
          target="_blank"
        />,
      ],
    },
    value: 4,
  },
  {
    label: "lang_risk_disclosure_option_5",
    value: 5,
  },
  {
    label: "lang_risk_disclosure_option_6",
    transProps: {
      components: [
        <Link
          href="https://www.magpiesecurities.com/pdf/risk-disclosure-statement.pdf"
          target="_blank"
        />,
      ],
    },
    value: 6,
  },
  {
    label: "lang_risk_disclosure_option_7",
    transProps: {
      components: [
        <Link
          href="https://www.magpiesecurities.com/pdf/Standing-Authorization-Letter-(For-Margin-Client-only).pdf"
          target="_blank"
        />,
        <Link
          href="https://www.magpiesecurities.com/pdf/Standing-Authority-Client-Money.pdf"
          target="_blank"
        />,
      ],
    },
    value: 7,
  },
  {
    label: "lang_risk_disclosure_option_8",
    transProps: {
      components: [
        <Link
          href="https://www.magpiesecurities.com/pdf/Personal%20Information%20Collection%20Statement%20of%20%5bStock%20Connect%20Northbound%20Trading%5d%20(for%20China%20Connect%20client).pdf"
          target="_blank"
        />,
      ],
    },
    value: 8,
  },
  {
    label: "lang_risk_disclosure_option_9",
    transProps: {
      components: [
        <Link
          href="https://www.magpiesecurities.com/pdf/W-8BEN.pdf"
          target="_blank"
        />,
      ],
    },
    value: 9,
  },
  {
    label: "lang_risk_disclosure_option_10",
    transProps: {
      components: [
        <Link
          href="https://www.magpiesecurities.com/pdf/Personal-Information-Collection-Statement-concerning-HKIDR-and-OTCR.pdf"
          target="_blank"
        />,
      ],
    },
    value: 10,
  },
];
export const risk_disclosure_OPTION_9 = {
  label: "lang_risk_disclosure_option_9",
  transProps: {
    components: [
      <Link
        href="https://www.magpiesecurities.com/pdf/W-8BEN.pdf"
        target="_blank"
      />,
    ],
  },
  value: 9,
};
export const risk_disclosure_OPTION_9_US = {
  label: "lang_risk_disclosure_option_9_us",
  transProps: {
    components: [
      <Link
        href="https://www.magpiesecurities.com/pdf/W-9.pdf"
        target="_blank"
      />,
    ],
  },
  value: 9,
};
