import React from "react";
import at from "lodash/at";
import { useField } from "formik";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import { Trans } from "react-i18next";
import dataStorage from "dataStorage";

function SelectField(props) {
  const {
    onchangeExtra,
    label,
    data,
    helperText,
    variant = "outlined",
    translate = true,
    multiple,
    ...rest
  } = props;
  const [field, meta, helper] = useField(props);
  const { setValue: setValueForm } = helper || {};
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, "touched", "error");
  const isError = touched && error && true;
  const openRef = React.useRef(false);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    openRef.current = open;
  }, [open]);

  /**
   * when list option expand and user scroll => remove list expand
   */
  React.useEffect(() => {
    let timeoutid = null;
    const handleScroll = () => {
      timeoutid && clearTimeout(timeoutid);
      timeoutid = setTimeout(() => {
        openRef.current && setOpen(false);
      }, 100);
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
      clearTimeout(timeoutid);
    };
  }, []);

  /**
   * Render helper text or error text
   * @returns translated text
   */
  function _renderHelperText() {
    if (isError) {
      return (
        <FormHelperText>
          <Trans values={{ label: dataStorage.translate(label) }}>
            {error}
          </Trans>
        </FormHelperText>
      );
    } else if (helperText) {
      return <FormHelperText>{helperText}</FormHelperText>;
    }
  }

  /**
   * Update new value for select field
   * @param {Event} event select change event
   */
  const _onChange = (event) => {
    setValueForm?.(event.target.value);
    onchangeExtra && onchangeExtra(event.target.value);
  };

  const valueSelected = multiple
    ? selectedValue
      ? typeof selectedValue === "string"
        ? selectedValue.split(",")
        : selectedValue
      : []
    : selectedValue || "";
  return (
    <FormControl variant={variant} {...rest} error={isError}>
      <InputLabel>
        <Trans>{label}</Trans>
      </InputLabel>
      <Select
        value={valueSelected}
        multiple={!!multiple}
        onChange={_onChange}
        onBlur={field.onBlur}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        name={field.name}
        label={<Trans>{label}</Trans>}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 228,
            },
          },
          disableScrollLock: true,
        }}
      >
        {data.map((item, index) => {
          return (
            <MenuItem key={index} value={item.value}>
              {translate ? <Trans>{item.label}</Trans> : item.label}
            </MenuItem>
          );
        })}
      </Select>
      {_renderHelperText()}
    </FormControl>
  );
}

export default SelectField;
