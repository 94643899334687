import React from "react";
import {
  AutocompleteField,
  SelectField,
  FileUpload,
} from "components/CustomFields";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import formModel from "containers/Onboarding/FormModels/Models";
import ListCountry from "constants/Countries/countries";
import ListNationality from "constants/Countries/nationalities";
import { ID_TYPE_OPTIONS } from "constants/Options/options";
import { Trans } from "react-i18next";
import { useFormikContext } from "formik";
import { useImage } from "hooks/useKYC";
import dataStorage from "dataStorage";
import { clone } from "helpers/utils";

const { formField } = formModel;

const countriesOverSea = clone(ListNationality);
let countriesHK = clone(ListNationality);
countriesHK.unshift({
  code: "HKG",
  value: "HKG",
  label: "Hong Kong, SAR, China",
  phone: "852",
});

const UploadIdPhoto = ({ setCheckDocument, setLoading }) => {
  const { values, setFieldValue, setSubmitting } = useFormikContext();
  const fileRef = React.useRef();
  const nationality = React.useRef(
    dataStorage.isOverSeas ? countriesOverSea : countriesHK
  );
  const { isLoading, mutate: uploadImage } = useImage();

  React.useEffect(() => {
    if (
      values.applicant_verification_id.country_of_residence &&
      fileRef.current
    ) {
      postPhoto();
    }
    setFieldValue(
      formField.personal_information.country_of_residence.name,
      values.applicant_verification_id.country_of_residence,
      true
    );
  }, [values.applicant_verification_id.country_of_residence]);

  /**
   * Post photo to server
   * @returns void if is loading or file not exist
   */
  const postPhoto = () => {
    if (!fileRef.current || isLoading) return;
    const { name: fileName } = fileRef.current;
    const extension = (fileName + "").match(/([^.]*)$/)?.[0]?.toLowerCase();
    setLoading(true);
    uploadImage(
      {
        key: "passPort",
        file: fileRef.current,
        documentType: extension,
        applicant_country: values.applicant_country,
        country_of_residence:
          values.applicant_verification_id.country_of_residence,
      },
      {
        onSuccess: (data) => {
          setLoading(false);
          setCheckDocument(true);
          dataStorage.livenessProgress = true;
          setFieldValue(
            formField.applicant_verification_id.pass_port.name,
            data,
            false
          );
          // values.applicant_verification_id.pass_port = data
        },
        onError: () => {
          setLoading(false);
          console.error("Cannot post image to server");
          dataStorage.showAlert("lang_upload_passport_photo_failed");
        },
      }
    );
  };

  /**
   * Handle change passport photo
   * @param {File} file new file
   * @returns void if file not exist
   */
  const onChangePassport = (file) => {
    if (!file) {
      fileRef.current = "";
      setSubmitting(false);
      setCheckDocument(false);
      return;
    }
    fileRef.current = file;
    if (values.applicant_verification_id.country_of_residence) {
      postPhoto();
    }
  };

  /**
   * Handle change front side photo
   * @param {File} file new file
   * @returns void if file not exist
   */
  const onChangeFrontSide = (file) => {
    if (!file) return;
    const { name: fileName } = file;
    const extension = (fileName + "").match(/([^.]*)$/)?.[0]?.toLowerCase();
    setLoading(true);
    uploadImage(
      {
        key: "frontSide",
        file,
        documentType: extension,
      },
      {
        onSuccess: (data) => {
          setLoading(false);
          setFieldValue(
            formField.applicant_verification_id.front_side.name,
            data,
            false
          );
        },
        onError: () => {
          setLoading(false);
          console.error("Cannot post image to server");
          dataStorage.showAlert("lang_upload_front_side_photo_failed");
        },
      }
    );
  };

  /**
   * Handle change back side photo
   * @param {File} file new file
   * @returns void if file not exist
   */
  const onChangeBackSide = (file) => {
    if (!file) return;
    const { name: fileName } = file;
    const extension = (fileName + "").match(/([^.]*)$/)?.[0]?.toLowerCase();
    setLoading(true);
    uploadImage(
      {
        key: "backSide",
        file,
        documentType: extension,
      },
      {
        onSuccess: (data) => {
          setLoading(false);
          setFieldValue(
            formField.applicant_verification_id.back_side.name,
            data,
            false
          );
        },
        onError: () => {
          setLoading(false);
          console.error("Cannot post image to server");
          dataStorage.showAlert("lang_upload_back_side_photo_failed");
        },
      }
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="body2">
          <Trans>lang_upload_id_photo_tip</Trans>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <AutocompleteField
          required
          name={formField.applicant_verification_id.applicant_nationality.name}
          label={
            formField.applicant_verification_id.applicant_nationality.label
          }
          data={nationality.current}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <SelectField
          required
          disabled
          name={formField.applicant_verification_id.id_type.name}
          label={formField.applicant_verification_id.id_type.label}
          data={ID_TYPE_OPTIONS}
          fullWidth
        />
      </Grid>
      {dataStorage.isOverSeas ? (
        <Grid item xs={12}>
          <AutocompleteField
            required
            name={formField.applicant_verification_id.country_of_residence.name}
            label={
              formField.applicant_verification_id.country_of_residence.label
            }
            data={ListCountry}
            fullWidth
          />
        </Grid>
      ) : (
        <React.Fragment />
      )}
      {dataStorage.isOverSeas ? (
        <Grid item xs={12} sm={6}>
          <FileUpload
            required
            onChange={onChangePassport}
            name={formField.applicant_verification_id.pass_port.name}
            label={formField.applicant_verification_id.pass_port.label}
          />
        </Grid>
      ) : (
        <React.Fragment>
          <Grid item xs={12}>
            <FileUpload
              required
              onChange={onChangeFrontSide}
              name={formField.applicant_verification_id.front_side.name}
              label={formField.applicant_verification_id.front_side.label}
            />
          </Grid>
          <Grid item xs={12}>
            <FileUpload
              required
              onChange={onChangeBackSide}
              name={formField.applicant_verification_id.back_side.name}
              label={formField.applicant_verification_id.back_side.label}
            />
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
};

export default UploadIdPhoto;
