import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Trans } from "react-i18next";
import ReactCodeInput from "react-code-input";
import FormHelperText from "@mui/material/FormHelperText";
import ResendButton from "./ResendButton";

const RenderOTPForm = (props) => {
  const {
    phoneData,
    onChangeOTP,
    pinRef,
    error,
    isLoadingLogin,
    OTP_LENGTH,
    theme,
    clearPin,
    onResendOTP,
  } = props;
  return (
    <React.Fragment>
      <Grid item xs={12} sm={12} textAlign="center">
        <Typography variant="h5">
          <Trans>lang_enter_otp_code</Trans>
        </Typography>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", mt: 2 }}
        >{`+${phoneData.current?.phone} ${phoneData.current?.phoneNumber}`}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} textAlign="center">
        <ReactCodeInput
          id="pinCode"
          type="number"
          name={"phone_otp"}
          className="ReactCodeInput"
          isValid={!error}
          fields={OTP_LENGTH}
          pattern="[0-9]*"
          inputmode="numeric"
          onChange={onChangeOTP}
          ref={pinRef}
          inputStyle={{
            outline: "none",
            margin: 4,
            width: "calc((100% - 48px) / 6)",
            maxWidth: 48,
            height: 48,
            borderRadius: 4,
            fontSize: 32,
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            border: `1px solid ${theme.palette.grey[500]}`,
            textAlign: "center",
          }}
          inputStyleInvalid={{
            outline: "none",
            margin: 4,
            width: "calc((100% - 48px) / 6)",
            maxWidth: 48,
            height: 48,
            borderRadius: 4,
            fontSize: 32,
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            border: `1px solid ${theme.palette.error.main}`,
            textAlign: "center",
          }}
        />
      </Grid>
      {error ? (
        <Grid item xs={12} sm={12} textAlign="center">
          <FormHelperText error style={{ textAlign: "center" }}>
            <Trans>lang_wrong_otp</Trans>
          </FormHelperText>
        </Grid>
      ) : (
        <React.Fragment />
      )}
      <Grid item xs={12} sm={12} textAlign="center">
        <ResendButton
          isLoading={isLoadingLogin}
          onClick={(e) => {
            e.stopPropagation();
            clearPin();
            onResendOTP();
          }}
        />
      </Grid>
    </React.Fragment>
  );
};
export default RenderOTPForm;
