/* eslint-disable max-lines */
// keep all handle in main file

import React, { useState } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { Formik, Form } from "formik";
import Steps from "components/Steps";
import validationSchema from "containers/Onboarding/FormModels/Validation";
import formModel from "containers/Onboarding/FormModels/Models";
import initialValues from "containers/Onboarding/FormModels/InitialValue";
import useStyles from "containers/Onboarding/styles";
import dataStorage from "dataStorage";
import {
  clone,
  scrollToTop,
  handleDataBeforeSubmit,
  checkBtnDisable,
} from "helpers/utils";
import FocusError from "components/FocusError";
import VerifyPhoneNumber from "components/VerifyPhoneNumber";
import RefferredCode from "components/RefferredCode";
import UploadIdPhoto from "containers/Onboarding/Screens/UploadIdPhoto";
import EKYC from "containers/Onboarding/Screens/EKYC";
import PersonalInformation from "containers/Onboarding/Screens/PersonalInformation";
import EmploymentInformation from "containers/Onboarding/Screens/EmploymentInformation";
import FinancialAndInvestment from "containers/Onboarding/Screens/FinancialAndInvestment";
import MakeADeposit from "containers/Onboarding/Screens/MakeADeposit";
import Esignature from "containers/Onboarding/Screens/Esignature";
import AccountType from "containers/Onboarding/Screens/AccountType";
import RiskDisclosureAndDeclarationSignature from "containers/Onboarding/Screens/RiskDisclosureAndDeclarationSignature";
import OtherIdentityDisclosuresAndTaxInformation from "containers/Onboarding/Screens/OtherIdentityDisclosuresAndTaxInformation";
import ListStep from "containers/Onboarding/FormModels/ListStep";
import { useSaveDraft } from "hooks/useDraft";
import { useTransaction } from "hooks/useKYC";
import { useSubmitAccount } from "hooks/useAccount";
import { Trans } from "react-i18next";
import moment from "moment";
import { isEmpty } from "lodash";

const { formId } = formModel;
const listStepOverSea = clone(ListStep);
let listStepHK = clone(ListStep);
listStepHK.splice(1, 1);

export default function Onboarding(props) {
  const { data: draft, onBack } = props;
  const initialStep = React.useRef(props.step || 0);
  const classes = useStyles();
  const listStep = React.useRef(
    dataStorage.isOverSeas ? listStepOverSea : listStepHK
  );
  const [activeStep, setActiveStep] = useState(initialStep.current);
  const [isLoading, setLoading] = useState(false);
  const listStepCanPress = React.useRef({
    0: false,
    1: false,
    2: initialStep.current > 1,
  });
  const initialData = React.useRef({
    ...clone(initialValues),
    ...draft,
  });
  const submitStep = listStep.current.length - 2;
  const lastStep = listStep.current.length - 1;
  const currentValidationSchema =
    validationSchema[listStep.current[activeStep]];
  const refNext = React.useRef();
  const kycUrl = React.useRef(props.kycUrl || "");
  const kycResult = React.useRef();
  const formValues = React.useRef();
  const formActions = React.useRef();
  const refKyc = React.useRef();
  const livenessEnable = React.useRef(true);
  const { mutate: callSaveDraft } = useSaveDraft();
  const [allow, setAllow] = React.useState(!(initialStep.current > 1));
  const { data: transaction } = useTransaction({
    enabled: allow && dataStorage.isOverSeas,
    refetchInterval: allow && dataStorage.isOverSeas ? 3000 : false,
  });
  const { mutate: callSubmit } = useSubmitAccount();

  /**
   * Handle when transaction change, check kyc error, show warning
   */
  React.useEffect(() => {
    if (initialStep.current > 1 || !dataStorage.isOverSeas) return;
    let result = {};
    if (transaction?.kyc_results) {
      result = JSON.parse(transaction.kyc_results);
      result = result.reduce((acc, cur) => {
        acc[cur.category] = cur;
        return acc;
      }, {});
      const photoMatchFailedCount =
        result?.PHOTO_MATCH?.data?.failed_count || 0;
      const livenessFailedCount = result?.LIVENESS?.data?.failed_count || 0;
      kycResult.current = result;
      if (
        result?.PHOTO_MATCH?.decision +
          result?.DOCUMENT?.decision +
          result?.LIVENESS?.decision ===
        "PASSEDPASSEDPASSED"
      ) {
        const passportInfo = result?.DOCUMENT?.data;
        formValues.current.personal_information.eng_given_name =
          passportInfo.lastName;
        formValues.current.personal_information.eng_sur_name =
          passportInfo.firstName;
        formValues.current.personal_information.dob = passportInfo.dateOfBirth;
        formValues.current.personal_information.id_number =
          passportInfo.documentNumber;
        dataStorage.livenessProgress = false;
        setAllow(false);
        setTimeout(() => {
          refNext.current && refNext.current.click();
        }, 500);
      } else if (photoMatchFailedCount >= 5 || livenessFailedCount >= 5) {
        dataStorage.livenessProgress = false;
        livenessEnable.current = false;
        formActions?.current?.setSubmitting?.(false);
        setAllow(false);
        dataStorage.showModal({
          title: "lang_notify",
          content: "kyc_details_no_match_over_5_times",
          callback: () => window.location.reload(),
        });
      } else {
        let errorLabel = "";
        if (
          result?.LIVENESS?.details?.label &&
          result?.LIVENESS?.details?.label !== "OK"
        )
          errorLabel = `kyc_details_${result.LIVENESS.details.label.toLowerCase()}`;
        if (
          result?.PHOTO_MATCH?.details?.label &&
          result?.PHOTO_MATCH?.details?.label !== "OK"
        )
          errorLabel = `kyc_details_${result.PHOTO_MATCH.details.label.toLowerCase()}`;
        if (
          result?.DOCUMENT?.details?.label &&
          result?.DOCUMENT?.details?.label !== "OK"
        )
          errorLabel = `kyc_details_${result.DOCUMENT.details.label.toLowerCase()}`;
        if (errorLabel && dataStorage.livenessProgress) {
          formActions?.current?.setSubmitting?.(false);
          setAllow(false);
          dataStorage.livenessProgress = false;
          dataStorage.showModal({
            title: "lang_notify",
            content: errorLabel,
            confirmText: "lang_try_again",
            callback: () => {
              dataStorage.livenessProgress = true;
              if (activeStep === 1) {
                setAllow(true);
                refKyc.current?.recheckLiveness?.();
              }
            },
          });
        }
      }
    }
    if (transaction?.workflow6_web_url) {
      if (kycUrl.current === "waiting") {
        formActions?.current?.setSubmitting?.(false);
        setTimeout(() => {
          kycUrl.current = transaction.workflow6_web_url;
          refNext.current && refNext.current.click();
        }, 300);
      } else {
        kycUrl.current = transaction.workflow6_web_url;
      }
    }
  }, [transaction]);

  /**
   * Only continue call transaction api when have document, esle not allow call transaction interval
   * @param {boolean} value next state of allow state
   */
  const setCheckDocument = (value) => {
    setAllow(!!value);
  };

  /**
   * Render step UI by flow HK or oversea
   * @returns HTML UI by step
   */
  function _renderStepContent() {
    if (dataStorage.isOverSeas) {
      switch (activeStep) {
        case 0:
          return (
            <UploadIdPhoto
              setLoading={setLoading}
              setCheckDocument={setCheckDocument}
            />
          );
        case 1:
          return <EKYC ref={refKyc} src={kycUrl.current} />;
        case 2:
          return <PersonalInformation setLoading={setLoading} />;
        case 3:
          return <EmploymentInformation />;
        case 4:
          return <FinancialAndInvestment />;
        case 5:
          return <OtherIdentityDisclosuresAndTaxInformation />;
        case 6:
          return <AccountType />;
        case 7:
          return <RiskDisclosureAndDeclarationSignature />;
        case 8:
          return <Esignature setLoading={setLoading} />;
        case 9:
          return <MakeADeposit />;
        default:
          return <div>Not Found</div>;
      }
    } else {
      switch (activeStep) {
        case 0:
          return (
            <UploadIdPhoto
              setCheckDocument={setCheckDocument}
              setLoading={setLoading}
            />
          );
        case 1:
          return <PersonalInformation setLoading={setLoading} />;
        case 2:
          return <EmploymentInformation />;
        case 3:
          return <FinancialAndInvestment />;
        case 4:
          return <OtherIdentityDisclosuresAndTaxInformation />;
        case 5:
          return <AccountType />;
        case 6:
          return <RiskDisclosureAndDeclarationSignature />;
        case 7:
          return <Esignature setLoading={setLoading} />;
        case 8:
          return <MakeADeposit />;
        default:
          return <div>Not Found</div>;
      }
    }
  }

  /**
   * Handle prepare data base on formik form values and submit account
   * @param {object} values formik form values
   * @param {object} actions formik form actions
   */
  async function _submitForm(values, actions) {
    const body = handleDataBeforeSubmit(clone(values), values);
    body.time = Date.now();
    if (Array.isArray(body.personal_financial_information.source_of_fund)) {
      body.personal_financial_information.source_of_fund =
        body.personal_financial_information.source_of_fund.join(",");
    }
    if (Array.isArray(body.account_type)) {
      body.account_type = body.account_type.join(",");
    }
    body.personal_information.dob = moment(
      body.personal_information.dob
    ).format("YYYY/MM/DD");
    body.identity_disclosure = {
      acting_as_intermediary: {},
      registered_securities_futures: {},
      related_to_public_function: {},
      related_to_magpie: {},
      control_magpie_voting: {},
      spouse_margin: {},
    };
    if (values.identity_disclosure.registered_securities_futures) {
      body.identity_disclosure.registered_securities_futures.licensed_person_comp_name =
        values.identity_disclosure.licensed_person_comp_name;
    }
    if (values.identity_disclosure.related_to_public_function) {
      body.identity_disclosure.related_to_public_function.relationship_desc =
        values.identity_disclosure.relationship_desc;
    }
    if (values.identity_disclosure.related_to_magpie) {
      body.identity_disclosure.related_to_magpie.comp_officer_name =
        values.identity_disclosure.comp_officer_name;
      body.identity_disclosure.related_to_magpie.comp_officer_relationship =
        values.identity_disclosure.comp_officer_relationship;
    }
    if (values.identity_disclosure.control_magpie_voting) {
      body.identity_disclosure.control_magpie_voting.client_name =
        values.identity_disclosure.client_name;
      body.identity_disclosure.control_magpie_voting.client_account_number =
        values.identity_disclosure.client_account_number;
    }
    if (values.identity_disclosure.spouse_margin) {
      body.identity_disclosure.spouse_margin.spouse_name =
        values.identity_disclosure.spouse_name;
      body.identity_disclosure.spouse_margin.spouse_account_number =
        values.identity_disclosure.spouse_account_number;
    }
    body.risk_disclosure = {};
    body.live_detection = {};
    body.bank_verification = {
      bank_name: null,
      bank_card_number: null,
    };
    if (isEmpty(body.personal_information.referral_code)) {
      delete body.personal_information.referral_code;
    }
    if (dataStorage.isOverSeas) {
      delete body.applicant_verification_id.front_side;
      delete body.applicant_verification_id.back_side;
    } else {
      delete body.applicant_verification_id.pass_port;
    }
    callSubmit(body, {
      onSuccess: () => {
        scrollToTop();
        callSaveDraft(clone(values));
        listStepCanPress.current[activeStep + 1] = true;
        actions.setTouched({});
        actions.setSubmitting(false);
        setActiveStep(activeStep + 1);
        console.log("Submit success");
      },
      onError: () => {
        actions.setSubmitting(false);
        console.error("Cannot post sign to server");
        dataStorage.showAlert("lang_submit_unsuccessfully");
      },
    });
  }

  /**
   * Handle goto next step
   * @param {object} values formik form values
   * @param {object} actions formik form actions
   * @returns void if in kyc step and processing
   */
  const onNext = (values, actions) => {
    const callback = () => {
      scrollToTop();
      callSaveDraft(values);
      listStepCanPress.current[activeStep + 1] = true;
      actions.setTouched({});
      actions.setSubmitting(false);
      setActiveStep(activeStep + 1);
    };
    if (dataStorage.isOverSeas) {
      switch (activeStep) {
        case 0: {
          if (!livenessEnable.current) return;
          if (kycUrl.current && kycUrl.current !== "waiting") callback();
          else {
            kycUrl.current = "waiting";
          }
          break;
        }
        case 2: {
          // Verify mobile number
          dataStorage.showModal({
            component: VerifyPhoneNumber,
            props: {
              callback: (response) => {
                values.verify_otp_token = response.verifyOtpToken;
                callback();
              },
              phone: values.personal_information.mobile_number,
            },
            cancelCallback: () => actions.setSubmitting(false),
          });
          break;
        }
        case 7: {
          // check all checkbox checked
          // show popup reference
          dataStorage.showModal({
            component: RefferredCode,
            props: {
              referrerCode: values.personal_information?.referral_code || "",
              callback: (referrerCode) => {
                values.personal_information.referral_code = referrerCode || "";
                callback();
              },
            },
          });
          break;
        }
        default:
          callback();
          break;
      }
    } else {
      switch (activeStep) {
        case 1: {
          // Verify mobile number
          dataStorage.showModal({
            component: VerifyPhoneNumber,
            props: {
              callback: (response) => {
                values.verify_otp_token = response.verifyOtpToken;
                callback();
              },
              phone: values.personal_information.mobile_number,
            },
            cancelCallback: () => actions.setSubmitting(false),
          });
          break;
        }
        case 6: {
          // check all checkbox checked
          // show popup reference
          dataStorage.showModal({
            component: RefferredCode,
            props: {
              referrerCode: values.personal_information?.referral_code || "",
              callback: (referrerCode) => {
                values.personal_information.referral_code = referrerCode || "";
                callback();
              },
            },
          });
          break;
        }
        default:
          callback();
          break;
      }
    }
  };

  /**
   * if creat account done finish by goto main website
   * @param {object} values formik form values
   * @param {object} actions formik form actions
   */
  const onFinish = (values, actions) => {
    const {
      accessToken,
      refreshToken,
      baseUrl,
      deviceID,
      scope,
      userId,
      userType,
      phone,
    } = dataStorage.auth;
    const url = dataStorage.config?.website;
    const link = `${url}?accessToken=${accessToken}&refreshToken=${refreshToken}&baseUrl=${baseUrl}&deviceId=${deviceID}&scope=${scope}&userId=${userId}&userType=${userType}&phone=${phone}`;
    actions.setSubmitting(false);
    window.open(url, link);
  };

  /**
   * Formik submit handler
   * @param {object} values formik form values
   * @param {object} actions formik form actions
   */
  function _handleSubmit(values, actions) {
    if (activeStep === submitStep) {
      _submitForm(values, actions);
    } else if (activeStep === lastStep) {
      onFinish(values, actions);
    } else {
      onNext(values, actions);
    }
  }

  // // temporary not support step click
  // const onStepClick = (actions) => (index) => {
  //   if (index > 1 && index < lastStep) {
  //     if (index < activeStep) {
  //       if (listStepCanPress.current[index]) {
  //         setActiveStep(index);
  //       } else _handleBack();
  //     } else if (index > activeStep) {
  //       actions.validateForm().then((errors) => {
  //         if (errors && Object.keys(errors).length) {
  //           actions.setTouched(errors);
  //         } else {
  //           if (listStepCanPress.current[index]) {
  //             setActiveStep(index);
  //           } else {
  //             refNext.current && refNext.current.click();
  //           }
  //         }
  //       });
  //     }
  //   }
  // };

  /**
   * Handle back to previouse step
   */
  function _handleBack() {
    if (activeStep === 0) {
      onBack?.();
    } else {
      scrollToTop();
      setActiveStep(activeStep - 1);
    }
  }

  /**
   * Render bottom button by each step
   * @param {boolean} isSubmitting formik submit state
   * @param {object} values formik form values
   * @returns HTML UI of list bottom buttons
   */
  const renderButtons = (isSubmitting, values, errors) => {
    const isHaveBack =
      activeStep === 0 ||
      (activeStep > (dataStorage.isOverSeas ? 2 : 0) && activeStep < lastStep);
    const textSubmit =
      activeStep === submitStep
        ? "lang_submit_application"
        : activeStep === lastStep
        ? "lang_initiate_a_fund_transfer_and_deposit"
        : "lang_next";
    const isBtnLoading =
      isSubmitting ||
      (dataStorage.isOverSeas
        ? activeStep === 1 && dataStorage.livenessProgress
        : false);
    return (
      <div className={classes.buttons}>
        <div />
        <div className={classes.rightButtons}>
          {isHaveBack ? (
            <div className={classes.wrapper}>
              <Button onClick={() => _handleBack()} className={classes.button}>
                <Trans>lang_back</Trans>
              </Button>
            </div>
          ) : (
            <React.Fragment />
          )}
          <div className={classes.wrapper}>
            <Button
              ref={refNext}
              disabled={
                isLoading ||
                isBtnLoading ||
                checkBtnDisable(values, kycResult, activeStep, errors)
              }
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
            >
              <Trans>{textSubmit}</Trans>
              {isLoading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Formik
      initialValues={initialData.current}
      validationSchema={currentValidationSchema}
      validateOnBlur={true}
      validateOnChange={false}
      onSubmit={_handleSubmit}
    >
      {({ isSubmitting, values, errors, setSubmitting }) => {
        // console.log("YOLO errors: ", errors);
        formValues.current = values;
        formActions.current = {
          setSubmitting,
        };
        return (
          <div className={classes.container}>
            <Steps
              steps={listStep.current}
              activeStep={activeStep}
              listStepCanPress={listStepCanPress.current}
              // onStepClick={onStepClick({ validateForm, setTouched })} //disable function
            />
            <div className={classes.formContainer}>
              <FocusError>
                <Form id={formId} autoComplete="off" noValidate>
                  {_renderStepContent()}
                  {renderButtons(isSubmitting, values, errors)}
                </Form>
              </FocusError>
            </div>
          </div>
        );
      }}
    </Formik>
  );
}
