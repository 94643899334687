import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import formModel from "containers/Onboarding/FormModels/Models";
import { useFormikContext } from "formik";
import { Trans } from "react-i18next";
import { SignatureField } from "components/CustomFields";
import { useSignature } from "hooks/useKYC";
import dataStorage from "dataStorage";

const { formField } = formModel;

const Esignature = ({ setLoading }) => {
  const { values, setFieldValue } = useFormikContext();
  const { mutate: callUploadSign } = useSignature();

  /**
   * Handle user change e signature
   */
  const onChangeSign = React.useCallback(
    (sign) => {
      if (!sign) return;
      setLoading(true);
      callUploadSign(
        {
          file: sign.split(",")[1],
        },
        {
          onSuccess: (data) => {
            setLoading(false);
            setFieldValue(formField.e_signature.name, data, true);
          },
          onError: () => {
            setLoading(false);
            console.error("Cannot post sign to server");
            dataStorage.showAlert("lang_upload_signature_photo_failed");
          },
        }
      );
    },
    [callUploadSign, setFieldValue]
  );

  const name =
    values.personal_information.eng_given_name +
    (values.personal_information.eng_middle_name
      ? ` ${values.personal_information.eng_middle_name}`
      : "") +
    ` ${values.personal_information.eng_sur_name}`;
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">
          <Trans values={{ name }}>lang_esignature_tip</Trans>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          <Trans>lang_esignature_confirm</Trans>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <SignatureField
          label={formField.e_signature.label}
          name={formField.e_signature.name}
          onChange={onChangeSign}
        />
      </Grid>
    </Grid>
  );
};

export default Esignature;
