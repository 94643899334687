import styled from "@emotion/styled";
import Close from "@mui/icons-material/Close";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { makeStyles, useTheme } from "@mui/styles";
import dataStorage from "dataStorage";
import { hiddenPhone } from "helpers/utils";
import { useVerifyPhoneNumber } from "hooks/useAuth";
import { useGetPhoneOTP } from "hooks/useOTP";
import { debounce, isUndefined } from "lodash";
import React, { useEffect, useState } from "react";
import ReactCodeInput from "react-code-input";
import { Trans } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
}));

const BtnClose = styled.span`
  float: right;
`;

const OTP_LENGTH = 6;

/**
 * get count down time
 * @param {string} phone is the phone number
 * @returns the rest time
 */
const getRestCount = (phone) => {
  phone = phone.replace(/\|/, "");
  if (dataStorage.timeSendOTP[phone]) {
    const restTime = 60000 - (Date.now() - dataStorage.timeSendOTP[phone]);
    if (restTime > 0) return Math.round(restTime / 1000);
    else return 0;
  } else return 0;
};

const VerifyPhoneNumber = ({ phone, callback, close }) => {
  const theme = useTheme();
  const classes = useStyles();
  const pinRef = React.useRef();
  const [error] = React.useState("");
  const { mutate: verifyPhoneOTP } = useVerifyPhoneNumber();
  const { mutate: requestPhoneOTP } = useGetPhoneOTP();
  const [count, setCount] = React.useState();
  const intervalId = React.useRef(null);
  const [otpObj, setOtpObj] = useState();

  React.useEffect(() => {
    const restCount = getRestCount(phone);
    if (restCount) {
      startCount(restCount);
    } else {
      window.grecaptcha.ready(function () {
        window.grecaptcha
          .execute("6Ld3_w8dAAAAAHDKxfB09-Y6lSbYhvZji5XB0z9r", {
            action: "submit",
          })
          .then(function (token) {
            requestPhoneOTP(
              { phone: (phone + "").replace(/\|/g, ""), recaptchaToken: token },
              {
                onSuccess: () => startCount(),
                onError: (error) => {
                  dataStorage.showAlert(error);
                },
              }
            );
          });
      });
    }
    return () => {
      clearInterval(intervalId.current);
    };
  }, []);

  useEffect(() => {
    if (isUndefined(otpObj)) return;
    const controller = new AbortController();
    getOtp(otpObj.otp, controller.signal);
    return () => {
      if (!isUndefined(otpObj)) {
        controller.abort();
      }
    };
  }, [otpObj]);

  /**
   * Get the otp code from API
   *
   * @param  otp is the otp code from otpObj
   * @param  signal is the controller signal
   */

  const getOtp = (otp, signal) => {
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute("6Ld3_w8dAAAAAHDKxfB09-Y6lSbYhvZji5XB0z9r", {
          action: "submit",
        })
        .then(function () {
          verifyPhoneOTP(
            {
              otpCode: otp,
              phoneNumber: (phone + "").replace(/\|/g, ""),
              signal: signal,
            },
            {
              onSuccess: (response) => {
                callback?.(response);
                close?.();
              },
              onError: (error) => {
                clearPin();
                console.error("verify phone OTP error: ", error);
                dataStorage.showAlert(error);
              },
            }
          );
        });
    });
  };

  /**
   * Update debounce value
   */

  const onchangeDebounce = debounce((otp) => {
    setOtpObj({ ...otpObj, otp: otp });
  }, 1000);
  const onChangeOTP = (otp) => {
    if (otp.length === OTP_LENGTH) {
      onchangeDebounce(otp);
    }
  };

  /**
   * Clear the pin value
   */

  const clearPin = () => {
    if (pinRef.current.textInput[0]) pinRef.current.textInput[0].focus();
    pinRef.current.state.input[0] = "";
    pinRef.current.state.input[1] = "";
    pinRef.current.state.input[2] = "";
    pinRef.current.state.input[3] = "";
    pinRef.current.state.input[4] = "";
    pinRef.current.state.input[5] = "";
  };

  React.useEffect(() => {
    if (count === 0) {
      clearInterval(intervalId.current);
    }
  }, [count]);

  /**
   * Start count down
   *
   * @param  initCount is the init value to count down
   */

  const startCount = (initCount = 60) => {
    setCount(initCount);
    intervalId.current && clearInterval(intervalId.current);
    intervalId.current = setInterval(() => {
      setCount((old) => old - 1);
    }, 1000);
  };

  /**
   * Resend otp code
   */

  const onResend = () => {
    clearPin();
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute("6Ld3_w8dAAAAAHDKxfB09-Y6lSbYhvZji5XB0z9r", {
          action: "submit",
        })
        .then(function (token) {
          requestPhoneOTP(
            { phone: (phone + "").replace(/\|/g, ""), recaptchaToken: token },
            {
              onSuccess: () => startCount(),
              onError: (error) => {
                dataStorage.showAlert(error);
              },
            }
          );
        });
    });
  };

  return (
    <Grid container spacing={3} className={classes.container}>
      <Grid item xs={12} textAlign="center">
        <BtnClose
          onClick={() => {
            close?.();
          }}
        >
          <Close />
        </BtnClose>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 0 }} textAlign="center">
        <Typography variant="body2">
          <Trans
            components={[<strong />]}
            values={{ phone: hiddenPhone(phone) }}
          >
            lang_phone_verification_code_send_noti
          </Trans>
        </Typography>
      </Grid>
      <Grid item xs={12} textAlign="center">
        <ReactCodeInput
          id="phoneCode"
          type="number"
          name={"verify_phone_otp"}
          className="ReactCodeInput"
          isValid={!error}
          fields={OTP_LENGTH}
          pattern="[0-9]*"
          inputmode="numeric"
          onChange={onChangeOTP}
          ref={pinRef}
          inputStyle={{
            outline: "none",
            margin: 4,
            width: "calc((100% - 48px) / 6)",
            maxWidth: 48,
            height: 48,
            borderRadius: 4,
            fontSize: 32,
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            border: `1px solid ${theme.palette.grey[500]}`,
            textAlign: "center",
          }}
          inputStyleInvalid={{
            outline: "none",
            margin: 4,
            width: "calc((100% - 48px) / 6)",
            maxWidth: 48,
            height: 48,
            borderRadius: 4,
            fontSize: 32,
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            border: `1px solid ${theme.palette.error.main}`,
            textAlign: "center",
          }}
        />
      </Grid>
      {error ? (
        <Grid item xs={12} textAlign="center">
          <FormHelperText error style={{ textAlign: "center" }}>
            <Trans>lang_wrong_otp</Trans>
          </FormHelperText>
        </Grid>
      ) : (
        <React.Fragment />
      )}
      <Grid item xs={12}>
        <Typography variant="body2">
          <Trans
            values={{ count: count ? `(${count}s)` : "" }}
            components={[
              <Link
                component="button"
                style={{
                  opacity: count ? 0.6 : 1,
                  cursor: count ? "default" : "grab",
                }}
                variant="body2"
                disabled={!!count}
                onClick={onResend}
              />,
              <Link
                href={`https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&to=cs@magpiesecurities.com`}
                target="_blank"
              />,
            ]}
          >
            lang_if_dont_receive_phone_verification_code
          </Trans>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default VerifyPhoneNumber;
