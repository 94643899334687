import React from "react";
import { makeStyles, withStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import CustomIcon from "./CustomIcon";
import { isMobile } from "helpers/utils";

const useStyles = makeStyles((theme) => ({
  container: {
    "&:hover": {
      "& svg": {
        fill: theme.palette.primary.main,
      },
    },
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
    maxWidth: 540,
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${theme.palette.primary.main}`,
  },
}))(Tooltip);

export default function CustomizedTooltips({
  iconName,
  tooltip,
  hover = true,
  placement = "bottom",
  style = {},
  iconColor = "#A7A7A8",
}) {
  const classes = useStyles();
  const [isOpen, setOpened] = React.useState(false);

  /**
   * hide tooltip
   */
  const onClose = () => {
    if (hover && !isMobile()) return;
    setOpened(false);
  };

  /**
   * show tooltip
   */
  const onOpen = () => {
    if (hover && !isMobile()) return;
    setOpened(true);
  };

  const props = React.useMemo(() => {
    if (hover && !isMobile()) return {};
    else {
      return {
        // open: isOpen,
        disableFocusListener: true,
        // disableHoverListener: true,
        // disableTouchListener: true,
      };
    }
  }, [hover, isOpen]);

  return (
    <ClickAwayListener onClickAway={onClose}>
      <HtmlTooltip
        enterTouchDelay={0}
        className={classes.container}
        placement={isMobile() ? "bottom" : placement}
        onClose={onClose}
        disableFocusListener
        leaveTouchDelay={2000}
        {...props}
        title={<React.Fragment>{tooltip}</React.Fragment>}
      >
        <IconButton onClick={onOpen} style={style}>
          <CustomIcon
            name={iconName || "Help"}
            color={iconColor}
            style={{ width: 20, height: 20 }}
          />
        </IconButton>
      </HtmlTooltip>
    </ClickAwayListener>
  );
}
