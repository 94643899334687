import React from "react";
import { CssBaseline } from "@mui/material";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
  StyledEngineProvider,
} from "@mui/material/styles";
import Footer from "./Footer";
import dataStorage from "dataStorage";
import Toolbar from "components/Toolbar";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import { Outlet } from "react-router-dom";
import ConfirmModal from "components/ConfirmModal";

export default function MaterialLayout() {
  const themeLocal = localStorage.getItem("theme");
  const [mode, setMode] = React.useState(themeLocal ?? dataStorage.theme);
  React.useEffect(() => {
    const changeTheme = (theme) => {
      dataStorage.theme = theme;
      document.body.classList.remove("dark", "light");
      document.body.classList.add(`${theme}`);
      localStorage.setItem("theme", theme);
      setMode(theme);
    };
    if (themeLocal) {
      dataStorage.theme = themeLocal;
      document.body.classList.remove("dark", "light");
      document.body.classList.add(`${themeLocal}`);
    }
    dataStorage.changeTheme = changeTheme;
  }, []);

  /**
   * Initial material theme
   */
  const theme = React.useMemo(() => {
    const themeData =
      mode === "light"
        ? createTheme({
            breakpoints: {
              values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536,
                xxl: 1895,
              },
            },
            components: {
              MuiAutocomplete: {
                styleOverrides: {
                  paper: {
                    width: "max-content",
                    minWidth: "100%",
                  },
                },
              },
              MuiDialogActions: {
                styleOverrides: {
                  root: {
                    padding: 16,
                  },
                },
              },
              MuiStep: {
                styleOverrides: {
                  root: {
                    padding: 0,
                  },
                },
              },
              MuiLink: {
                styleOverrides: {
                  root: {
                    wordBreak: "break-all",
                  },
                },
              },
            },
            palette: {
              mode: "light",
              primary: {
                main: "#D5A34D",
                light: "#D5A34D",
                dark: "#D5A34D",
                contrastText: "#FFFFFF",
              },
              boxBg: "#E0E0E0",
              boxBorder: "#BDBDBD",
            },
            typography: {
              fontSize: 16,
            },
          })
        : createTheme({
            breakpoints: {
              values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536,
                xxl: 1895,
              },
            },
            components: {
              MuiPaper: {
                styleOverrides: {
                  root: {
                    backgroundImage: "unset",
                  },
                },
              },
              MuiAutocomplete: {
                styleOverrides: {
                  paper: {
                    width: "max-content",
                    minWidth: "100%",
                  },
                },
              },
              MuiDialogActions: {
                styleOverrides: {
                  root: {
                    padding: 16,
                  },
                },
              },
              MuiStep: {
                styleOverrides: {
                  root: {
                    padding: 0,
                  },
                },
              },
              MuiLink: {
                styleOverrides: {
                  root: {
                    wordBreak: "break-all",
                  },
                },
              },
            },
            palette: {
              mode: "dark",
              primary: {
                main: "#D5A34D",
                light: "#D5A34D",
                dark: "#D5A34D",
                contrastText: "#FFFFFF",
              },
              boxBg: "#212121",
              boxBorder: "#424242",
            },
            typography: {
              fontSize: 16,
            },
          });
    return responsiveFontSizes(themeData);
  }, [mode]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div id="topMark" />
        <ErrorBoundary />
        <Toolbar />
        <div className="appBackground" />
        <Outlet />
        <Footer />
        <ConfirmModal />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
