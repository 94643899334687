import { useQuery, useMutation } from "react-query";
import { postData, getData } from "api/request";
import {
  getTransactionUrl,
  getUploadImageUrl,
  getRenewLivenessTokenUrl,
} from "api/api";
import { getBase64 } from "helpers/utils";

const getTransaction = async () => {
  const result = await getData(getTransactionUrl());
  return result;
};
const useTransaction = (options = {}) => {
  return useQuery(["getTransaction"], getTransaction, options);
};

const postPhoto = async ({ key, file, ...rest }) => {
  const base64ImageData = await getBase64(file);
  const base64Only = base64ImageData.split(",")[1];
  const imageUrl = await postData(getUploadImageUrl(), {
    [key]: base64Only,
    ...rest,
  });
  return imageUrl;
};
const useImage = () => {
  return useMutation((variables) => postPhoto(variables));
};

const postAddressPhoto = async ({ file, documentType }) => {
  const base64ImageData = await getBase64(file);
  const base64Only = base64ImageData.split(",")[1];
  const imageUrl = await postData(getUploadImageUrl(), {
    correspondenceAddressDocument: base64Only,
    documentType,
  });
  return imageUrl;
};
const useAddress = () => {
  return useMutation((variables) => postAddressPhoto(variables));
};

const postSignature = async ({ file }) => {
  const imageUrl = await postData(getUploadImageUrl(), {
    eSignature: file,
    documentType: "png",
  });
  return imageUrl;
};
const useSignature = () => {
  return useMutation((variables) => postSignature(variables));
};

const renewLiveness = async () => {
  const newtoken = await getData(getRenewLivenessTokenUrl());
  return newtoken;
};
const useRenewLiveness = () => {
  return useMutation((variables) => renewLiveness(variables));
};

export { useTransaction, useImage, useAddress, useSignature, useRenewLiveness };
