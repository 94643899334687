import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { makeCapitalizeFunc, isValidHttpUrl } from "helpers/utils";
import dataStorage from "dataStorage";
import { QueryClient, QueryClientProvider } from "react-query";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { autoRenewToken } from "hooks/useAuth";
import ListPhoneCountry from "constants/Countries/countriesPhone";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});

/**
 * init capitalize function for string builder
 */
makeCapitalizeFunc();

/**
 * Initial website after have enough config
 */
const initialWebsite = () => {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  );
};

/**
 * Handle auto login when user access from main magpie website
 */
const handleAutoLogin = () => {
  if (isValidHttpUrl(window.name)) {
    const url = new URL(window.name);
    window.name = "";
    const queryObj = new URLSearchParams(url.search);
    const accessToken = queryObj.get("accessToken");
    const refreshToken = queryObj.get("refreshToken");
    const baseUrl = queryObj.get("baseUrl");
    const deviceId = queryObj.get("deviceId");
    const userId = queryObj.get("userId");
    const phone = queryObj.get("phone");
    const accountStatus = queryObj.get("accountStatus");
    dataStorage.deviceId = deviceId;
    dataStorage.phone = "+" + (phone?.trim() || "");
    dataStorage.baseUrl = baseUrl;
    axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
    axios.defaults.baseURL = dataStorage.baseURL;
    dataStorage.auth = {
      phone,
      accessToken,
      refreshToken,
      deviceID: deviceId,
      userId,
    };
    dataStorage.accountStatus = accountStatus;
    autoRenewToken();
  }
};

/**
 * get config and save in storage to use
 */
fetch(`${window.location.origin}/config.json?${+new Date()}`)
  .then((response) => response.json())
  .then((config) => {
    const currentCountry = ListPhoneCountry.find(
      (e) => e.value === window?.currentGeolocation?.country_code3
    );
    dataStorage.defaultCountry = currentCountry;
    dataStorage.deviceId = uuidv4();
    window.__data = dataStorage;
    dataStorage.config = config;
    dataStorage.baseUrl = dataStorage.config.baseUrl;
    dataStorage.addressKey = dataStorage.config.addressKey;
    axios.defaults.baseURL = dataStorage.baseURL;
    handleAutoLogin();
    initialWebsite();
  })
  .catch((error) => {
    console.error("get web config error", error);
    initialWebsite();
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
