import Grid from "@mui/material/Grid";
import { InputField, SelectField } from "components/CustomFields";
import {
  EMPLOYMENT_STATUS,
  EMPLOYMENT_STATUS_OPTIONS,
  OCCUPATION_INDUSTRY,
  OCCUPATION_INDUSTRY_OPTIONS,
  OCCUPATION_ROLE,
  OCCUPATION_ROLE_OPTIONS,
  WORKING_DURATION_OPTIONS,
} from "constants/Options/options";
import formModel from "containers/Onboarding/FormModels/Models";
import { useFormikContext } from "formik";
import React, { useMemo } from "react";

const { formField } = formModel;

const EmploymentInformation = () => {
  const { values, setFieldValue } = useFormikContext();

  const occopations = useMemo(() => {
    switch (values.employment_information.employment_status) {
      case EMPLOYMENT_STATUS.OTHER:
        return OCCUPATION_ROLE_OPTIONS;
      case EMPLOYMENT_STATUS.EMPLOYED: {
        const arrWithEmployedStatus = OCCUPATION_ROLE_OPTIONS.filter(
          (e) => e.value !== EMPLOYMENT_STATUS.SELF_EMPLOYED
        );
        return arrWithEmployedStatus;
      }
      case EMPLOYMENT_STATUS.SELF_EMPLOYED: {
        const arrWithSelfStatus = OCCUPATION_ROLE_OPTIONS.filter(
          (e) =>
            e.value == EMPLOYMENT_STATUS.SELF_EMPLOYED ||
            e.value == EMPLOYMENT_STATUS.OTHER
        );
        return arrWithSelfStatus;
      }
      default:
        return [];
    }
  }, [values.employment_information.employment_status]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <SelectField
          required
          name={formField.employment_information.employment_status.name}
          label={formField.employment_information.employment_status.label}
          data={EMPLOYMENT_STATUS_OPTIONS}
          fullWidth
          onchangeExtra={(value) => {
            switch (value) {
              case EMPLOYMENT_STATUS.OTHER:
                setFieldValue(
                  formField.employment_information.occupation_role.name,
                  OCCUPATION_ROLE.OFFICER
                );
                break;
              case EMPLOYMENT_STATUS.EMPLOYED:
                setFieldValue(
                  formField.employment_information.occupation_role.name,
                  OCCUPATION_ROLE.OFFICER
                );
                break;
              case EMPLOYMENT_STATUS.SELF_EMPLOYED:
                setFieldValue(
                  formField.employment_information.occupation_role.name,
                  OCCUPATION_ROLE.SELF_EMPLOYED
                );
                break;
              default:
                break;
            }
          }}
        />
      </Grid>
      {values?.employment_information?.employment_status ===
      EMPLOYMENT_STATUS.OTHER ? (
        <Grid item xs={12}>
          <InputField
            required
            maxLength={100}
            name={formField.employment_information.employment_status_desc.name}
            label={
              formField.employment_information.employment_status_desc.label
            }
            fullWidth
          />
        </Grid>
      ) : (
        <React.Fragment />
      )}
      {values?.employment_information?.employment_status ===
      EMPLOYMENT_STATUS.RETIRED ? (
        <React.Fragment />
      ) : (
        <React.Fragment>
          <Grid item xs={12}>
            <InputField
              required
              maxLength={200}
              name={formField.employment_information.employer_name.name}
              label={formField.employment_information.employer_name.label}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <SelectField
              required
              name={formField.employment_information.occupation_role.name}
              label={formField.employment_information.occupation_role.label}
              data={occopations}
              fullWidth
            />
          </Grid>
          {values?.employment_information?.occupation_role ===
          OCCUPATION_ROLE.OTHER ? (
            <Grid item xs={12}>
              <InputField
                required
                maxLength={100}
                name={
                  formField.employment_information.occupation_role_desc.name
                }
                label={
                  formField.employment_information.occupation_role_desc.label
                }
                fullWidth
              />
            </Grid>
          ) : (
            <React.Fragment />
          )}
          <Grid item xs={12}>
            <SelectField
              required
              name={formField.employment_information.occupation_industry.name}
              label={formField.employment_information.occupation_industry.label}
              data={OCCUPATION_INDUSTRY_OPTIONS}
              fullWidth
            />
          </Grid>
          {values?.employment_information?.occupation_industry ===
          OCCUPATION_INDUSTRY.OTHERS ? (
            <Grid item xs={12}>
              <InputField
                required
                maxLength={200}
                name={
                  formField.employment_information.occupation_industry_desc.name
                }
                label={
                  formField.employment_information.occupation_industry_desc
                    .label
                }
                fullWidth
              />
            </Grid>
          ) : (
            <React.Fragment />
          )}
          <Grid item xs={12}>
            <SelectField
              required
              name={formField.employment_information.working_duration.name}
              label={formField.employment_information.working_duration.label}
              data={WORKING_DURATION_OPTIONS}
              fullWidth
            />
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
};

export default EmploymentInformation;
