import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles } from "@mui/styles";
import { Trans } from "react-i18next";
import Onboarding from "containers/Onboarding/Onboarding";
import dataStorage from "dataStorage";
import initialValues from "containers/Onboarding/FormModels/InitialValue";
import i18n from "i18next";

const countries = [
  { code: "AU", value: "AUS", label: "Australia", phone: "61" },
  { code: "AT", value: "AUT", label: "Austria", phone: "43" },
  { code: "BE", value: "BEL", label: "Belgium", phone: "32" },
  { code: "CA", value: "CAN", label: "Canada", phone: "1" },
  { code: "IE", value: "IRL", label: "Ireland", phone: "353" },
  { code: "IL", value: "ISR", label: "Israel", phone: "972" },
  { code: "IT", value: "ITA", label: "Italy", phone: "39" },
  { code: "MY", value: "MYS", label: "Malaysia", phone: "60" },
  { code: "NO", value: "NOR", label: "Norway", phone: "47" },
  { code: "PT", value: "PRT", label: "Portugal", phone: "351" },
  { code: "SG", value: "SGP", label: "Singapore", phone: "65" },
  { code: "ES", value: "ESP", label: "Spain", phone: "34" },
  { code: "SE", value: "SWE", label: "Sweden", phone: "46" },
  { code: "CH", value: "CHE", label: "Switzerland", phone: "41" },
  { code: "GB", value: "GBR", label: "United Kingdom", phone: "44" },
  { code: "US", value: "USA", label: "United States", phone: "1" },
  { code: "OTHER", value: "OTHER", label: "lang_other", phone: "" },
];

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: theme.spacing(7),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
    },
    "& button": {
      width: "fit-content",
    },
  },
}));

export default function ChooseCountry(props) {
  const classes = useStyles();
  const [country, setCountry] = React.useState("");
  const [already, setAlready] = React.useState(false);
  const [options, setOption] = React.useState(
    countries.map((e) => ({
      ...e,
      label: dataStorage.translate(e.label),
    }))
  );

  /**
   * set new options by new language when language changed
   */
  React.useEffect(() => {
    initialValues.personal_information.mobile_number = dataStorage.phone;
    i18n.on("languageChanged", function () {
      setOption(
        countries.map((e) => ({
          ...e,
          label: dataStorage.translate(e.label),
        }))
      );
    });
  }, []);

  /**
   * if already render onboarding as next step
   */
  if (already) {
    return <Onboarding {...props} />;
  }

  /**
   * Handle change country and show warning if country not support
   * @param {Event} event select change event
   * @param {object} option selected option
   * @param {string} reason as select action
   */
  const onChangeCountry = (event, option, reason) => {
    if (reason === "selectOption") {
      if (option.value === "OTHER") {
        // currently not support
        dataStorage.showModal({
          title: "lang_notify",
          content: "lang_country_not_support",
        });
        setCountry("");
      } else setCountry(option);
    }
  };

  /**
   * Custom filter of material autocomplete
   * @param {Array} options list option
   * @param {object} param1 contain search text user typing
   * @returns list option valid with filter condition of user
   */
  const filterOptionsCustom = (options, { inputValue }) => {
    if (!inputValue) return options;
    return options.filter((e) =>
      e.label.toLowerCase().includes(inputValue.toLowerCase().trim())
    );
  };

  /**
   * Render list countries
   * @returns HTML UI of autocomplete countries
   */
  const renderCountrySelect = () => {
    return (
      <Autocomplete
        openText={dataStorage.translate("lang_open")}
        closeText={dataStorage.translate("lang_close")}
        noOptionsText={dataStorage.translate("lang_no_options")}
        required={true}
        fullWidth
        onChange={onChangeCountry}
        options={options}
        isOptionEqualToValue={(option, select) => {
          return select && option.value === select.value;
        }}
        sx={{ mt: 3 }}
        getOptionLabel={(option) => option.label || ""}
        filterOptions={filterOptionsCustom}
        disableClearable
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
            key={option.value}
          >
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              alt=""
            />
            {option.label}
          </Box>
        )}
        renderInput={(params) => {
          return (
            <React.Fragment>
              <TextField
                name={"password"}
                {...params}
                label={<Trans>lang_choose_country</Trans>}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "off",
                  form: {
                    autocomplete: "off",
                  },
                }}
                required={true}
                variant="outlined"
                type="text"
              />
            </React.Fragment>
          );
        }}
      />
    );
  };

  return (
    <Box className={classes.container}>
      <Typography variant="h6">
        <Trans>lang_country</Trans>
      </Typography>
      <Typography variant="body1">
        <Trans>lang_country_explain</Trans>
      </Typography>
      {renderCountrySelect()}
      <Box display="flex" justifyContent="center" sx={{ mt: 4 }}>
        <Button
          variant="contained"
          disabled={!country}
          onClick={() => setAlready(true)}
        >
          <Trans>lang_open_account_now</Trans>
        </Button>
      </Box>
    </Box>
  );
}
