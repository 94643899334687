/* eslint-disable max-lines */
// keep each step in a file

import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import formModel from "containers/Onboarding/FormModels/Models";
import { useFormikContext, FieldArray } from "formik";
import {
  SelectField,
  InputField,
  BooleanRadio,
  FileUpload,
  AutocompleteField,
} from "components/CustomFields";
import {
  TAX_ID_REASON,
  TAX_ID_REASON_OPTIONS,
  BOOLEAN_OPTIONS,
} from "constants/Options/options";
import { Trans } from "react-i18next";
import ListCountry from "constants/Countries/countriesLimit";
import ListTaxCountry from "constants/Countries/countriesTax";
import useStyles from "containers/Onboarding/styles";
import { EMPTY_TAX_RESIDENCY } from "constants/constants";
import { clone } from "helpers/utils";

const { formField } = formModel;

const OtherIdentityDisclosuresAndTaxInformation = () => {
  const classes = useStyles();
  const { values } = useFormikContext();
  const renderTaxInformation = () => {
    return (
      <FieldArray
        name={formField.tax_information.name}
        render={(arrayHelpers) => (
          <React.Fragment>
            {values.tax_information.map((e, i) => (
              <Grid
                container
                spacing={3}
                key={`tax_information_${i}`}
                className={classes.boxContainer}
              >
                {values.tax_information.length > 1 ? (
                  <IconButton
                    className={classes.removeArrBtn}
                    onClick={() => arrayHelpers.remove(i)}
                  >
                    <RemoveIcon />
                  </IconButton>
                ) : (
                  <React.Fragment />
                )}
                <Grid item xs={12}>
                  <Typography variant="subtitle2">
                    <Trans values={{ index: i + 1 }}>
                      lang_tax_residency_with_index
                    </Trans>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <AutocompleteField
                    required
                    name={`tax_information[${i}].tax_country`}
                    label={formField.tax_information.tax_country.label}
                    data={ListTaxCountry}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <BooleanRadio
                    isHorizontal
                    name={`tax_information[${i}].is_there_taxid`}
                    label={formField.tax_information.is_there_taxid.label}
                    data={BOOLEAN_OPTIONS}
                    fullWidth
                  />
                </Grid>
                {e.is_there_taxid ? (
                  <Grid item xs={12}>
                    <InputField
                      required
                      // maxLength={e.tax_country === 'CHN' ? 18 : 15}
                      textTransform="uppercase"
                      name={`tax_information[${i}].tax_id`}
                      label={formField.tax_information.tax_id.label}
                      fullWidth
                    />
                  </Grid>
                ) : (
                  <React.Fragment>
                    <Grid item xs={12}>
                      <SelectField
                        required
                        name={`tax_information[${i}].taxid_reason`}
                        label={formField.tax_information.taxid_reason.label}
                        data={TAX_ID_REASON_OPTIONS}
                        fullWidth
                      />
                    </Grid>
                    {e.taxid_reason === TAX_ID_REASON.REASON_B ? (
                      <Grid item xs={12}>
                        <InputField
                          required
                          maxLength={300}
                          name={`tax_information[${i}].taxid_reason_desc`}
                          label={
                            formField.tax_information.taxid_reason_desc.label
                          }
                          fullWidth
                        />
                      </Grid>
                    ) : (
                      <React.Fragment />
                    )}
                  </React.Fragment>
                )}
              </Grid>
            ))}
            <Button
              variant="text"
              className={classes.addArrBtn}
              style={{
                opacity: values.tax_information.length < 5 ? "1" : "0.5",
                pointerEvents:
                  values.tax_information.length < 5 ? "unset" : "none",
              }}
              onClick={() => {
                arrayHelpers.push(clone(EMPTY_TAX_RESIDENCY));
              }}
            >
              <Trans>lang_add_another_tax_residency</Trans>
            </Button>
          </React.Fragment>
        )}
      />
    );
  };

  const renderUSPersonInfo = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <BooleanRadio
            isHorizontal
            tooltip={
              <Link
                href={
                  "https://www.irs.gov/individuals/international-taxpayers/classification-of-taxpayers-for-us-tax-purposes"
                }
                target="_blank"
              >
                <Trans>lang_are_you_us_person_link_explain</Trans>
              </Link>
            }
            name={formField.us_person_info.are_you_us_person.name}
            label={formField.us_person_info.are_you_us_person.label}
            data={BOOLEAN_OPTIONS}
            fullWidth
          />
        </Grid>
        {values.us_person_info.are_you_us_person ? (
          <React.Fragment>
            <Typography pl="24px" variant="h6">
              W9
            </Typography>
            <Grid item xs={12}>
              <InputField
                required
                maxLength={9}
                name={formField.us_person_info.taxpayer_id.name}
                label={formField.us_person_info.taxpayer_id.label}
                fullWidth
              />
            </Grid>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography pl="24px" variant="h6">
              W8-BEN
            </Typography>
            <Grid item xs={12}>
              <AutocompleteField
                required
                name={formField.us_person_info.country_of_citizenship.name}
                label={formField.us_person_info.country_of_citizenship.label}
                data={ListCountry}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <AutocompleteField
                required
                name={
                  formField.us_person_info.country_of_tax_treaty_benefits.name
                }
                label={
                  formField.us_person_info.country_of_tax_treaty_benefits.label
                }
                data={ListCountry}
                helperText={"lang_note_tin_when_is_us_person"}
                fullWidth
              />
            </Grid>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <BooleanRadio
          isHorizontal
          name={formField.identity_disclosure.acting_as_intermediary.name}
          label={formField.identity_disclosure.acting_as_intermediary.label}
          data={BOOLEAN_OPTIONS}
          yesTooltip={
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              maxWidth="400px"
            >
              <Typography fontWeight="bold" variant="subtitle2">
                <Trans>lang_identity_first_question_tips</Trans>
              </Typography>
            </Box>
          }
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <BooleanRadio
          isHorizontal
          name={
            formField.identity_disclosure.registered_securities_futures.name
          }
          label={
            formField.identity_disclosure.registered_securities_futures.label
          }
          data={BOOLEAN_OPTIONS}
          fullWidth
        />
      </Grid>
      {values.identity_disclosure.registered_securities_futures ? (
        <React.Fragment>
          <Grid item xs={12}>
            <InputField
              required
              maxLength={200}
              name={
                formField.identity_disclosure.licensed_person_comp_name.name
              }
              label={
                formField.identity_disclosure.licensed_person_comp_name.label
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FileUpload
              required
              name={formField.identity_disclosure.upload_consent_letter.name}
              label={formField.identity_disclosure.upload_consent_letter.label}
            />
          </Grid>
        </React.Fragment>
      ) : (
        <React.Fragment />
      )}
      <Grid item xs={12}>
        <BooleanRadio
          isHorizontal
          name={formField.identity_disclosure.related_to_public_function.name}
          label={formField.identity_disclosure.related_to_public_function.label}
          data={BOOLEAN_OPTIONS}
          fullWidth
        />
      </Grid>
      {values.identity_disclosure.related_to_public_function ? (
        <Grid item xs={12}>
          <InputField
            required
            maxLength={300}
            name={formField.identity_disclosure.relationship_desc.name}
            label={formField.identity_disclosure.relationship_desc.label}
            fullWidth
          />
        </Grid>
      ) : (
        <React.Fragment />
      )}
      <Grid item xs={12}>
        <BooleanRadio
          isHorizontal
          name={formField.identity_disclosure.related_to_magpie.name}
          label={formField.identity_disclosure.related_to_magpie.label}
          data={BOOLEAN_OPTIONS}
          fullWidth
        />
      </Grid>
      {values.identity_disclosure.related_to_magpie ? (
        <React.Fragment>
          <Grid item xs={12}>
            <InputField
              required
              maxLength={100}
              name={formField.identity_disclosure.comp_officer_name.name}
              label={formField.identity_disclosure.comp_officer_name.label}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              required
              maxLength={300}
              name={
                formField.identity_disclosure.comp_officer_relationship.name
              }
              label={
                formField.identity_disclosure.comp_officer_relationship.label
              }
              fullWidth
            />
          </Grid>
        </React.Fragment>
      ) : (
        <React.Fragment />
      )}
      <Grid item xs={12}>
        <BooleanRadio
          isHorizontal
          name={formField.identity_disclosure.spouse_margin.name}
          label={formField.identity_disclosure.spouse_margin.label}
          data={BOOLEAN_OPTIONS}
          fullWidth
        />
      </Grid>
      {values.identity_disclosure.spouse_margin ? (
        <React.Fragment>
          <Grid item xs={12}>
            <InputField
              required
              maxLength={100}
              name={formField.identity_disclosure.spouse_name.name}
              label={formField.identity_disclosure.spouse_name.label}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              required
              maxLength={20}
              name={formField.identity_disclosure.spouse_account_number.name}
              label={formField.identity_disclosure.spouse_account_number.label}
              fullWidth
            />
          </Grid>
        </React.Fragment>
      ) : (
        <React.Fragment />
      )}
      <Grid item xs={12}>
        <BooleanRadio
          isHorizontal
          name={formField.identity_disclosure.control_magpie_voting.name}
          label={formField.identity_disclosure.control_magpie_voting.label}
          data={BOOLEAN_OPTIONS}
          fullWidth
        />
      </Grid>
      {values.identity_disclosure.control_magpie_voting ? (
        <React.Fragment>
          <Grid item xs={12}>
            <InputField
              required
              maxLength={100}
              name={formField.identity_disclosure.client_name.name}
              label={formField.identity_disclosure.client_name.label}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              required
              maxLength={20}
              name={formField.identity_disclosure.client_account_number.name}
              label={formField.identity_disclosure.client_account_number.label}
              fullWidth
            />
          </Grid>
        </React.Fragment>
      ) : (
        <React.Fragment />
      )}
      {renderTaxInformation()}
      {renderUSPersonInfo()}
    </Grid>
  );
};

export default OtherIdentityDisclosuresAndTaxInformation;
