import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { makeStyles, useTheme } from "@mui/styles";
import { LoginContext } from "App.js";
import Logo from "components/Logo";
import { ACCOUNT_STATUS } from "constants/constants";
import dataStorage from "dataStorage";
import { useAccountStatus } from "hooks/useAccount";
import { useRegisterPhoneNumber } from "hooks/useAuth";
import { useSendOTP } from "hooks/useOTP";
import { debounce } from "lodash";
import { isUndefined } from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RenderLoginForm from "./RenderLoginForm";
import RenderOTPForm from "./RenderOTPForm";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "row-reverse",
    width: "100vw",
    justifyContent: "space-evenly",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  loginContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 400,
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  button: {
    width: "100%",
  },
}));

const STEPS = {
  LOGIN: "login",
  OTP: "otp",
};
const OTP_LENGTH = 6;

const LoginPage = () => {
  const theme = useTheme();
  const classes = useStyles();
  const [step, setStep] = React.useState(STEPS.LOGIN);
  const pinRef = React.useRef();
  const phoneData = React.useRef({});
  const recaptchaToken = React.useRef();
  const { isLoading: isLoadingOTP, mutate: mutateOTP, error } = useSendOTP();
  const { isLoading: isLoadingLogin, mutate: mutateLogin } =
    useRegisterPhoneNumber();
  const { mutate: getAccountStatus } = useAccountStatus();
  const navigate = useNavigate();
  const { setLogin } = React.useContext(LoginContext);
  const [isValid, setIsValid] = React.useState(false);
  const validRef = React.useRef(false);
  const [otpObj, setOtpObj] = useState();

  /**
   * Set the valid of phone number
   *
   * @param value is the boolean value
   */

  const setValid = (value) => {
    validRef.current = value;
    setIsValid(value);
  };

  React.useEffect(() => {
    if (dataStorage.auth?.accessToken) {
      window.location.reload();
      return;
    }

    /**
     * Handle when press keyboard
     *
     * @param   event is the DOM event
     */

    const onKeyPress = (event) => {
      if (event.key === "Enter" && validRef.current) {
        event.preventDefault();
        onNext(event);
      }
    };
    document.addEventListener("keypress", onKeyPress);
    return () => {
      document.removeEventListener("keypress", onKeyPress);
    };
  }, []);

  /**
   * Update new phone value
   *
   * @param {object} data is an object has phone data
   */

  const onChangePhone = (data) => {
    phoneData.current = data;
    if (data.phoneNumber && !isValid) setValid(true);
    else if (!data.phoneNumber && isValid) setValid(false);
  };

  /**
   * Move to next page
   *
   * @param e is the DOM event
   */

  const onNext = (e) => {
    e.preventDefault();
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute("6Ld3_w8dAAAAAHDKxfB09-Y6lSbYhvZji5XB0z9r", {
          action: "submit",
        })
        .then(function (token) {
          recaptchaToken.current = token;
          mutateOTP(
            {
              phoneCode: phoneData.current.phone,
              phoneNumber: phoneData.current.phoneNumber.replace(/^0/, ""),
              recaptchaToken: recaptchaToken.current,
            },
            {
              onSuccess: () => setStep(STEPS.OTP),
              onError: (error) => dataStorage.showAlert(error),
            }
          );
        });
    });
  };

  /**
   * Clear pin
   */

  const clearPin = () => {
    if (pinRef.current.textInput[0]) pinRef.current.textInput[0].focus();
    pinRef.current.state.input[0] = "";
    pinRef.current.state.input[1] = "";
    pinRef.current.state.input[2] = "";
    pinRef.current.state.input[3] = "";
    pinRef.current.state.input[4] = "";
    pinRef.current.state.input[5] = "";
  };

  /**
   * Handle account status
   *
   * @param account is the data account get from API
   */

  const handleAccountStatus = (account) => {
    if (!account || !account.account_status) {
      navigate("/");
    } else {
      dataStorage.accountStatus = account.account_status;
      if (account.account_region == "USERS IN HONG KONG SAR") {
        dataStorage.isOverSeas = false;
      }
      switch (account.account_status) {
        case ACCOUNT_STATUS.PENDING:
        case ACCOUNT_STATUS.PENDING_APPROVE:
          dataStorage.showModal({
            title: "lang_notify",
            content: "lang_your_account_is_under_review",
            callback: () => window.location.reload(),
          });
          break;
        case ACCOUNT_STATUS.APPROVED:
          dataStorage.showModal({
            title: "lang_notify",
            content: "lang_your_account_is_active",
            callback: () => window.location.reload(),
          });
          break;
        default:
          navigate("/");
          break;
      }
    }
  };

  /**
   * Handle on change
   *
   * @param {*} otp is the otp code
   * @param {*} signal is the controlled signal
   */

  const handleOnChange = (otp, signal) => {
    mutateLogin(
      {
        otpCode: otp,
        phoneNumber: `+${
          phoneData.current.phone
        }${phoneData.current.phoneNumber.replace(/^0/, "")}`,
        signal: signal,
      },
      {
        onSuccess: () => {
          setLogin(true);
          dataStorage.phone = `+${phoneData.current.phone}|${phoneData.current.phoneNumber}`;
          getAccountStatus(
            {},
            {
              onSuccess: (res) => handleAccountStatus(res),
              onError: (error) => {
                dataStorage.showAlert(error);
              },
            }
          );
        },
        onError: (error) => {
          dataStorage.showAlert(error);
          clearPin();
        },
      }
    );
  };

  useEffect(() => {
    if (isUndefined(otpObj)) return;
    const controller = new AbortController();
    handleOnChange(otpObj.otp, controller.signal);
    return () => {
      if (!isUndefined(otpObj)) {
        controller.abort();
      }
    };
  }, [otpObj]);

  const onchangeDebounce = debounce((otp) => {
    setOtpObj({ ...otpObj, otp: otp });
  }, 1000);

  const onChangeOTP = (otp) => {
    if (otp.length === OTP_LENGTH) {
      onchangeDebounce(otp);
    }
  };

  /**
   * Resend OTP
   */

  const onResendOTP = () => {
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute("6Ld3_w8dAAAAAHDKxfB09-Y6lSbYhvZji5XB0z9r", {
          action: "submit",
        })
        .then(function (token) {
          recaptchaToken.current = token;
          mutateOTP(
            {
              phoneCode: phoneData.current.phone,
              phoneNumber: phoneData.current.phoneNumber.replace(/^0/, ""),
              recaptchaToken: recaptchaToken.current,
            },
            {
              onSuccess: () => console.log("Resend sucess"),
              onError: (error) => dataStorage.showAlert(error),
            }
          );
        });
    });
  };

  return (
    <div className={classes.container}>
      <Logo />
      <Paper elevation={15} className={classes.loginContainer}>
        <Grid container spacing={3}>
          {step === STEPS.LOGIN ? (
            <RenderLoginForm
              onNext={onNext}
              onChangePhone={onChangePhone}
              isLoadingOTP={isLoadingOTP}
              isValid={isValid}
              classes={classes}
            />
          ) : (
            <RenderOTPForm
              phoneData={phoneData}
              onChangeOTP={onChangeOTP}
              pinRef={pinRef}
              error={error}
              isLoadingLogin={isLoadingLogin}
              OTP_LENGTH={OTP_LENGTH}
              theme={theme}
              clearPin={clearPin}
              onResendOTP={onResendOTP}
            />
          )}
        </Grid>
      </Paper>
    </div>
  );
};

export default LoginPage;
