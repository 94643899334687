import { Trans } from "react-i18next";
import React from "react";
import Button from "@mui/material/Button";

const ResendButton = (props) => {
  const [count, setCount] = React.useState();
  const intervalId = React.useRef(null);

  React.useEffect(() => {
    startCount();
    return () => {
      clearInterval(intervalId.current);
    };
  }, []);

  React.useEffect(() => {
    if (count === 0) {
      clearInterval(intervalId.current);
    }
  }, [count]);

  const startCount = () => {
    setCount(60);
    intervalId.current && clearInterval(intervalId.current);
    intervalId.current = setInterval(() => {
      setCount((old) => old - 1);
    }, 1000);
  };

  const onResend = (e) => {
    if (count) return;
    startCount();
    props.onClick && props.onClick(e);
  };

  return (
    <Button
      style={{ justifyContent: "flex-start", textTransform: "none" }}
      onClick={onResend}
      disabled={!!count || props.isLoading}
    >
      <Trans>lang_click_send_otp_again</Trans>
      {count ? "(" + count + "s)" : ""}
    </Button>
  );
};
export default ResendButton;
