/* eslint-disable react/display-name */
import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@mui/styles";
import dataStorage from "dataStorage";
import { Trans } from "react-i18next";

const useStyles = makeStyles(() => ({
  container: {
    "& .MuiDialog-paper": {
      minWidth: 400,
    },
  },
}));

const ConfirmModal = () => {
  const [option, setOption] = React.useState({
    open: false,
    title: "",
    content: "",
    cancelText: "lang_close",
    confirmText: "lang_confirm",
  });
  const confirmCb = React.useRef();
  const cancelCb = React.useRef();
  const classes = useStyles();

  /**
   * save showModal method to global storage to use in another file
   */
  React.useEffect(() => {
    const showModal = (params) => {
      const { callback, cancelCallback, ...options } = params;
      if (callback) confirmCb.current = callback;
      if (cancelCallback) cancelCb.current = cancelCallback;
      setOption(() => ({ ...(options || {}), open: true }));
    };
    dataStorage.showModal = showModal;
  }, []);

  /**
   * Handle close modal
   * @param {Event} e Click event, but not use
   * @param {string} reason reason for close action
   */
  const handleClose = (e, reason) => {
    if (reason === "backdropClick") return;
    cancelCb.current?.();
    confirmCb.current = null;
    cancelCb.current = null;
    setOption((old) => ({ ...old, open: false }));
  };

  /**
   * Handle confirm action
   */
  const onConfirm = () => {
    confirmCb.current?.();
    confirmCb.current = null;
    cancelCb.current = null;
    setOption((old) => ({ ...old, open: false }));
  };

  /**
   * Render modal by custom component
   * @returns HTML to render modal
   */
  const renderModal = () => {
    if (option.component) {
      const Component = option.component;
      return <Component {...option.props} close={handleClose} />;
    } else {
      return (
        <React.Fragment>
          {option?.title ? (
            <DialogTitle id="alert-dialog-title">
              <Trans>{option.title}</Trans>
            </DialogTitle>
          ) : (
            <React.Fragment />
          )}
          {option?.content ? (
            <DialogContent>
              {typeof option.content === "string" ? (
                <DialogContentText id="alert-dialog-description">
                  <Trans {...(option?.transProps || {})}>
                    {option.content}
                  </Trans>
                </DialogContentText>
              ) : (
                option.content
              )}
            </DialogContent>
          ) : (
            <React.Fragment />
          )}
          <DialogActions>
            {cancelCb.current ? (
              <Button onClick={handleClose} variant="text">
                <Trans>{option.cancelText || "lang_close"}</Trans>
              </Button>
            ) : (
              <React.Fragment />
            )}
            <Button
              onClick={onConfirm}
              autoFocus
              variant="contained"
              color="primary"
            >
              <Trans>{option.confirmText || "lang_ok"}</Trans>
            </Button>
          </DialogActions>
        </React.Fragment>
      );
    }
  };

  return (
    <Dialog
      open={option?.open}
      onClose={handleClose}
      className={classes.container}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {renderModal()}
    </Dialog>
  );
};

export default ConfirmModal;
