import { TAX_ID_REASON } from "constants/Options/options";

// common constants

export const EMPTY_TAX_RESIDENCY = {
  tax_country: "CHN",
  is_there_taxid: true,
  tax_id: "",
  taxid_reason: TAX_ID_REASON.REASON_A,
  taxid_reason_desc: "",
};

export const ACCOUNT_STATUS = {
  APPROVED: "APPROVED",
  IN_PROGRESS: "IN_PROGRESS",
  PENDING: "PENDING",
  PENDING_APPROVE: "PENDING_APPROVAL",
  MORE_INFO: "MORE_INFO",
};
