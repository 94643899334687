import React from "react";
import { Navigate } from "react-router-dom";
import { LoginContext } from "App.js";

const ProtectedRoute = ({ children }) => {
  const { isLogin } = React.useContext(LoginContext);

  if (isLogin) return children;

  return <Navigate to={`/login`} />;
};

export default ProtectedRoute;
