import dataStorage from "dataStorage";
import { getSessionUrl } from "api/api";
import { getData } from "api/request";
import { v4 as uuidv4 } from "uuid";

export default function getSession() {
  const { key, time_expire } = dataStorage.session;
  //  het han session
  if (!key || time_expire < Date.now()) {
    const sessionId = uuidv4();
    dataStorage.session.sessionId = sessionId;
    return getData(getSessionUrl(sessionId));
  }

  return new Promise((resolve) => {
    resolve(dataStorage.session);
  });
}
