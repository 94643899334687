import React from "react";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import Logo from "components/Logo";
import { makeStyles } from "@mui/styles";
import Onboarding from "containers/Onboarding/Onboarding";
import ServicesAgreement from "containers/ServicesAgreement/ServicesAgreement";
import ChooseBankLocation from "containers/ChooseBankLocation/ChooseBankLocation";
import { useGetDraft } from "hooks/useDraft";
import { useTransaction } from "hooks/useKYC";
import dataStorage from "dataStorage";
import { ACCOUNT_STATUS } from "constants/constants";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    width: "100vw",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down(808 + theme.spacing(3) * 2)]: {
      paddingTop: theme.spacing(2),
    },
  },
  modalContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "calc(100vw - 64px)",
    marginTop: theme.spacing(4),
    padding: theme.spacing(3),
    [theme.breakpoints.down(600)]: {
      padding: theme.spacing(2),
      width: "calc(100vw - 32px)",
    },
    [theme.breakpoints.up(1200)]: {
      padding: theme.spacing(5),
      width: "calc(100vw - 128px)",
    },
    [theme.breakpoints.up(1500)]: {
      padding: theme.spacing(5),
      width: "calc(100vw - 256px)",
    },
  },
}));

const OnboardingPage = () => {
  const classes = useStyles();
  const { isLoading, data } = useGetDraft({
    enabled: dataStorage.accountStatus !== ACCOUNT_STATUS.IN_PROGRESS,
  });
  const { isLoading: isLoadVerify, data: transaction } = useTransaction({
    enabled: dataStorage.accountStatus !== ACCOUNT_STATUS.IN_PROGRESS,
  });

  const currentStatus = React.useMemo(() => {
    let result = {};
    if (transaction?.kyc_results) {
      result = JSON.parse(transaction.kyc_results);
      result = result.reduce((acc, cur) => {
        acc[cur.category] = cur;
        return acc;
      }, {});
    }
    const isPassDocument = result?.DOCUMENT?.decision === "PASSED";
    const isPassLiveness = result?.LIVENESS?.decision === "PASSED";
    const isPassPhotoMatch = result?.PHOTO_MATCH?.decision === "PASSED";
    const isPassKyc = isPassDocument && isPassLiveness && isPassPhotoMatch;
    if (!isPassKyc && isPassDocument) {
      return {
        kycUrl: transaction.workflow6_web_url,
        step: 1,
      };
    }
    return {
      step: isPassKyc ? 2 : 0,
    };
  }, [transaction]);

  return (
    <div className={classes.container}>
      <Logo />
      <Paper elevation={15} className={classes.modalContainer}>
        {dataStorage.accountStatus === ACCOUNT_STATUS.IN_PROGRESS ? (
          dataStorage.isOverSeas ? (
            <Onboarding step={9} />
          ) : (
            <Onboarding step={8} />
          )
        ) : isLoading || isLoadVerify ? (
          <CircularProgress />
        ) : data && Object.keys(data).length ? (
          <ChooseBankLocation data={data} {...currentStatus} />
        ) : (
          <ServicesAgreement />
        )}
      </Paper>
    </div>
  );
};

export default OnboardingPage;
