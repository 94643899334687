import React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { makeStyles } from "@mui/styles";
import dataStorage from "dataStorage";
import { Trans } from "react-i18next";

const useStyles = makeStyles((theme) => {
  return {
    stepperVertical: {
      display: "block !important",
      width: "27%",
      maxWidth: 300,
      marginRight: theme.spacing(2),
      "& .MuiStepLabel-label": {
        fontSize: 24,
      },
      [theme.breakpoints.down("sm")]: {
        display: "none !important",
      },
      [theme.breakpoints.up("xs")]: {
        marginRight: theme.spacing(3),
      },
      [theme.breakpoints.up("sm")]: {
        marginRight: theme.spacing(4),
      },
      [theme.breakpoints.up("md")]: {
        marginRight: theme.spacing(6),
      },
      [theme.breakpoints.up("lg")]: {
        marginRight: theme.spacing(8),
      },
      [theme.breakpoints.up("xl")]: {
        marginRight: theme.spacing(12),
      },
      "& .MuiStepConnector-lineVertical": {
        minHeight: 40,
      },
      "& .MuiStep-root": {
        "& *": {
          cursor: "default",
        },
      },
      "& .Mui-active": {
        color: "#D5A34D",
        "& .css-1d8nm1y-MuiStepIcon-text": {
          color: "#121212",
          fill: "#121212",
        },
      },
      "& .Mui-completed": { color: "#D5A34D", fill: "#D5A34D" },
    },
    stepperHorizontal: {
      display: "none !important",
      "& .MuiStepLabel-label": {
        fontSize: 24,
      },
      padding: theme.spacing(2, 0),
      [theme.breakpoints.between(0, "sm")]: {
        display: "flex !important",
      },
      [theme.breakpoints.down(400)]: {
        "& .MuiStepIcon-root": {
          width: "0.8em",
          height: "0.8em",
        },
      },
      [theme.breakpoints.down("sm")]: {
        "& .MuiStepLabel-labelContainer": {
          display: "none !important",
        },
        "& .MuiStepLabel-iconContainer": {
          paddingRight: 0,
        },
      },
      "& .MuiStep-root": {
        "& *": {
          cursor: "default",
        },
      },
      "& .Mui-active": {
        color: "#D5A34D",
        "& .css-1d8nm1y-MuiStepIcon-text": {
          color: "#121212",
          fill: "#121212",
        },
      },
      "& .Mui-completed": { color: "#D5A34D", fill: "#D5A34D" },
    },
  };
});

const Steps = ({ steps, activeStep, onStepClick }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Stepper
        orientation="vertical"
        activeStep={activeStep}
        className={classes.stepperVertical}
      >
        {steps.map((e, i) => (
          <Step
            key={`${e}vertical`}
            onClick={() => {
              if (dataStorage.accountStatus) return;
              onStepClick(i);
            }}
            className={i === activeStep ? "stepActive" : ""}
          >
            <StepLabel>
              <Trans>{e}</Trans>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <Stepper activeStep={activeStep} className={classes.stepperHorizontal}>
        {steps.map((e, i) => (
          <Step
            key={`${e}horizon`}
            onClick={() => {
              if (dataStorage.accountStatus) return;
              onStepClick(i);
            }}
            className={i === activeStep ? "stepActive" : ""}
          >
            <StepLabel>
              <Trans>{e}</Trans>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </React.Fragment>
  );
};

export default Steps;
