import React from "react";
import SignaturePad from "react-signature-canvas";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { Trans } from "react-i18next";
import classnames from "classnames";
import dataStorage from "dataStorage";

const useStyles = makeStyles((theme) => ({
  container: {},
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
    "& button": {
      color: `${theme.palette.common.white} !important`,
      background: "#D5A34D",
      "&~button": {
        marginLeft: theme.spacing(2),
      },
    },
  },
  signContainer: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    boxSizing: "border-box",
    alignItems: "flex-end",
    justifyContent: "center",
    width: "100%",
    minHeight: 200,
    // maxHeight: 500,
    maxWidth: 1000,
  },
  signEmptyContainer: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    boxSizing: "border-box",
    justifyContent: "center",
    width: "100%",
    minHeight: 200,
    maxHeight: 500,
    maxWidth: 1000,
    background: theme.palette.boxBg,
    "& svg": {
      width: 32,
      height: 32,
      marginRight: 8,
    },
    cursor: "pointer",
    alignItems: "center",
  },
  error: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    borderWidth: 1,
    borderStyle: "solid",
  },
  sigPad: {
    width: "calc(100vw - 32px)",
    maxWidth: 1000,
    background: theme.palette.common.white,
    height: "50vw",
    maxHeight: 500,
    border: "solid black",
    borderWidth: 1,
  },
  sigImage: {
    width: "100%",
    // background: theme.palette.boxBg,
    background: "#E0E0E0",
    padding: theme.spacing(2),
    objectFit: "scale-down",
  },
  modalContentContainer: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const Signature = ({ error, onChange }) => {
  const classes = useStyles();
  const [sign, setSign] = React.useState(dataStorage.esignature || "");
  const [open, setOpen] = React.useState(false);
  const sigPad = React.useRef();

  /**
   * Clear the signature
   */
  const onClear = () => {
    sigPad.current.clear();
  };

  /**
   * Submit the signature and get preview image
   */
  const onDone = () => {
    if (sigPad.current.isEmpty()) {
      setOpen(false);
    } else {
      const signImage = sigPad.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      setSign(signImage);
      dataStorage.esignature = signImage;
      setOpen(false);
    }
  };

  React.useEffect(() => {
    onChange?.(sign);
  }, [sign]);

  /**
   * Open blank singature pad
   */
  const onResign = () => setOpen(true);

  /**
   * Render button clear and comfirm signature
   * @returns HTML to render button
   */
  const renderButtons = () => {
    return (
      <div className={classes.rowContainer}>
        <Button variant="outlined" onClick={onClear}>
          <Trans>lang_clear</Trans>
        </Button>
        <Button variant="outlined" onClick={onDone}>
          <Trans>lang_confirm</Trans>
        </Button>
      </div>
    );
  };

  const closeBTnColor = dataStorage.theme === "light" ? "black" : "white";
  const filter = dataStorage.theme === "light" ? "none" : "invert(1)";
  return (
    <div className={classes.container}>
      {sign ? (
        <Box className={classes.signContainer}>
          <img
            className={classnames(classes.sigImage, {
              [classes.error]: !!error,
            })}
            alt="signature"
            src={sign}
            style={{ filter: `${filter}` }}
          />
          <Button style={{ textTransform: "none" }} onClick={onResign}>
            <Trans>lang_resign</Trans>
          </Button>
        </Box>
      ) : (
        <Box
          className={classnames(classes.signEmptyContainer, {
            [classes.error]: !!error,
          })}
          onClick={() => setOpen(true)}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <svg viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M9.75 20.85C11.53 20.15 11.14 18.22 10.24 17C9.35 15.75 8.12 14.89 6.88 14.06C6 13.5 5.19 12.8 4.54 12C4.26 11.67 3.69 11.06 4.27 10.94C4.86 10.82 5.88 11.4 6.4 11.62C7.31 12 8.21 12.44 9.05 12.96L10.06 11.26C8.5 10.23 6.5 9.32 4.64 9.05C3.58 8.89 2.46 9.11 2.1 10.26C1.78 11.25 2.29 12.25 2.87 13.03C4.24 14.86 6.37 15.74 7.96 17.32C8.3 17.65 8.71 18.04 8.91 18.5C9.12 18.94 9.07 18.97 8.6 18.97C7.36 18.97 5.81 18 4.8 17.36L3.79 19.06C5.32 20 7.88 21.47 9.75 20.85M18.96 7.33L13.29 13H11V10.71L16.67 5.03L18.96 7.33M22.36 6.55C22.35 6.85 22.04 7.16 21.72 7.47L19.2 10L18.33 9.13L20.93 6.54L20.34 5.95L19.67 6.62L17.38 4.33L19.53 2.18C19.77 1.94 20.16 1.94 20.39 2.18L21.82 3.61C22.06 3.83 22.06 4.23 21.82 4.47C21.61 4.68 21.41 4.88 21.41 5.08C21.39 5.28 21.59 5.5 21.79 5.67C22.08 5.97 22.37 6.25 22.36 6.55Z"
              />
            </svg>
            <Trans>lang_esignature</Trans>
          </div>
        </Box>
      )}
      <Modal
        open={open}
        // onClose={() => setOpen(false)}
      >
        <Box className={classes.modalContentContainer}>
          <div style={{ position: "relative" }}>
            <SignaturePad
              penColor="black"
              canvasProps={{
                className: classes.sigPad,
                style: { filter: `${filter}` },
              }}
              ref={sigPad}
              clearOnResize={false}
            />
            <IconButton
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                color: `${closeBTnColor}`,
              }}
              onClick={() => setOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </div>
          {renderButtons()}
        </Box>
      </Modal>
    </div>
  );
};

export default Signature;
