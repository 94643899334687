export const dicStep = {
  UPLOAD_ID_PHOTO: "lang_upload_id_photo",
  EKYC: "lang_ekyc",
  PERSONAL_INFORMATION: "lang_personal_information",
  EMPLOYMENT_INFORMATION: "lang_employment_information",
  FINANCIAL_INVESTMENT: "lang_financial_and_investment",
  IDENTITY_DISCLOSURES_TAX_INFORMATION:
    "lang_identity_disclosures_and_tax_information",
  ACCOUNT_TYPE: "lang_account_type",
  RISK_DISCLOSURE: "lang_risk_disclosure",
  ESIGNATURE: "lang_esignature",
  DEPOSIT: "lang_make_a_deposit",
};

const Steps = [
  dicStep.UPLOAD_ID_PHOTO,
  dicStep.EKYC,
  dicStep.PERSONAL_INFORMATION,
  dicStep.EMPLOYMENT_INFORMATION,
  dicStep.FINANCIAL_INVESTMENT,
  dicStep.IDENTITY_DISCLOSURES_TAX_INFORMATION,
  dicStep.ACCOUNT_TYPE,
  dicStep.RISK_DISCLOSURE,
  dicStep.ESIGNATURE,
  dicStep.DEPOSIT,
];

export default Steps;
