import React from "react";
import at from "lodash/at";
import { useField } from "formik";
import Signature from "components/Signature";
import FormControl from "@mui/material/FormControl";

const SignatureField = (props) => {
  const { ...rest } = props;
  const [field, meta] = useField(props);
  const [touched, error] = at(meta, "touched", "error");
  const isError = touched && error && true;

  return (
    <FormControl {...rest} error={isError} style={{ width: "100%" }}>
      <Signature
        error={isError}
        value={field.value}
        onChange={props.onChange}
      />
    </FormControl>
  );
};

export default React.memo(SignatureField);
