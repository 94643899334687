import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import dataStorage from "dataStorage";
import { scrollToTop } from "helpers/utils";
import { Trans } from "react-i18next";

const ErrorBoundary = () => {
  const [error, setError] = React.useState({
    msg: "",
    type: "error",
  });

  /**
   * When have error, scroll to top and show translated error
   * @param {object | string} errorObj error object or error message
   * @param {string} type the error type
   */
  const showAlert = (errorObj = "", type = "error") => {
    if (!errorObj) return;
    scrollToTop();
    if (typeof errorObj === "string") {
      setError({ msg: errorObj, type });
      return;
    }
    let msg = errorObj.message;
    const errorCode =
      errorObj?.response?.data?.error_code ||
      errorObj?.response?.data?.errorCode;
    if (errorCode == 854002) {
      dataStorage.showModal({
        title: "lang_notify",
        content: "error_code_854002",
        callback: () => window.location.reload(),
      });
    } else {
      if (errorCode) msg = `error_code_${errorCode}`;
      setError({
        msg,
        type,
      });
    }
  };

  React.useEffect(() => {
    dataStorage.showAlert = showAlert;
  }, []);

  return (
    <Snackbar
      open={!!error.msg}
      onClose={() => setError((old) => ({ ...old, msg: "" }))}
      autoHideDuration={null}
      disableWindowBlurListener={true}
      ClickAwayListenerProps={{
        mouseEvent: "onClick",
        onClickAway: () =>
          setTimeout(() => setError((old) => ({ ...old, msg: "" })), 3000),
      }}
      style={{ zIndex: 9999 }}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert elevation={6} variant="filled" severity={error.type}>
        <Trans>{error.msg}</Trans>
      </Alert>
    </Snackbar>
  );
};
export default ErrorBoundary;
