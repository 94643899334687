import {
  ID_TYPE,
  ACCOUNT_TYPE,
  EMPLOYMENT_STATUS,
  OCCUPATION_ROLE,
  OCCUPATION_INDUSTRY,
  DURATION,
  INVESTMENT_OBJECTIVES,
  ESTIMATED_INVESTMENT_TIME,
  AMOUNT_VALUE,
  RISK_TOLERANCE,
  RESIDENTIAL_STATUS,
  SOURCE_OF_FUND,
  TAX_ID_REASON,
  EDUCATION,
} from "constants/Options/options";
import ListContryFull from "constants/Countries/countries";
import ListContryLimit from "constants/Countries/countriesLimit";
import ListContryTax from "constants/Countries/countriesTax";

const initialValues = {
  applicant_country: "USERS IN OVERSEAS",
  account_status: "",
  applicant_verification_id: {
    applicant_nationality: "",
    id_type: ID_TYPE.PASSPORT,
    // country_of_residence: ListContryFull[0].value,
    country_of_residence: "",
    pass_port: "",
    back_side: "",
    front_side: "",
  },
  personal_information: {
    eng_given_name: "",
    eng_middle_name: "",
    eng_sur_name: "",
    name_in_pinyin: "",
    chinese_name: "",
    gender: "MALE",
    id_number: "",
    dob: "",
    region: "",
    country_of_residence: ListContryFull[0].value,
    house_number: "",
    street_name: "",
    city_name: "",
    province_name: "",
    zip_code: "",
    correspondence_address_document: "",
    mobile_number: "",
    email_address: "",
    education: EDUCATION.PRIMARY_SCHOOL,
    referral_code: "",
  },
  employment_information: {
    employment_status: EMPLOYMENT_STATUS.EMPLOYED,
    employment_status_desc: "",
    employer_name: "",
    occupation_role: OCCUPATION_ROLE.OFFICER,
    occupation_role_desc: "",
    occupation_industry: OCCUPATION_INDUSTRY.INTERNET,
    occupation_industry_desc: "",
    working_duration: DURATION.LESS_THAN_ONE_YEAR,
  },
  personal_financial_information: {
    annual_income: AMOUNT_VALUE.LESS_THAN_200K_HKD,
    net_asset_value: AMOUNT_VALUE.LESS_THAN_25K_HKD,
    housing_status: RESIDENTIAL_STATUS.OWNED,
    housing_status_desc: "",
    source_of_fund: [SOURCE_OF_FUND.SALARY],
    source_of_fund_desc: "",
    liquid_net_worth: AMOUNT_VALUE.LESS_THAN_25K_HKD,
  },
  invesment_exp: {
    stock: DURATION.NO,
    cbbc: DURATION.NO,
    derivatives: DURATION.NO,
    futures: DURATION.NO,
    options_warrants: DURATION.NO,
    forex: DURATION.NO,
    commodity: DURATION.NO,
    other: "",
    other_period: DURATION.NO,
  },
  investment_objectives_and_risk_tolerance: {
    investment_objectives: INVESTMENT_OBJECTIVES.DIVIDEND_INCOME,
    investment_objectives_desc: "",
    investment_time: ESTIMATED_INVESTMENT_TIME.SHORT_TERM,
    investment_volume: AMOUNT_VALUE.LESS_THAN_25K_HKD,
    risk_tolerance: RISK_TOLERANCE.VERY_LOW,
  },
  derivative_awareness: "",
  identity_disclosure: {
    acting_as_intermediary: false,
    registered_securities_futures: false,
    licensed_person_comp_name: "",
    related_to_public_function: false,
    relationship_desc: "",
    related_to_magpie: false,
    comp_officer_name: "",
    comp_officer_relationship: "",
    spouse_margin: false,
    spouse_name: "",
    spouse_account_number: "",
    control_magpie_voting: false,
    client_account_number: "",
  },
  tax_information: [
    {
      tax_country: ListContryTax[0].value,
      is_there_taxid: true,
      tax_id: "",
      taxid_reason: TAX_ID_REASON.REASON_A,
      taxid_reason_desc: "",
    },
  ],
  us_person_info: {
    are_you_us_person: false,
    country_of_citizenship: ListContryLimit[0].value,
    country_of_tax_treaty_benefits: ListContryLimit[0].value,
    taxpayer_id: "",
  },
  account_type: [ACCOUNT_TYPE.GLOBAL_MARGIN_ACCOUNT],
  risk_disclosure: [],
  e_signature: "",
  notify_deposit: {
    deposit_currency: null,
    deposit_amount: null,
  },
  bank_verification: {
    bank_name: null,
    bank_card_number: null,
  },
  live_detection: {
    confidence_level: "",
    human_image: "",
  },
};

export default initialValues;
