import { useEffect } from "react";
import isObject from "lodash/isObject";
import { useFormikContext } from "formik";

/**
 * Get first field have error to can scroll to this field
 * @param {object} object form values
 * @param {Array} keys list path until handling
 * @returns first field error as string
 */
const getFirstErrorKey = (object, keys = []) => {
  const firstErrorKey = Object.keys(object)[0];
  if (isObject(object[firstErrorKey])) {
    return getFirstErrorKey(object[firstErrorKey], [...keys, firstErrorKey]);
  }
  return [...keys, firstErrorKey].join(".");
};

const FormikOnError = ({ children }) => {
  const formik = useFormikContext();

  /**
   * when have error scroll into first field error
   */
  useEffect(() => {
    if (!formik.isValid && formik.isSubmitting) {
      let firstErrorKey = getFirstErrorKey(formik.errors);
      firstErrorKey = firstErrorKey.replace(/\.(\d)\./, "[$1].");
      const el = document.getElementsByName(firstErrorKey);
      if (el.length) {
        el[0].scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
    }
  }, [formik.isValid, formik.isSubmitting, formik.errors]);

  return children;
};

export default FormikOnError;
