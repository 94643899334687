import axios from "axios";

/**
 * Support get data by url use axios
 * @param {string} url api link
 * @param {object} config config for axios
 * @returns a promise
 */
export const getData = (url, config = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, config)
      .then((response) => {
        resolve(response?.data || response);
      })
      .catch((error) => {
        // handle error code here
        reject(error);
      });
  });
};

/**
 * Support post data to server by url use axios
 * @param {string} url api link
 * @param {object} data to post to server
 * @returns a promise
 */
export const postData = (url, data, config = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, config)
      .then((response) => {
        resolve(response?.data || response);
      })
      .catch((error) => {
        // handle error code here
        reject(error);
      });
  });
};
