import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Trans } from "react-i18next";

const MakeADeposit = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">
          <Trans>lang_submitted_title</Trans>
        </Typography>
        <Typography variant="body2" sx={{ mt: 0.5 }}>
          <Trans>lang_submitted_subtitle</Trans>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" color={"#D5A34D"}>
          1: <Trans>lang_select_a_currency</Trans>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" color={"#D5A34D"}>
          2: <Trans>lang_online_transfer</Trans>
        </Typography>
        <Typography variant="body2" sx={{ mt: 0.5 }}>
          <Trans>lang_online_transfer_tip</Trans>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default MakeADeposit;
