import React from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles } from "@mui/styles";
import { Trans } from "react-i18next";
import classnames from "classnames";
import dataStorage from "dataStorage";
import i18n from "i18next";
import ListPhoneCountry from "constants/Countries/countriesPhone";

const useStyle = makeStyles(() => ({
  container: {
    "& .MuiAutocomplete-root": {
      "& fieldset": {
        display: "none",
      },
      "& .MuiAutocomplete-endAdornment": {
        top: "calc(50% - 16px)",
        right: 4,
      },
    },
    "& .MuiOutlinedInput-root.MuiInputBase-adornedStart": {
      padding: 0,
    },
  },
  inputError: {
    // '& input': {
    //     '&::placeholder': {
    //         color: theme.palette.error.main
    //     }
    // }
  },
}));

const PhoneCountry = ({
  error,
  label,
  phoneValue,
  maxLength = 20,
  onChange,
  ...rest
}) => {
  const classes = useStyle();
  const [phone, setPhone] = React.useState(
    phoneValue ||
      dataStorage.defaultCountry || {
        code: "HK",
        value: "HKG",
        label: "Hong Kong, SAR, China",
        phone: "852",
      }
  );
  const [options, setOption] = React.useState(
    ListPhoneCountry.map((e) => ({
      ...e,
      label: dataStorage.translate(e.label),
    }))
  );

  React.useEffect(() => {
    i18n.on("languageChanged", function () {
      setOption(
        ListPhoneCountry.map((e) => ({
          ...e,
          label: dataStorage.translate(e.label),
        }))
      );
    });
  }, []);

  /**
   * Update phone number value
   * @param {Event} event input change event
   */
  const onChangePhoneNumber = (event) => {
    if (
      event.target.value === "" ||
      (event.target.value && /^[0-9]*$/.test(event.target.value))
    ) {
      const newState = { ...phone, ...{ phoneNumber: event.target.value } };
      onChange?.(newState);
      setPhone(newState);
    }
  };

  /**
   * Update phone country value
   * @param {Event} event autocomplete change event
   * @param {object} option selected option
   * @param {string} reason select action
   */
  const onChangeCountry = (event, option, reason) => {
    if (reason === "selectOption") {
      const newState = { ...phone, ...option };
      onChange?.(newState);
      setPhone(newState);
    }
  };

  /**
   * Create custom filter function
   * @param {Array} options list option for autocomplete
   * @param {string} inputValue text search
   * @returns list new option by text search
   */
  const filterOptionsCustom = (options, { inputValue }) => {
    if (!inputValue) return options;
    return options.filter(
      (e) =>
        e.phone.includes(inputValue.trim()) ||
        e.label.toLowerCase().includes(inputValue.toLowerCase().trim())
    );
  };

  /**
   * Render list country, user can search
   * @returns HTML to render list countries
   */
  const renderCountrySelect = () => {
    return (
      <Autocomplete
        openText={dataStorage.translate("lang_open")}
        closeText={dataStorage.translate("lang_close")}
        noOptionsText={dataStorage.translate("lang_no_options")}
        required={true}
        onChange={onChangeCountry}
        value={phone || null}
        options={options}
        isOptionEqualToValue={(option, select) => {
          return select && option.value === select.value;
        }}
        getOptionLabel={(option) => {
          return `+${option.phone}`;
        }}
        filterOptions={filterOptionsCustom}
        disableClearable
        renderOption={(props, option) => {
          return (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
              key={`${option.value} +${option.phone}`}
            >
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt=""
              />
              {`${option.label} (+${option.phone})`}
            </Box>
          );
        }}
        renderInput={(params) => {
          return (
            <React.Fragment>
              <TextField
                name={"password"}
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password",
                  form: {
                    autocomplete: "off",
                  },
                  style: {
                    width: phone.phone?.length
                      ? phone.phone.length * 10 + 13
                      : 56,
                    marginRight: -8,
                  },
                }}
                required={true}
                variant="outlined"
                type="text"
              />
            </React.Fragment>
          );
        }}
      />
    );
  };

  return (
    <TextField
      {...rest}
      error={error}
      className={classnames(classes.container, {
        [classes.inputError]: !!error,
      })}
      required
      variant={"outlined"}
      placeholder={dataStorage.translate("lang_phone_number")}
      type="tel"
      label={label ? <Trans>{label}</Trans> : ""}
      value={phone.phoneNumber}
      onChange={onChangePhoneNumber}
      inputProps={{ maxLength }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            {renderCountrySelect()}
          </InputAdornment>
        ),
      }}
      fullWidth
    />
  );
};

export default PhoneCountry;
