/* eslint-disable max-lines */

import {
  EMPLOYMENT_STATUS,
  OCCUPATION_ROLE,
  OCCUPATION_INDUSTRY,
  RESIDENTIAL_STATUS,
  SOURCE_OF_FUND,
  INVESTMENT_OBJECTIVES,
  TAX_ID_REASON,
} from "constants/Options/options";

const formModels = {
  formId: "onboarding",
  formField: {
    applicant_country: {
      name: "applicant_country",
      label: "lang_country",
    },
    applicant_verification_id: {
      name: "applicant_verification_id",
      applicant_nationality: {
        originName: "applicant_nationality",
        name: "applicant_verification_id.applicant_nationality",
        label: "lang_nationality",
      },
      id_type: {
        originName: "id_type",
        name: "applicant_verification_id.id_type",
        label: "lang_type_of_id",
      },
      country_of_residence: {
        originName: "country_of_residence",
        name: "applicant_verification_id.country_of_residence",
        label: "lang_country_of_residence",
      },
      pass_port: {
        originName: "pass_port",
        name: "applicant_verification_id.pass_port",
        label: "lang_passport_photo",
      },
      front_side: {
        originName: "front_side",
        name: "applicant_verification_id.front_side",
        label: "lang_front_side",
      },
      back_side: {
        originName: "back_side",
        name: "applicant_verification_id.back_side",
        label: "lang_back_side",
      },
    },
    live_detection: {
      name: "live_detection",
      confidence_level: {
        originName: "confidence_level",
        name: "live_detection.confidence_level",
        label: "lang_confidence_level",
      },
      human_image: {
        originName: "human_image",
        name: "live_detection.human_image",
        label: "lang_human_image",
      },
    },
    personal_information: {
      name: "personal_information",
      eng_given_name: {
        originName: "eng_given_name",
        name: "personal_information.eng_given_name",
        label: "lang_given_name",
      },
      eng_middle_name: {
        originName: "eng_middle_name",
        name: "personal_information.eng_middle_name",
        placeholder: "lang_middle_other_name",
        label: "lang_middle_name",
      },
      eng_sur_name: {
        originName: "eng_sur_name",
        name: "personal_information.eng_sur_name",
        placeholder: "lang_surname",
        label: "lang_surname",
      },
      name_in_pinyin: {
        originName: "name_in_pinyin",
        name: "personal_information.name_in_pinyin",
        placeholder: "lang_if_applicable",
        label: "lang_chinese_name",
      },
      chinese_name: {
        originName: "chinese_name",
        name: "personal_information.chinese_name",
        placeholder: "lang_if_applicable",
        label: "lang_chinese_name",
      },
      gender: {
        originName: "gender",
        name: "personal_information.gender",
        label: "lang_gender",
      },
      id_number: {
        originName: "id_number",
        name: "personal_information.id_number",
        label: "lang_id_number",
      },
      dob: {
        originName: "dob",
        name: "personal_information.dob",
        label: "lang_date_of_birth",
      },
      region: {
        originName: "region",
        name: "personal_information.region",
        label: "lang_place_of_birth",
      },
      country_of_residence: {
        originName: "country_of_residence",
        name: "personal_information.country_of_residence",
        label: "lang_country_of_residence",
      },
      house_number: {
        originName: "house_number",
        name: "personal_information.house_number",
        placeholder: "lang_house_flat_unit",
        label: "lang_house_number",
      },
      street_name: {
        originName: "street_name",
        name: "personal_information.street_name",
        placeholder: "lang_street_no_name_building",
        label: "lang_street_name",
      },
      city_name: {
        originName: "city_name",
        name: "personal_information.city_name",
        placeholder: "lang_town_city",
        label: "lang_city_name",
      },
      province_name: {
        originName: "province_name",
        name: "personal_information.province_name",
        placeholder: "lang_province_state_city",
        label: "lang_province_name",
      },
      zip_code: {
        originName: "zip_code",
        name: "personal_information.zip_code",
        placeholder: "lang_zip_postcode",
        label: "lang_zipcode",
      },
      correspondence_address_document: {
        originName: "correspondence_address_document",
        name: "personal_information.correspondence_address_document",
        label: "lang_upload_address_photo",
      },
      mobile_number: {
        originName: "mobile_number",
        name: "personal_information.mobile_number",
        label: "lang_phone_number",
      },
      email_address: {
        originName: "email_address",
        name: "personal_information.email_address",
        placeholder: "lang_please_enter",
        label: "lang_email",
      },
      education: {
        originName: "education",
        name: "personal_information.education",
        label: "lang_highest_level_of_education",
      },
    },
    employment_information: {
      name: "employment_information",
      employment_status: {
        originName: "employment_status",
        name: "employment_information.employment_status",
        label: "lang_employment_status",
      },
      employment_status_desc: {
        originName: "employment_status_desc",
        name: "employment_information.employment_status_desc",
        label: "lang_employment_status_description",
        depend: {
          "employment_information.employment_status": [EMPLOYMENT_STATUS.OTHER],
        },
      },
      employer_name: {
        originName: "employer_name",
        name: "employment_information.employer_name",
        label: "lang_employer",
        depend: {
          "employment_information.employment_status": [
            EMPLOYMENT_STATUS.EMPLOYED,
            EMPLOYMENT_STATUS.SELF_EMPLOYED,
            EMPLOYMENT_STATUS.OTHER,
          ],
        },
      },
      occupation_role: {
        originName: "occupation_role",
        name: "employment_information.occupation_role",
        label: "lang_occupation",
        depend: {
          "employment_information.employment_status": [
            EMPLOYMENT_STATUS.EMPLOYED,
            EMPLOYMENT_STATUS.SELF_EMPLOYED,
            EMPLOYMENT_STATUS.OTHER,
          ],
        },
      },
      occupation_role_desc: {
        originName: "occupation_role_desc",
        name: "employment_information.occupation_role_desc",
        label: "lang_occupation_description",
        depend: {
          "employment_information.occupation_role": [OCCUPATION_ROLE.OTHER],
          "employment_information.employment_status": [
            EMPLOYMENT_STATUS.EMPLOYED,
            EMPLOYMENT_STATUS.SELF_EMPLOYED,
            EMPLOYMENT_STATUS.OTHER,
          ],
        },
      },
      occupation_industry: {
        originName: "occupation_industry",
        name: "employment_information.occupation_industry",
        label: "lang_industry",
        depend: {
          "employment_information.employment_status": [
            EMPLOYMENT_STATUS.EMPLOYED,
            EMPLOYMENT_STATUS.SELF_EMPLOYED,
            EMPLOYMENT_STATUS.OTHER,
          ],
        },
      },
      occupation_industry_desc: {
        originName: "occupation_industry_desc",
        name: "employment_information.occupation_industry_desc",
        label: "lang_industry_description",
        depend: {
          "employment_information.occupation_industry": [
            OCCUPATION_INDUSTRY.OTHERS,
          ],
          "employment_information.employment_status": [
            EMPLOYMENT_STATUS.EMPLOYED,
            EMPLOYMENT_STATUS.SELF_EMPLOYED,
            EMPLOYMENT_STATUS.OTHER,
          ],
        },
      },
      working_duration: {
        originName: "working_duration",
        name: "employment_information.working_duration",
        label: "lang_years_with_current_employer",
        depend: {
          "employment_information.employment_status": [
            EMPLOYMENT_STATUS.EMPLOYED,
            EMPLOYMENT_STATUS.SELF_EMPLOYED,
            EMPLOYMENT_STATUS.OTHER,
          ],
        },
      },
    },
    personal_financial_information: {
      name: "personal_financial_information",
      annual_income: {
        originName: "annual_income",
        name: "personal_financial_information.annual_income",
        label: "lang_annual_incode",
      },
      net_asset_value: {
        originName: "net_asset_value",
        name: "personal_financial_information.net_asset_value",
        label: "lang_total_net_worth",
      },
      housing_status: {
        originName: "housing_status",
        name: "personal_financial_information.housing_status",
        label: "lang_residential_status",
      },
      housing_status_desc: {
        originName: "housing_status_desc",
        name: "personal_financial_information.housing_status_desc",
        label: "lang_residential_status_description",
        depend: {
          "personal_financial_information.housing_status": [
            RESIDENTIAL_STATUS.OTHER,
          ],
        },
      },
      source_of_fund: {
        originName: "source_of_fund",
        name: "personal_financial_information.source_of_fund",
        label: "lang_source_of_fund",
      },
      source_of_fund_desc: {
        originName: "source_of_fund_desc",
        name: "personal_financial_information.source_of_fund_desc",
        label: "lang_source_of_fund_description",
        depend: {
          "personal_financial_information.source_of_fund": [
            SOURCE_OF_FUND.OTHER,
          ],
        },
      },
      liquid_net_worth: {
        originName: "liquid_net_worth",
        name: "personal_financial_information.liquid_net_worth",
        label: "lang_liquid_assets",
      },
    },
    invesment_exp: {
      name: "invesment_exp",
      stock: {
        originName: "stock",
        name: "invesment_exp.stock",
        label: "lang_shares",
      },
      cbbc: {
        originName: "cbbc",
        name: "invesment_exp.cbbc",
        label: "lang_cbbc",
      },
      derivatives: {
        originName: "derivatives",
        name: "invesment_exp.derivatives",
        label: "lang_warrants",
      },
      futures: {
        originName: "futures",
        name: "invesment_exp.futures",
        label: "lang_futures",
      },
      options_warrants: {
        originName: "options_warrants",
        name: "invesment_exp.options_warrants",
        label: "lang_options",
      },
      forex: {
        originName: "forex",
        name: "invesment_exp.forex",
        label: "lang_forex",
      },
      commodity: {
        originName: "commodity",
        name: "invesment_exp.commodity",
        label: "lang_commodities",
      },
      other: {
        originName: "other",
        name: "invesment_exp.other",
        label: "lang_others",
      },
      other_period: {
        originName: "other_period",
        name: "invesment_exp.other_period",
        label: "lang_choose_investment_period",
      },
    },
    investment_objectives_and_risk_tolerance: {
      name: "investment_objectives_and_risk_tolerance",
      investment_objectives: {
        originName: "investment_objectives",
        name: "investment_objectives_and_risk_tolerance.investment_objectives",
        label: "lang_investment_objectives",
      },
      investment_objectives_desc: {
        originName: "investment_objectives_desc",
        name: "investment_objectives_and_risk_tolerance.investment_objectives_desc",
        label: "lang_investment_objectives_description",
        depend: {
          "investment_objectives_and_risk_tolerance.investment_objectives": [
            INVESTMENT_OBJECTIVES.OTHER,
          ],
        },
      },
      investment_time: {
        originName: "investment_time",
        name: "investment_objectives_and_risk_tolerance.investment_time",
        label: "lang_estimated_investment_time",
      },
      investment_volume: {
        originName: "investment_volume",
        name: "investment_objectives_and_risk_tolerance.investment_volume",
        label: "lang_estimated_investment_amount",
      },
      risk_tolerance: {
        originName: "risk_tolerance",
        name: "investment_objectives_and_risk_tolerance.risk_tolerance",
        label: "lang_risk_tolerance",
      },
    },
    derivative_awareness: {
      name: "derivative_awareness",
      label: "lang_derivatives_awareness_assessment",
    },
    identity_disclosure: {
      name: "identity_disclosure",
      acting_as_intermediary: {
        originName: "acting_as_intermediary",
        name: "identity_disclosure.acting_as_intermediary",
        label: "lang_identity_first_question",
      },
      registered_securities_futures: {
        originName: "registered_securities_futures",
        name: "identity_disclosure.registered_securities_futures",
        label: "lang_identity_second_question",
      },
      licensed_person_comp_name: {
        originName: "licensed_person_comp_name",
        name: "identity_disclosure.licensed_person_comp_name",
        label: "lang_name_of_the_licensed",
        depend: {
          "identity_disclosure.registered_securities_futures": [true],
        },
      },
      upload_consent_letter: {
        originName: "upload_consent_letter",
        name: "identity_disclosure.upload_consent_letter",
        label: "lang_upload_consent_letter",
        depend: {
          "identity_disclosure.registered_securities_futures": [true],
        },
      },
      related_to_public_function: {
        originName: "related_to_public_function",
        name: "identity_disclosure.related_to_public_function",
        label: "lang_identity_third_question",
      },
      relationship_desc: {
        originName: "relationship_desc",
        name: "identity_disclosure.relationship_desc",
        label: "lang_identity_third_question_required",
        depend: {
          "identity_disclosure.related_to_public_function": [true],
        },
      },
      related_to_magpie: {
        originName: "related_to_magpie",
        name: "identity_disclosure.related_to_magpie",
        label: "lang_identity_fourth_question",
      },
      comp_officer_name: {
        originName: "comp_officer_name",
        name: "identity_disclosure.comp_officer_name",
        label: "lang_name_of_employee_director",
        depend: {
          "identity_disclosure.related_to_magpie": [true],
        },
      },
      comp_officer_relationship: {
        originName: "comp_officer_relationship",
        name: "identity_disclosure.comp_officer_relationship",
        label: "lang_relationship",
        depend: {
          "identity_disclosure.related_to_magpie": [true],
        },
      },
      spouse_margin: {
        originName: "spouse_margin",
        name: "identity_disclosure.spouse_margin",
        label: "lang_identity_fifth_question",
      },
      spouse_name: {
        originName: "spouse_name",
        name: "identity_disclosure.spouse_name",
        label: "lang_name_of_the_spouse",
        depend: {
          "identity_disclosure.spouse_margin": [true],
        },
      },
      spouse_account_number: {
        originName: "spouse_account_number",
        name: "identity_disclosure.spouse_account_number",
        label: "lang_account_no",
        depend: {
          "identity_disclosure.spouse_margin": [true],
        },
      },
      control_magpie_voting: {
        originName: "control_magpie_voting",
        name: "identity_disclosure.control_magpie_voting",
        label: "lang_identity_sixth_question",
      },
      client_name: {
        originName: "client_name",
        name: "identity_disclosure.client_name",
        label: "lang_name_of_the_controlled_client",
        depend: {
          "identity_disclosure.control_magpie_voting": [true],
        },
      },
      client_account_number: {
        originName: "client_account_number",
        name: "identity_disclosure.client_account_number",
        label: "lang_account_no",
        depend: {
          "identity_disclosure.control_magpie_voting": [true],
        },
      },
    },
    tax_information: {
      name: "tax_information",
      tax_country: {
        originName: "tax_country",
        name: "identity_disclosure.tax_country",
        label: "lang_country_region",
      },
      is_there_taxid: {
        originName: "is_there_taxid",
        name: "identity_disclosure.is_there_taxid",
        label: "lang_is_there_a_tax_id",
      },
      tax_id: {
        originName: "tax_id",
        name: "identity_disclosure.tax_id",
        label: "lang_tax_id",
        depend: {
          "tax_information[index].is_there_taxid": [true],
        },
      },
      taxid_reason: {
        originName: "taxid_reason",
        name: "identity_disclosure.taxid_reason",
        label: "lang_reason",
        depend: {
          "tax_information[index].is_there_taxid": [false],
        },
      },
      taxid_reason_desc: {
        originName: "taxid_reason_desc",
        name: "identity_disclosure.taxid_reason_desc",
        label: "lang_reason_why",
        depend: {
          "tax_information[index].is_there_taxid": [false],
          "tax_information[index].taxid_reason": [TAX_ID_REASON.REASON_B],
        },
      },
    },
    us_person_info: {
      name: "us_person_info",
      are_you_us_person: {
        originName: "are_you_us_person",
        name: "us_person_info.are_you_us_person",
        label: "lang_are_you_us_person",
      },
      country_of_citizenship: {
        originName: "country_of_citizenship",
        name: "us_person_info.country_of_citizenship",
        label: "lang_country_of_citizenship",
        depend: {
          "us_person_info.are_you_us_person": [false],
        },
      },
      country_of_tax_treaty_benefits: {
        originName: "country_of_tax_treaty_benefits",
        name: "us_person_info.country_of_tax_treaty_benefits",
        label: "lang_country_of_tax_treaty_benefits",
        depend: {
          "us_person_info.are_you_us_person": [false],
        },
      },
      taxpayer_id: {
        originName: "taxpayer_id",
        name: "us_person_info.taxpayer_id",
        label: "lang_tax_payer_id",
        depend: {
          "us_person_info.are_you_us_person": [true],
        },
      },
    },
    account_type: {
      name: "account_type",
      label: "lang_type_of_margin_account",
    },
    risk_disclosure: {
      name: "risk_disclosure",
      label: "lang_risk_disclosure_tip",
    },
    e_signature: {
      name: "e_signature",
      label: "lang_signature",
    },
  },
};

export default formModels;
