import React from "react";
import Grid from "@mui/material/Grid";
import formModel from "containers/Onboarding/FormModels/Models";
import { useFormikContext } from "formik";
import { MultipleSelectField } from "components/CustomFields";
import {
  risk_disclosure_OPTIONS,
  risk_disclosure_OPTION_9,
  risk_disclosure_OPTION_9_US,
} from "constants/Options/options";

const { formField } = formModel;

const RiskDisclosureAndDeclarationSignature = () => {
  const { values } = useFormikContext();

  const options = React.useMemo(() => {
    const init = risk_disclosure_OPTIONS;
    if (values.us_person_info.are_you_us_person) {
      init[8] = risk_disclosure_OPTION_9_US;
    } else {
      init[8] = risk_disclosure_OPTION_9;
    }
    return init;
  }, [values.us_person_info.are_you_us_person]);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <MultipleSelectField
          name={formField.risk_disclosure.name}
          label={formField.risk_disclosure.label}
          data={options}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default RiskDisclosureAndDeclarationSignature;
