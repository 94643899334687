import moment from "moment";
import * as Yup from "yup";
import dataStorage from "dataStorage";

export const VALIDATE_ENUM = {
  PHONE: {
    REGEX: /^[0-9]{6,16}$/,
    ERROR: "Phone is invalid",
  },
  EMAIL: {
    REGEX:
      /^([a-z0-9]+[_+.-])*[a-z0-9]+@(([a-z0-9]+-)*([a-z0-9]+)\.)+[a-z]{2,}$/i,
    ERROR: "Email is invalid",
  },
  NAME_ON_CARD: {
    REGEX: /^[A-Za-z0-9\s'-]*$/,
    ERROR: "Name on Card is invalid",
  },
};

Yup.addMethod(
  Yup.mixed,
  "checkPhone",
  function (message = "lang_mobile_phone_invalid") {
    return this.test("phone", message, function (value) {
      if (value) {
        const phoneCode = value.split("|")[0];
        const phoneNumber = value.split("|")[1];
        const phone = phoneNumber || phoneCode;
        const { path, createError } = this;
        if (!phoneNumber) {
          return createError({ path, message: "lang_mobile_phone_required" });
        }
        if ((phone + "").length < 8 || (phone + "").length > 20) {
          return createError({ path, message });
        }
      }
      return true;
    });
  }
);

Yup.addMethod(
  Yup.mixed,
  "checkEmail",
  function (message = "lang_email_address_invalid") {
    return this.test("checkEmail", message, function (value) {
      if (typeof value === "string") value = value.trim();
      const { path, createError } = this;
      if (!VALIDATE_ENUM.EMAIL.REGEX.test(value)) {
        return createError({ path, message });
      } else {
        const valiueBefore = (value + "").split("@")?.[0] || "";
        if ((value + "").length > 320 || valiueBefore.length > 255) {
          return createError({ path, message });
        }
        return true;
      }
    });
  }
);

Yup.addMethod(
  Yup.mixed,
  "checkRequired",
  function (message = "lang_field_is_required") {
    return this.test("required", message, function (value) {
      const { path, createError } = this;
      if (Array.isArray(value)) {
        if (value?.length == 0) {
          return createError({ path, message });
        }
        return true;
      }
      if (typeof value === "string") value = value.trim();
      if ([null, undefined, ""].includes(value)) {
        return createError({ path, message });
      }
      return true;
    });
  }
);

Yup.addMethod(Yup.mixed, "checkMaxLength", function (max, message) {
  return this.test("length", message, function (value) {
    if (!max) return true;
    const { path, createError } = this;
    if ((value + "").length > max) {
      return createError({
        path,
        message: message ?? "lang_field_max_length_error",
      });
    }
    return true;
  });
});

Yup.addMethod(
  Yup.mixed,
  "checkName",
  function (message = "lang_field_only_allow_text_and_space") {
    return this.test("checkName", message, function (value) {
      const { path, createError } = this;
      if (!/^([a-zA-Z]+\s)*[a-zA-Z]+$/.test(value + "")) {
        return createError({ path, message });
      }
      return true;
    });
  }
);

Yup.addMethod(
  Yup.mixed,
  "checkFile",
  function (message, maxSize = 10000000, accept = ".jpeg, .jpg, .png, .heic") {
    return this.test("checkFile", "", function (value) {
      const { path, createError } = this;
      if ([null, undefined, ""].includes(value)) {
        return createError({ path, message: "lang_field_is_required" });
      }
      if (value.size && value.size > maxSize)
        return createError({
          path,
          message: message ?? "lang_field_size_limit_exceeded",
        });
      if (value.extension && !accept.includes(value.extension))
        return createError({ path, message: "lang_file_format_error" });
      return true;
    });
  }
);

Yup.addMethod(
  Yup.mixed,
  "checkIdNumber",
  function (message = "lang_please_enter_a_valid_id_number") {
    return this.test("checkIdNumber", message, function (value) {
      const { path, createError } = this;
      if (dataStorage.isOverSeas) return true;
      if (value) {
        const patt = /^([A-Z]{2}[0-9]{5,8}[0-9A])$|^([A-Z][0-9]{6,9}[0-9A])$/;
        if (!patt.test(value) || value.length < 7 || value.length > 15) {
          return createError({ path, message });
        }
        return true;
      }
      return true;
    });
  }
);

Yup.addMethod(
  Yup.mixed,
  "checkTaxIdNumber",
  function (message = "lang_please_enter_a_valid_id_number") {
    return this.test("checkTaxIdNumber", message, function (value) {
      const { path, createError, parent } = this;
      const { tax_country } = parent;
      let patt = /^[A-Z0-9]{7,15}$/;
      switch (tax_country) {
        case "CHN":
          patt = /^[A-Z0-9]{18}$/;
          break;
        case "HKG":
          patt = /^([A-Z]{2}[0-9]{5,8}[0-9A])$|^([A-Z][0-9]{6,9}[0-9A])$/;
          break;
        default:
          break;
      }
      if (value) {
        if (!patt.test(value)) {
          return createError({ path, message });
        }
        return true;
      }
      return true;
    });
  }
);

Yup.addMethod(
  Yup.mixed,
  "checkTaxPayerId",
  function (message = "lang_tax_payer_id_format_error") {
    return this.test("checkTaxPayerId", message, function (value) {
      const { path, createError } = this;
      if (value) {
        const patt = /^[0-9]{9}$/;
        if (!patt.test(value)) {
          return createError({ path, message });
        }
        return true;
      }
      return true;
    });
  }
);

Yup.addMethod(
  Yup.mixed,
  "checkDerivativesAwarenessAssessment",
  function (message = "lang_derivatives_awareness_assessment_error") {
    return this.test(
      "checkDerivativesAwarenessAssessment",
      message,
      function (value) {
        const { path, createError } = this;
        if ([null, undefined, ""].includes(value)) {
          return createError({ path, message });
        }
        return true;
      }
    );
  }
);

Yup.addMethod(
  Yup.mixed,
  "checkRiskDisclosureAndDeclarationSignature",
  function (message = "lang_risk_disclosure_error") {
    return this.test(
      "checkDerivativesAwarenessAssessment",
      message,
      function (value) {
        const { path, createError } = this;
        if (value && value.length === 10) return true;
        else {
          return createError({ path, message });
        }
      }
    );
  }
);

Yup.addMethod(
  Yup.mixed,
  "checkEnoughAge",
  function (age = 18, message = "lang_you_not_enough_18_age") {
    return this.test("checkEnoughAge", message, function (value) {
      const { path, createError } = this;
      const date = moment(value);
      if (date.isValid()) {
        const diff = moment().diff(date, "years");
        if (diff < age) {
          return createError({ path, message });
        } else if (diff > 100) {
          return createError({ path, message: "lang_date_of_birth_invalid" });
        }
        return true;
      } else {
        return createError({ path, message: "lang_date_of_birth_invalid" });
      }
    });
  }
);

export default Yup;
