import React from "react";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useField } from "formik";
import InputBase from "@mui/material/InputBase";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import at from "lodash/at";
import dataStorage from "dataStorage";
import { getData } from "api/request";
import { Trans } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    overflow: "hidden",
    boxSizing: "border-box",
    alignItems: "center",
    justifyContent: "center",
    borderStyle: "dashed",
    borderWidth: 2,
    width: "100%",
    minHeight: 200,
    // maxHeight: 400,
    maxWidth: 600,
    cursor: "pointer",
  },
  inputFileHidden: {
    position: "absolute",
    display: "none",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    position: "relactive",
  },
  removeImage: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  previewImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  errorText: {
    color: theme.palette.error.main,
  },
}));

function ChooseFile(props) {
  const classes = useStyles();
  const {
    review,
    required,
    helperText = "JPEG, JPG, PNG, HEIC (10 MB)",
    note,
    accept = ".png, .heic, .jpeg, .jpg",
    size = 10000000, // bytes
    name,
    label,
    onChange,
    ...rest
  } = props;
  const [field, meta, helpers] = useField(props);
  const [touched, error] = at(meta, "touched", "error");
  const isError = touched && error && true;
  const refInput = React.useRef();
  const [url, setUrl] = React.useState("");

  /**
   * if have file link download and set value
   */
  React.useEffect(() => {
    if (typeof field.value === "string" && field.value.includes("http")) {
      getData(field.value, {
        responseType: "arraybuffer",
      })
        .then((res) => {
          const resUrl = URL.createObjectURL(new Blob([res]));
          setUrl(resUrl);
        })
        .catch(() => console.error("Get image data error"));
    }
  }, [field.value]);

  /**
   * Update new file upload
   * @param {Event} e input type file change event
   */
  const onChangeFile = (e) => {
    helpers.setTouched(true);
    const file = e?.target?.files?.[0];
    if (!file) return;
    const { size: fileSize, name: fileName } = file;
    const extension = (fileName + "").match(/([^.]*)$/)?.[0]?.toLowerCase();
    if (fileSize > size || !accept.includes(extension)) {
      helpers.setValue(
        {
          name: fileName,
          size: fileSize,
          extension,
        },
        true
      );
      return;
    }
    const objUrl = URL.createObjectURL(file);
    setUrl(objUrl);
    helpers.setValue(objUrl, true);
    onChange?.(file);
  };

  /**
   * Render the helper text or error text
   * @returns text translated
   */
  function _renderHelperText() {
    if (isError) {
      return (
        <FormHelperText sx={{ pl: 1.5 }} className={classes.errorText}>
          <Trans values={{ label: dataStorage.translate(label) }}>
            {error}
          </Trans>
        </FormHelperText>
      );
    } else if (note) {
      return (
        <FormHelperText error={false} sx={{ pl: 1 }}>
          <Trans>lang_note</Trans>:&nbsp;<Trans>{note}</Trans>
        </FormHelperText>
      );
    }
  }

  /**
   * Remove file uploaded
   * @param {Event} e dom click event
   */
  const onRemove = (e) => {
    e.preventDefault();
    setUrl("");
    refInput.current.value = null;
    helpers.setValue("", true);
    onChange?.("");
  };

  /**
   * Render preview image for file selected
   * @returns HTML UI show preview image
   */
  const renderPreview = () => {
    return (
      <React.Fragment>
        <IconButton onClick={onRemove} className={classes.removeImage}>
          <CloseIcon />
        </IconButton>
        <img className={classes.previewImage} src={url} alt="uploaded photo" />
      </React.Fragment>
    );
  };

  return (
    <div style={{ width: "initial", maxWidth: "100%" }}>
      <InputLabel shrink>
        <Trans>{label}</Trans>
        {required ? " *" : ""}
      </InputLabel>
      <label
        htmlFor={`input_file_for_${name}`}
        className={classes.container}
        style={{
          borderStyle: url ? "solid" : "dash",
          borderColor: isError ? "#E93535" : "gray",
        }}
      >
        <InputBase
          type="file"
          inputRef={refInput}
          id={`input_file_for_${name}`}
          inputProps={{
            accept: accept,
          }}
          disabled={review}
          onChange={onChangeFile}
          className={classes.inputFileHidden}
        />
        <FormControl
          {...rest}
          className={classes.inputContainer}
          error={isError}
        >
          {url ? (
            renderPreview()
          ) : (
            <React.Fragment>
              <Typography
                sx={{ px: 1 }}
                style={{ color: "#D5A34D" }}
                variant="body1"
              >
                <Trans>lang_browse_file</Trans>
              </Typography>
              <FormHelperText error={false} sx={{ pl: 1 }}>
                {helperText}
              </FormHelperText>
            </React.Fragment>
          )}
        </FormControl>
      </label>
      {_renderHelperText()}
    </div>
  );
}

export default ChooseFile;
