import { useMutation } from "react-query";
import axios from "axios";
import { postData } from "api/request";
import { getLoginUrl, getRefreshUrl, getVerifyOtpUrl } from "api/api";
import CryptoJS from "react-native-crypto-js";
import dataStorage from "dataStorage";
import getSession from "hooks/common/useSession";
import logger from "helpers/logger";

const REFRESH_TOKEN_TIMEOUT = 2 * 60 * 1000;

export const autoRenewToken = () => {
  dataStorage.timeoutRefreshToken &&
    clearInterval(dataStorage.timeoutRefreshToken);
  dataStorage.timeoutRefreshToken = setInterval(() => {
    postData(getRefreshUrl(), {
      refreshToken: dataStorage.auth.refreshToken,
      deviceID: dataStorage.auth.deviceID,
    })
      .then((result) => {
        if (result?.accessToken) {
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${result.accessToken}`;
          dataStorage.auth.accessToken = result.accessToken;
        }
      })
      .catch((error) => {
        logger.error("renew token error: ", error);
        dataStorage.showAlert(error);
      });
  }, REFRESH_TOKEN_TIMEOUT);
};

const verifyRegisterOTP = async ({ otpCode, phoneNumber, signal }) => {
  if (!otpCode || !phoneNumber) return "";
  const session = await getSession();
  // console.log('sesstion-------', session);
  Object.assign(dataStorage.session, session || {});
  const encrypOtp = CryptoJS.AES.encrypt(
    otpCode,
    dataStorage.session?.key
  ).toString();
  const auth = await postData(
    getLoginUrl(),
    {
      phone_number: phoneNumber,
      otp_code: encrypOtp,
      session_id: dataStorage.session?.sessionId,
      device_id: dataStorage.deviceId,
      language: localStorage.getItem("lastLanguage") || "en",
    },
    { signal: signal }
  );
  const result = await postData(getRefreshUrl(), {
    refreshToken: auth.refreshToken,
    deviceID: dataStorage.deviceId,
  });
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${result.accessToken}`;
  dataStorage.auth = result;
  dataStorage.auth.refreshToken = auth.refreshToken;
  dataStorage.auth.phone = phoneNumber;
  autoRenewToken();
  return result;
};
const useRegisterPhoneNumber = () => {
  return useMutation((variables) => verifyRegisterOTP(variables));
};

const verifyPhoneOTP = async ({ otpCode, phoneNumber, signal }) => {
  if (!otpCode || !phoneNumber) return "";
  const session = await getSession();
  Object.assign(dataStorage.session, session || {});
  const encrypOtp = CryptoJS.AES.encrypt(
    otpCode,
    dataStorage.session?.key
  ).toString();
  const result = await postData(
    getVerifyOtpUrl(),
    {
      phone_number: phoneNumber,
      otp_code: encrypOtp,
      session_id: dataStorage.session?.sessionId,
      otp_type: "ACCOUNT_PHONE",
    },
    {
      signal: signal,
    }
  );
  return result;
};

const useVerifyPhoneNumber = () => {
  return useMutation((variables) => verifyPhoneOTP(variables));
};

export { useRegisterPhoneNumber, useVerifyPhoneNumber };
