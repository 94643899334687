import React from "react";
import at from "lodash/at";
import { useField } from "formik";
import { Trans } from "react-i18next";
import dataStorage from "dataStorage";
import PhoneCountry from "components/PhoneCountry";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import ListPhoneCountry from "constants/Countries/countriesPhone";

export default function InputField({ ...props }) {
  const { helperText, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const { setValue: setFieldValue } = helper;
  const [touched, error] = at(meta, "touched", "error");
  const isError = touched && error && true;

  /**
   * Render helper text or error text
   * @returns translated text
   */
  function _renderHelperText() {
    let text = "";
    if (touched && error) {
      text = error;
    } else if (helperText) {
      text = helperText;
    }
    return (
      <Trans
        values={{
          label: dataStorage.translate(rest.label),
          max: rest.maxLength,
        }}
      >
        {text}
      </Trans>
    );
  }

  /**
   * Update phone value
   * @param {object} data phone data include country code, phone number...
   */
  const onChange = (data) => {
    const value = `+${data.phone}|${data.phoneNumber}`;
    setFieldValue(value);
  };

  const phone = React.useMemo(() => {
    const phoneNumber = (field.value + "").replace("+", "");
    const phoneCode = phoneNumber.split("|")[0];
    const country = ListPhoneCountry.find((e) => e.phone === phoneCode);
    return {
      ...country,
      phoneNumber: phoneNumber.split("|")[1],
    };
  }, [field.value]);
  return (
    <FormControl
      name={field.name}
      onBlur={field.onBlur}
      {...rest}
      error={isError}
    >
      <PhoneCountry
        {...rest}
        onBlur={field.onBlur}
        label={rest.label}
        error={isError}
        phoneValue={phone}
        onChange={onChange}
      />
      <FormHelperText>{_renderHelperText()}</FormHelperText>
    </FormControl>
  );
}
