import React from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import useStyles from "./styles";
import dataStorage from "dataStorage";
import MailIcon from "@mui/icons-material/Mail";
import { Trans } from "react-i18next";

export default function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.appFooter}>
      <Typography variant="body2" align="center" component="span">
        <Trans>lang_customer_support</Trans>:&nbsp;&nbsp;&nbsp;
        {<MailIcon style={{ marginBottom: -2, fontSize: 16 }} />}&nbsp;
        <Link
          className="emailLink"
          target="_blank"
          href={`mailto:${dataStorage.config?.supportEmail}`}
        >
          {dataStorage.config?.supportEmail}
        </Link>
      </Typography>
      <div style={{ height: 8 }} />
      <Typography variant="body2" align="center">
        {dataStorage.config?.footer}
      </Typography>
      <Typography variant="body2" align="center">
        {dataStorage.config?.footerAddition}
      </Typography>
    </div>
  );
}
