import React from "react";
import {
  AutocompleteField,
  SelectField,
  FileUpload,
  InputField,
  RadioField,
  DatePickerField,
  PhoneCountryField,
} from "components/CustomFields";
import Grid from "@mui/material/Grid";
import formModel from "containers/Onboarding/FormModels/Models";
import { GENDER_OPTIONS, EDUCATION_OPTIONS } from "constants/Options/options";
import ListCountry from "constants/Countries/countries";
import { useAddress } from "hooks/useKYC";
import { useFormikContext } from "formik";
import dataStorage from "dataStorage";

const { formField } = formModel;

const PersonalInformation = ({ setLoading }) => {
  const { setFieldValue } = useFormikContext();
  const { mutate: callUploadPhoto } = useAddress();

  /**
   * Handle change file
   * @param {File} file new file
   * @returns void if dont have file
   */
  const onChangePhoto = (file) => {
    if (!file) return;
    const { name: fileName } = file;
    const extension = (fileName + "").match(/([^.]*)$/)?.[0]?.toLowerCase();
    setLoading(true);
    callUploadPhoto(
      {
        file,
        documentType: extension,
      },
      {
        onSuccess: (data) => {
          setLoading(false);
          setFieldValue(
            formField.personal_information.correspondence_address_document.name,
            data,
            false
          );
        },
        onError: () => {
          setLoading(false);
          console.error("Cannot post address image to server");
          dataStorage.showAlert("lang_upload_address_photo_failed");
        },
      }
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <InputField
          required
          maxLength={100}
          name={formField.personal_information.eng_given_name.name}
          label={formField.personal_information.eng_given_name.label}
          placeholder={
            formField.personal_information.eng_given_name.placeholder
          }
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          maxLength={100}
          name={formField.personal_information.eng_middle_name.name}
          label={formField.personal_information.eng_middle_name.label}
          placeholder={
            formField.personal_information.eng_middle_name.placeholder
          }
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          required
          maxLength={100}
          name={formField.personal_information.eng_sur_name.name}
          label={formField.personal_information.eng_sur_name.label}
          placeholder={formField.personal_information.eng_sur_name.placeholder}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          maxLength={100}
          name={formField.personal_information.chinese_name.name}
          label={formField.personal_information.chinese_name.label}
          placeholder={formField.personal_information.chinese_name.placeholder}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <RadioField
          isHorizontal
          required
          name={formField.personal_information.gender.name}
          label={formField.personal_information.gender.label}
          data={GENDER_OPTIONS}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          required
          textTransform="uppercase"
          name={formField.personal_information.id_number.name}
          label={formField.personal_information.id_number.label}
          placeholder={formField.personal_information.id_number.placeholder}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DatePickerField
          required
          name={formField.personal_information.dob.name}
          label={formField.personal_information.dob.label}
          inputFormat="yyyy/MM/dd"
          // minDate={new Date('1920/1/1')}
          maxDate={new Date().setFullYear(new Date().getFullYear() - 18)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          required
          maxLength={200}
          name={formField.personal_information.region.name}
          label={formField.personal_information.region.label}
          fullWidth
        />
      </Grid>
      {dataStorage.isOverSeas ? (
        <Grid item xs={12} md={6}>
          <AutocompleteField
            required
            disabled
            name={formField.applicant_verification_id.country_of_residence.name}
            label={
              formField.applicant_verification_id.country_of_residence.label
            }
            data={ListCountry}
            fullWidth
          />
        </Grid>
      ) : (
        <React.Fragment />
      )}
      <Grid item xs={12} md={6}>
        <InputField
          maxLength={200}
          name={formField.personal_information.house_number.name}
          label={formField.personal_information.house_number.label}
          placeholder={formField.personal_information.house_number.placeholder}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          required
          maxLength={200}
          name={formField.personal_information.street_name.name}
          label={formField.personal_information.street_name.label}
          placeholder={formField.personal_information.street_name.placeholder}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          required
          maxLength={200}
          name={formField.personal_information.city_name.name}
          label={formField.personal_information.city_name.label}
          placeholder={formField.personal_information.city_name.placeholder}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          required
          maxLength={200}
          name={formField.personal_information.province_name.name}
          label={formField.personal_information.province_name.label}
          placeholder={formField.personal_information.province_name.placeholder}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          maxLength={100}
          name={formField.personal_information.zip_code.name}
          label={formField.personal_information.zip_code.label}
          placeholder={formField.personal_information.zip_code.placeholder}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <FileUpload
          required
          onChange={onChangePhoto}
          note={"lang_address_photo_note"}
          name={
            formField.personal_information.correspondence_address_document.name
          }
          label={
            formField.personal_information.correspondence_address_document.label
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <PhoneCountryField
          required
          name={formField.personal_information.mobile_number.name}
          label={formField.personal_information.mobile_number.label}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectField
          required
          name={formField.personal_information.education.name}
          label={formField.personal_information.education.label}
          data={EDUCATION_OPTIONS}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <InputField
          required
          maxLength={320}
          name={formField.personal_information.email_address.name}
          label={formField.personal_information.email_address.label}
          helperText="lang_email_address_reminder"
          placeholder={formField.personal_information.email_address.placeholder}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default PersonalInformation;
