import React, { useState, useEffect } from "react";
import { useField } from "formik";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Trans } from "react-i18next";
import dataStorage from "dataStorage";
import { zhCN, zhHK, enGB } from "date-fns/locale";
import i18n from "i18next";

export default function DatePickerField(props) {
  const [field, meta, helper] = useField(props);
  const { touched, error } = meta;
  const { setValue } = helper;
  const isError = touched && error && true;
  const { value } = field;
  const [selectedDate, setSelectedDate] = useState(null);
  const [lang, setLang] = React.useState(i18n.language);

  /**
   * Listener language change event
   */
  React.useEffect(() => {
    i18n.on("languageChanged", function (lng) {
      setLang(lng);
    });
  }, []);

  /**
   * Update datepicker value when field value change
   */
  useEffect(() => {
    if (value) {
      const date = new Date(value);
      setSelectedDate(date);
    } else {
      setSelectedDate(null);
    }
  }, [value]);

  /**
   * Handle date picker change value
   * @param {Date | null} date is the new date value
   */
  function _onChange(date) {
    if (date == null) {
      setValue("", true);
      return;
    }
    if (date) {
      setSelectedDate(date);
      try {
        setValue(date, true);
      } catch (error) {
        setValue(date, true);
      }
    } else {
      setValue(date, true);
    }
  }

  /**
   * get locale by language to show correct text of date picker lib
   */
  const locale = React.useMemo(() => {
    switch (lang) {
      case "en":
        return enGB;
      case "cn":
        return zhHK;
      case "zh":
        return zhCN;
      default:
        return enGB;
    }
  }, [lang]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
      <DatePicker
        {...field}
        {...props}
        autoOk={true}
        // disableToolbar
        defaultCalendarMonth={props.maxDate || props.minDate}
        variant="inline"
        inputVariant="outlined"
        value={selectedDate}
        mask="____/__/__"
        onChange={_onChange}
        // onClose={_onClose}
        renderInput={(params) => (
          <TextField
            // {...field}
            required={props.required}
            placeholder="YYYY/MM/DD"
            name={field.name}
            value={field.value}
            onBlur={field.onBlur}
            {...params}
            label={<Trans>{params.label}</Trans>}
            error={isError}
            helperText={
              isError && error ? (
                <Trans values={{ label: dataStorage.translate(props.label) }}>
                  {error}
                </Trans>
              ) : (
                ""
              )
            }
            fullWidth
          />
        )}
      />
    </LocalizationProvider>
  );
}
