import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEn from "./lang/en.json";
import translationCn from "./lang/cn.json";
import translationZh from "./lang/zh.json";
import dataStorage from "dataStorage";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: translationEn,
  },
  cn: {
    translation: translationCn,
  },
  zh: {
    translation: translationZh,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("lastLanguage") || "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      bindI18n: "languageChanged",
    },
  })
  .then(function (t) {
    dataStorage.translate = t;
    document.title = dataStorage.translate("lang_magpie_onboarding");
  })
  .catch(() => console.error("Cannot init i18n"));

export default i18n;
