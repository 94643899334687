import dataStorage from "dataStorage";

const configVersionUrl = (path, version = "v1") => {
  return `${dataStorage.baseUrl}/${version}/${path}`;
};

export const getSearchAddressUrl = (searchTerms) => {
  return configVersionUrl(
    `address-finder/autocomplete?filter=${searchTerms}&post_box=0`
  );
};

export function getLogUrl() {
  return configVersionUrl("log/data");
}

// Auth

export function getSessionUrl(sessionId) {
  return configVersionUrl(`auth/session?session_id=${sessionId}`, "v3");
}

export function getLoginUrl() {
  return configVersionUrl("auth/login", "v3");
}

export function getRefreshUrl() {
  return configVersionUrl("auth/refresh", "v3");
}

// OTP

export function getOtpUrl() {
  return configVersionUrl("auth/otp", "v3");
}

export function getVerifyOtpUrl() {
  return configVersionUrl("auth/verify-otp", "v3");
}

// KYC

export function getUploadImageUrl() {
  return configVersionUrl("account-opening-shenzen/image");
}

export function getTransactionUrl() {
  return configVersionUrl("account-opening-shenzen/transaction");
}

export function getRenewLivenessTokenUrl() {
  return configVersionUrl("account-opening-shenzen/renew-liveness-token");
}

// Draft

export function getDraftUrl() {
  return configVersionUrl("account-opening-shenzen/draft");
}

// Account Opening

export function getAccountUrl() {
  return configVersionUrl("account-opening-shenzen/account");
}

// User

export function getUserDetailUrl(userId) {
  return configVersionUrl(`user/user-details/${userId}?getAccount=true`, "v2");
}
