import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Trans } from "react-i18next";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import PhoneCountry from "components/PhoneCountry";

const RenderLoginForm = (props) => {
  const { onNext, onChangePhone, isLoadingOTP, isValid, classes } = props;
  return (
    <React.Fragment>
      <Grid item xs={12} sm={12}>
        <Typography variant="h5">
          <Trans>lang_new_trading_account</Trans>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <PhoneCountry onChange={onChangePhone} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          <Trans>lang_send_otp_explain</Trans>
        </Typography>
      </Grid>
      <Grid item xs={12} align="right">
        <Button
          onClick={onNext}
          disabled={isLoadingOTP || !isValid}
          type="submit"
          variant="contained"
          color="primary"
          className={classes.button}
        >
          <Trans>lang_next</Trans>
          {isLoadingOTP && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
      </Grid>
    </React.Fragment>
  );
};
export default RenderLoginForm;
